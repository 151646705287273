import React, { useEffect, useState } from "react";
import localStorage from "../../utils/localStorage";

export const LocationsContext = React.createContext([]);

const LocationContext = ({ subPages }) => {
  const itemType = JSON.parse(localStorage.load("ITEM_TYPE"));
  const province = JSON.parse(localStorage.load("PROVINCE"));
  const [locationList, setLocationList] = useState([]);
  const [userDefaultData, setUserDefaultData] = useState([]);
  const [itemTypeData, setItemTypeData] = useState(itemType);
  const [locationProvince, setLocationProvince] = useState([]);
  const [locationSearch, setLocationSearch] = useState({});

  useEffect(() => {
    if (itemType) {
      setItemTypeData(itemType);
    }
    if (province) {
      setLocationProvince(province.map(item => item.province));
    }
  }, []);

  return (
    <LocationsContext.Provider value={
      {
        locationData: [locationList, setLocationList],
        locationQuery: [locationSearch, setLocationSearch],
        locationUserDefaultData: [userDefaultData, setUserDefaultData],
        locationUserProvinceData: [locationProvince, setLocationProvince],
        locationItemType: [itemTypeData, setItemTypeData],
      }
    }>
      {subPages}
    </LocationsContext.Provider>
  );
};

export default LocationContext;
