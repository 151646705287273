import { actionTypes } from 'state/actions/userInfo';

const initialState = {
  user_info: {},
  edit_user_info: {},
  user_reserve_address_request_help: {}
};

const userInfo = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_USER_INFO:
      return {
        ...state,
        user_info: payload
      };
    case actionTypes.SET_EDIT_USER:
      return {
        ...state,
        edit_user_info: payload
      };
    case actionTypes.SET_RESERVE_ADDRESS_REQUEST_HELP:
      return {
        ...state,
        user_reserve_address_request_help: payload
      };
    default:
      return state;
  }
};

export default userInfo;
