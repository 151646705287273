import Button from 'components/Button/index';
import styled from 'styled-components';
import { formatNumber } from 'utils/constant';


const StyleDiv = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 16px;

  .store {
    font-weight: 700;
    font-size: 16px;
    color: #003974;
    margin-bottom: 12px;
  }

  .info {
    display: flex;
  }

  .title {
    width: 30%;
    font-weight: 400;
    font-size: 12px;
    color: #7b7b7b;
    margin-bottom: 8px;
  }
  .content {
    width: 70%;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }
`;

const StoreInformation = (props) => {

  const { reserve, onClick } = props;

  const handleViewDetail = (id) => {
    onClick && onClick(id);
  };

  return (
    <StyleDiv>
      <div className="store">
        {reserve.item_name}
      </div>
      <div className="info">
        <span className="title">数量/単位</span>
        <span className="content">{formatNumber(reserve.quantity)}{reserve.item_unit}</span>
      </div>
      <div className="info">
        <span className="title">備蓄場所</span>
        <span className="content">{reserve.province}{reserve.city}{reserve.street}</span>
      </div>
      <div className="info">
        <span className="title">担当者1</span>
        <span className="content">{reserve.person_in_charge.length ? reserve.person_in_charge[0]?.name : "-"}</span>
      </div>
      <div className="info">
        <div style={{
          width: "30%",
          display: "flex",
          flexDirection: "column",
          fontWeight: 400,
          fontSize: 12,
          color: "#7b7b7b",
          marginBottom: "8px"
        }}>
          <span>公開設定</span>
        </div>
        <span className="content" style={{ display: "flex", alignItems: "center" }}>{reserve.is_published ? "公開する" : "公開しない"}</span>
      </div>
      <div className="info">
        <span className="title">期限</span>
        <span className="content">{reserve.exp_date.split(" ")[0].split("-").join("/")}</span>
      </div>
      <Button
        type="primary"
        style={{
          padding: '4px 0px',
          gap: '8px',
          width: '130px',
          height: '34px',
          background: '#0059AB',
          border: '1px solid #0059AB',
          borderRadius: '4px',
          marginTop: '12px'
        }}
        onClick={() => handleViewDetail(reserve.reserve_id)}
      >
        <span
          style={{
            fontWeight: 700,
            fontSize: '16px'
          }}
        >
          確認・編集する
        </span>
      </Button>
    </StyleDiv>
  );
};

export default StoreInformation;
