import styled from 'styled-components';
const UserStyledDiv = styled.div`
padding: 24px 16px 32px;
width: 375px;
background: #fff;
justify-content: center;
@media (max-width: 576px) {
  min-width: 350px;
}
.ant-form-item {
  margin-bottom: 16px; 
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 10px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '';
  }
}
.ant-form-item .ant-form-item-label label::before {
  color: #ff676e;
  font-size : 10px
}
.ant-radio-inner {
  background-color: white;
  border-radius: 0 !important;

}
.ant-radio-wrapper {
  margin-right: 24px !important;
}
.radio-custom {
  .ant-radio-wrapper.ant-radio-wrapper-in-form-item input[type='radio'] {
    width: 14px;
    height: 14px;
    border-radius: 0;
  
}
.ant-radio-disabled + span {
  color: #333;
  cursor: not-allowed;
}
.ant-radio-checked .ant-radio-inner {
  background-color: #0059AB;
  border-color: #0059AB;
  border-radius: 2px !important;
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  border-radius: 2px;
  border-collapse: separate;
}
.ant-radio-checked .ant-radio{
  border-radius: 0 !important;
  border: none;
  white-space: nowrap;
}
.ant-radio-checked .ant-radio-inner:after{
    position: absolute;
    border-radius: 2px;

    top: 100%;
    left: 80.5%;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(0) translate(-50%,-50%);
    opacity: 0;
    transition: all .1s cubic-bezier(.71,-.46,.88,.6),opacity .1s;
    content: " ";
}
.ant-radio-checked .ant-radio-inner:after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%,-50%);
  opacity: 1;
  transition: all .2s cubic-bezier(.12,.4,.29,1.46) .1s;
  content: " ";
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #0059AB;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
  animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  content: '';
}
.ant-radio:hover .ant-radio-inner {
  border-radius: 0 !important;
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9!important;
}
.ant-radio-disabled .ant-radio-inner:after {
border-collapse: separate;
border-color: #00000040;
background-color: #f5f5f5;
-webkit-animation-name: none;

animation-name: none;
}
input {
  border: none!important;
  width: -webkit-fill-available;
}
}
.ant-radio {
  border-radius: 0!important;
  background-color: white;
}
.ant-radio-input::after{
  border-radius: 0;
}

input {
  border: #fff;
  border-bottom: 1px solid #dcdcdc;
  width: -webkit-fill-available;
  ::placeholder {
    color: #333;
  }
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input[disabled] {
  border-bottom: 1px solid  #dcdcdc;
  border-right-width: 1px;
  color: #333;
}
.ant-form {
  width: inherit;
}
.ant-form-vertical .ant-form-item-label > label {
  color: #7b7b7b;
  font-size: 10px;
  padding-bottom: 0px;
}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
  padding: 0 0px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
}
.input-on-edit {
  .ant-form-item {
    margin-bottom: 6px;
  }
  label {
    font-size: 10px;
  }
  input {
    border: 2px solid #0059ab;
    border-radius: 4px;
    width: -webkit-fill-available;
    height: 34px;
  }
}
.custom-input {
  border-bottom: 0.5px solid #dcdcdc;
  border-color: #dcdcdc;
  margin-bottom: 16px;
    
  label {
    font-size: 10px;
    display: block;
    color: #7b7b7b;
  }
  input {
    width: -webkit-fill-available;
    font-size: 14px;
    color: #333;
    outline: 0;
    border-width: unset;
    border: none;
    border-color: none;
    background-color: transparent;
    background: none;
  }
  input:focus {
    border-color: green
  }
}
.user-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  font-style: normal;
  color: #0059AB;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-information {
  font-size: 40px;
  line-height: 48px;
  color: #0059ab;
  font-family: 'Arial Bold', sans-serif;
}
.ant-form-item {
  margin-bottom: 16px;
}
.ant-table-wrapper {
  width: 100%;
}
.btn-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fff;
  font-weight: 700;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
}
.button {
  margin-top: 12px;
  background: #53b7b2;
  border: 1px solid #53b7b2;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  height: 48px;
}

.btn-primary {
  background-color: #0059AB;
  width: 164px;
  height: 44px;
}
.btn-cancel {
  color: #0059AB;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  width: 164px;
  height: 44px;
}
.block-label {
  padding: 4px 0 !important;
  display: block;

}
.label-group {
  display: flex;
  flex-direction: column;
}
`;
export default UserStyledDiv;