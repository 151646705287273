import { actionTypes } from "state/actions/masterData";

const initialState = {
  province: [],
  customer_type: [],
  item_type: [],
  item_unit: [],
  fetched: false
};

const masterData = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.GET_PROVINCE:
      return {
        ...state,
        province: payload
      };
    case actionTypes.GET_CUSTOMER_TYPE:
      return {
        ...state,
        customer_type: payload
      };
    case actionTypes.GET_ITEM_TYPE:
      return {
        ...state,
        item_type: payload
      };
    case actionTypes.GET_ITEM_UNIT:
      return {
        ...state,
        item_unit: payload
      };
    case actionTypes.FETCH_MASTER_DATA_SUCCESS:
      return {
        ...state,
        fetched: true
      };
    default:
      return state;
  }
};

export default masterData;
