import { QuestionCircleOutlined } from "@ant-design/icons";
import { Checkbox, Form } from "antd";
import { Button, Input, Modal } from "components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ERROR_MESSAGES, PASSWORD_REGEX } from "utils/constant";
import { useScroll } from "../../hooks/useScroll";
import geocode from "./getCityState";
import {
  customer_type_id_list,
  FixedBottom,
  is_notify_list, StyleButton,
  StyledDiv, StylePlaceHolder
} from "./styles";
import TermAggrement1 from "./TermAggrement/TermAggrement1";
import TermAggrement2 from "./TermAggrement/TermAggrement2";
import TermAggrement3 from "./TermAggrement/TermAggrement3";

const SignUpForm = ({
  onSubmit,
  loading,
  isNotify,
  setIsNotify,
  customerType,
  setCustomerType,
  cityState,
  setCityState,
  zipcode,
  setZipcode,
  setLandlineFail,
  setMobileFail,
  validateFormData
}) => {
  const [check_1, setCheck1] = useState(false);
  const [check_2, setCheck2] = useState(false);
  const [check_3, setCheck3] = useState(false);
  const [checked_1, setChecked1] = useState(false);
  const [checked_2, setChecked2] = useState(false);
  const [checked_3, setChecked3] = useState(false);
  const [isNotifyQuestion, setIsNotifyQuestion] = useState(false);
  const [disable, setDisable] = useState(false);
  const [checkNotify, setCheckNotify] = useState(false);
  const navigate = useNavigate();
  const { scrollDirection } = useScroll();
  const styles = {
    active: {
      visibility: "visible",
      transition: "all 0.125s",
      transform: "translate(-50%, 0)"
    },
    hidden: {
      visibility: "hidden",
      transition: "all 0.125s",
      transform: "translate(-50%, 100%)"
    }
  };

  useEffect(() => {
    setDisable(loading);
  }, [loading]);
  useEffect(() => {}, [check_1, check_2, check_3]);
  const onCheckboxChange_1 = (e) => {
    setChecked1(false);
    setCheck1(e.target.checked);
  };
  const onCheckboxChange_2 = (e) => {
    setChecked2(false);
    setCheck2(e.target.checked);
  };
  const onCheckboxChange_3 = (e) => {
    setChecked3(false);
    setCheck3(e.target.checked);
  };
  useEffect(() => {}, [cityState]);
  const onFinish = async (data) => {
    onSubmit(data);
  };

  function handleChange(checkedValues) {
    setCheckNotify(true);
    setIsNotify(checkedValues.target.value);
  }

  const onValidate = () => {};
  const [componentDisabled, setComponentDisabled] = useState(false);
  const [checkCustomerType, setCheckCustomerType] = useState(false);
  const [form] = Form.useForm();

  return (
    <StyledDiv className={`${componentDisabled ? "preview-request" : ""}`}>
      <Form
        form={form}
        initialValues={{ landline_number: "", mobile_phone_number: "" }}
        onFinish={componentDisabled ? onFinish : onValidate}
        autoComplete="off"
        layout="vertical"
      >
        <div className="form-wrapper">
          <div className="register">アカウント登録</div>
          <div className="description">
            {componentDisabled ? (
              <p>内容をご確認の上、アカウントをご登録ください。</p>
            ) : (
              <p>
                アカウントを登録するために、
                <br />
                下記の情報をご入力ください。
              </p>
            )}
          </div>
          <Form.Item
            name="company_name"
            label="事業者名/自治体名"
            rules={[{ required: true, message: ERROR_MESSAGES.REQUIRED }]}
          >
            <Input
              id="company_name"
              name="company_name"
              type="text"
              disabled={componentDisabled}
            />
          </Form.Item>

          <Form.Item
            name="customer_type_id"
            rules={[
              () => ({
                validator(_, value) {
                  if (checkCustomerType) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("いずれかにチェックをしてください。"));
                }
              })
            ]}
          >
            {customer_type_id_list.map((customer_type) => {
              return (
                <Checkbox
                  key={customer_type.label}
                  onChange={(checkedValues) => {
                    setCheckCustomerType(true);
                    setCustomerType(checkedValues.target.value);
                  }}
                  checked={customer_type.value == customerType}
                  value={customer_type.value}
                  className="form_font"
                  name="customer_type_id"
                  disabled={componentDisabled}
                >
                  {customer_type.label}
                </Checkbox>
              );
            })}
          </Form.Item>

          <Form.Item
            name="name"
            label={"管理者名"}
            rules={[{ required: true, message: ERROR_MESSAGES.REQUIRED }]}
          >
            <Input id="name" name="name" type="text" disabled={componentDisabled} />
          </Form.Item>

          <Form.Item
            name="email"
            label={"メールアドレス"}
            rules={[{ required: true, message: ERROR_MESSAGES.REQUIRED }]}
            className="margin-bottom"
          >
            <Form.Item
              name="email"
              label={
                <div className="label-group">
                  <span>
                    {componentDisabled
                      ? ""
                      : "※入力したメールアドレスがアカウントIDになります"}
                  </span>
                </div>
              }
              rules={[
                {
                  type: "email",
                  message: "入力されたメールアドレスは有効ではありません。"
                }
              ]}
            >
              <Input id="email" name="email" type="text" disabled={componentDisabled} />
            </Form.Item>
          </Form.Item>

          <Form.Item
            name="zip_code"
            label={"郵便番号"}
            rules={[
              { required: true, message: ERROR_MESSAGES.REQUIRED },
              {
                pattern: /^[0-9]{7}$/,
                message: "7桁の半角数字、ハイフン()なしで入力してください。"
              }
            ]}
          >
            <Input
              id="zip_code"
              name="zip_code"
              type="text"
              disabled={componentDisabled}
              value={zipcode}
              onChange={async (event) => {
                const { value } = event.target;
                if (value.length === 7) {
                  let { province, city } = await geocode(value);
                  setCityState({ province: province, city: city });
                } else {
                  setCityState({ province: "", city: "" });
                }
              }}
            />
          </Form.Item>

          <Form.Item
            name="province"
            label={
              <div>
                都道府県<span style={{ color: "#ff676e", "font-size": "14px" }}>*</span>
              </div>
            }
          >
            <StylePlaceHolder>
              <Input
                id="province"
                name="province"
                type="text"
                disabled={componentDisabled}
                value={cityState.province}
                placeholder={cityState.province}
                className="style-placeholder"
              />
            </StylePlaceHolder>
          </Form.Item>

          <Form.Item
            name="city"
            label={
              <div>
                市区町村 <span style={{ color: "#ff676e", "font-size": "14px" }}>*</span>
              </div>
            }
          >
            <StylePlaceHolder>
              <Input
                id="city"
                name="city"
                type="text"
                disabled={componentDisabled}
                value={cityState.city}
                placeholder={cityState.city}
                className="style-placeholder"
              />
            </StylePlaceHolder>
          </Form.Item>

          <Form.Item
            name="street"
            label={"詳細住所"}
            rules={[{ required: true, message: ERROR_MESSAGES.REQUIRED }]}
          >
            <Input id="street" name="street" type="text" disabled={componentDisabled} />
          </Form.Item>

          <Form.Item
            name="phone"
            label={
              <div>
                代表者電話番号{" "}
                <span style={{ color: "#ff676e", "font-size": "14px" }}>*</span>
              </div>
            }
            className="margin-bottom group-wrapper"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  let mobile = getFieldValue("mobile_phone_number");
                  let landline = getFieldValue("landline_number");
                  if (mobile === "" && landline === "") {
                    return Promise.reject(
                      new Error("携帯、固定電話番号どちらか入力してください。")
                    );
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            <Form.Item
              name="mobile_phone_number"
              label="(携帯） いずれか必須"
              rules={[
                {
                  pattern: /^[0-9]{11}$/,
                  message: "11桁の半角数字、ハイフン(-)なしで入力してください。"
                }
              ]}
            >
              <Input
                id="mobile_phone_number"
                name="mobile_phone_number"
                type="text"
                default
                disabled={componentDisabled}
              />
            </Form.Item>
            <Form.Item
              name="landline_number"
              label="(固定）いずれか必須"
              rules={[
                {
                  pattern: /^[0-9]{10}$/,
                  message: "10桁の半角数字、ハイフン(-)なしで入力してください。"
                }
              ]}
            >
              <Input
                id="landline_number"
                name="landline_number"
                type="text"
                disabled={componentDisabled}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item
            name="password"
            label={"ログインパスワード"}
            rules={[
              { required: true, message: ERROR_MESSAGES.REQUIRED },
              {
                pattern: PASSWORD_REGEX,
                message: ERROR_MESSAGES.PASSWORD_NOT_MATCH
              }
            ]}
            className="landline-number"
          >
            {componentDisabled ? (
              <Input
                id="password"
                name="password"
                autoComplete={"off"}
                label="ログインパスワード"
                type="text"
                placeholder="************"
                disabled={true}
                className="password-hidden"
              />
            ) : (
              <Input
                id="password"
                name="password"
                label="ログインパスワード"
                type="password"
                disabled={componentDisabled}
              />
            )}
          </Form.Item>

          <Form.Item
            name="confirm_password"
            label={"ログインパスワード（確認用)"}
            rules={[
              { required: true, message: ERROR_MESSAGES.REQUIRED },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(ERROR_MESSAGES.CONFIRM_PASSWORD_NOT_MATCH)
                  );
                }
              })
            ]}
          >
            {componentDisabled ? (
              <Input
                id="confirm_password"
                name="confirm_password"
                label="ログインパスワード（確認用)"
                placeholder="************"
                type="text"
                disabled={true}
                className="password-hidden"
              />
            ) : (
              <Input
                id="confirm_password"
                name="confirm_password"
                label="ログインパスワード（確認用)"
                type="password"
                disabled={componentDisabled}
              />
            )}
          </Form.Item>

          <Form.Item
            name="is_notify"
            valuePropName="checked"
            label={
              <div>
                被災者からの支援要請を受け取りますか？{" "}
                <QuestionCircleOutlined
                  onClick={() => {
                    setIsNotifyQuestion(true);
                  }}
                />
              </div>
            }
          >
            {is_notify_list.map((item) => {
              return (
                <Checkbox
                  key={item.label}
                  onChange={handleChange}
                  checked={item.value === isNotify}
                  value={item.value}
                  className="form_font"
                  name="is_notify"
                  id="is_notify"
                  disabled={componentDisabled}
                >
                  {item.label}
                </Checkbox>
              );
            })}
          </Form.Item>

          <Form.Item
            name="agreement"
            valuePropName="checked"
            label={
              <div>
                同意事項 <span style={{ color: "#ff676e", "font-size": "14px" }}>*</span>
              </div>
            }
            className="form_agreement"
            rules={[
              () => ({
                validator(_, value) {
                  if (checked_1 && checked_2 && checked_3) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("同意する場合はすべてチェックしてください。")
                  );
                }
              })
            ]}
          >
            <Checkbox
              onChange={onCheckboxChange_1}
              className="form_font"
              disabled={componentDisabled}
              checked={checked_1}
            >
              「支援要請についてのご確認事項と利用方法」に同意します
            </Checkbox>
            <Checkbox
              onChange={onCheckboxChange_3}
              className="form_font"
              disabled={componentDisabled}
              checked={checked_3}
            >
              「プライバシーポリシー」に同意します
            </Checkbox>
            <Checkbox
              onChange={onCheckboxChange_2}
              className="form_font"
              disabled={componentDisabled}
              checked={checked_2}
            >
              「ネットワーク利用規約」に同意します
            </Checkbox>
          </Form.Item>
        </div>
        <FixedBottom>
          {/* <SignUpAction style={scrollDirection === "up" ? styles.active : styles.hidden}> */}
          {componentDisabled === true ? (
            <StyleButton>
              <Button
                loading={loading}
                type="primary"
                style={{ background: "#0059AB", color: "#fff" }}
                disabled={false}
                htmlType="submit"
                className="button-ok"
              >
                登録
              </Button>
              <Button
                style={{
                  background: "#53B7B2",
                  color: "#fff",
                  border: "none"
                }}
                type="secondary cancel"
                disabled={disable}
                onClick={() => {
                  setLandlineFail(true);
                  setMobileFail(true);
                  setComponentDisabled(false);
                }}
              >
                編集に戻る
              </Button>
            </StyleButton>
          ) : null}

          {componentDisabled === false ? (
            <StyleButton>
              <Button
                loading={loading}
                type="primary"
                onClick={async () => {
                  await form
                    .validateFields()
                    .then(async (result) => {
                      await validateFormData(result);
                      setComponentDisabled(true);
                    })
                    .catch((err) => {
                      setComponentDisabled(false);
                    });
                }}
                className="button-ok"
              >
                次へ
              </Button>
              <Button
                disabled={disable}
                type="cancel"
                onClick={() => {
                  navigate("/login");
                }}
                className="button-cancel"
              >
                戻る
              </Button>
            </StyleButton>
          ) : null}
        </FixedBottom>
        {/* </SignUpAction> */}
      </Form>
      <TermAggrement1 check_1={check_1} setCheck1={setCheck1} setChecked1={setChecked1} />
      <TermAggrement2 check_2={check_2} setCheck2={setCheck2} setChecked2={setChecked2} />
      <TermAggrement3 check_3={check_3} setCheck3={setCheck3} setChecked3={setChecked3} />
      <Modal
        className="note-modal"
        visible={isNotifyQuestion}
        centered
        footer={null}
        closable={false}
        width={355}
        onCancel={() => {
          setIsNotifyQuestion(false);
        }}
      >
        <div className="note-content">
          <p>
            緊急時、自身が登録した備蓄品に対して被災者からの支援要請を受け取るか否かの設定です。
          </p>
        </div>
      </Modal>
    </StyledDiv>
  );
};

export default SignUpForm;
