import React from 'react'

const EmailSuccess = (props) => {
    return (
      <svg {...props} width={67} height={67} viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M40.4998 73.3333C58.9093 73.3333 73.8332 58.4095 73.8332 40C73.8332 21.5905 58.9093 6.66666 40.4998 6.66666C22.0903 6.66666 7.1665 21.5905 7.1665 40C7.1665 58.4095 22.0903 73.3333 40.4998 73.3333Z" fill="#5FD88E" />
        <path d="M35.7668 51.9333C35.1001 51.9333 34.4668 51.6667 34.0001 51.2L24.5668 41.7667C23.6001 40.8 23.6001 39.2 24.5668 38.2333C25.5335 37.2667 27.1335 37.2667 28.1001 38.2333L35.7668 45.9L52.9001 28.7667C53.8668 27.8 55.4668 27.8 56.4335 28.7667C57.4001 29.7333 57.4001 31.3333 56.4335 32.3L37.5335 51.2C37.0668 51.6667 36.4335 51.9333 35.7668 51.9333Z" fill="#5FD88E" />
      </svg>





    )
}
export default EmailSuccess;
