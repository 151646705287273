import styled, { css } from 'styled-components';
import { COLORS } from 'utils/constant';
import { margin, padding, textAlign } from './styledUtils';

export const Title = styled.div(
  {
    fontWeight: '600',
    color: COLORS.MAIN_BLUE
  },
  textAlign,
  margin,
  padding,
  (p) => p.color && { color: p.color },
  (p) => p.fontSize && { fontSize: p.fontSize }
);

export const TextWithLabel = ({ label, text, margin = '0 0 8px 0' }) => {
  return (
    <TextWithLabelStyled margin={margin} style={{ width: '100%' }}>
      {label && <Label>{label}</Label>}
      <Text>
        {text}
      </Text>
    </TextWithLabelStyled>
  );
};

const TextWithLabelStyled = styled.div({}, margin);

export const EmptyText = styled.div`
  height: 24px;
  width: 60px;
  font-size: 10px;
  padding-top: 5px;
  // border-bottom: 2px solid #ddd;
`;

export const Text = ({ children }) => {
  if (children === '' || !children) return <EmptyText> </EmptyText>;
  return <TextStyled style={{ wordWrap: 'break-word' }}>{children}</TextStyled>;
};

const TextStyled = styled.span`
  pre {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${COLORS.BLACK};
    display: block;
    font-family: 'Segoe UI', SegoeUI, 'Yu Gothic UI', 'Meiryo UI', 'Helvetica Neue',
      Helvetica, Arial, sans-serif;
    margin-bottom: unset;
  }
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 23px;
  color: ${COLORS.DARK_GRAY};
  ${(p) =>
    p.required &&
    css`
      ::after {
        content: '*';
        color: red;
        margin-left: 5px;
      }
    `}
    
  ${(p) =>
    p.fontSize &&
    css`
      font-size: ${p.fontSize}px;
    `}

`;
const TextWL = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${COLORS.BLACK};
  line-height: 24px;
`;
