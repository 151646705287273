import { Table as ATable } from 'antd';
import Button from 'components/Button/index';
import styled from 'styled-components';

const StyledTable = styled(ATable)`
  .ant-table-thead th.ant-table-column-sort {
    background: #0059ab1a;
  }

  th.ant-table-cell {
    line-height: 24px;

    &:before {
      content: '';
      width: 0;
      display: none;
    }
  }

  td.ant-table-column-sort {
    background: #0059ab1a;
  }

  .ant-table-row-expand-icon-cell {
    padding: 0;
    display: none;
  }

  .ant-table-expand-icon-col {
    width: 0;
    display: none;
  }

  .ant-table-expanded-row {
    & .ant-table-cell {
      white-space: unset !important;
    }
  }

  .ant-table-cell {
    white-space: nowrap;
    font-size: 16px;
    line-height: 24px;

    &.ant-table-column-has-sorters {
      &:hover {
        background: #0059ab1a;
      }
    }
  }

  .user-list-page-table-row-odd {
    background-color: #f6f9ff;

    .ant-table-cell-fix-right.ant-table-cell-fix-right-first {
      background-color: #f6f9ff;
    }

    & + tr.ant-table-expanded-row-level-1 .ant-table-expanded-row-fixed {
      background-color: white;
    }
  }

  .user-list-page-table-row-even {
    background-color: white;

    .ant-table-cell-fix-right.ant-table-cell-fix-right-first {
      background-color: white;
    }

    & + tr.ant-table-expanded-row-level-1 .ant-table-expanded-row-fixed {
      background-color: #f6f9ff;
    }
  }

  .ant-table-expanded-row.ant-table-expanded-row-level-1 {
    * {
      position: unset !important;
    }
  }

  .ant-pagination {
    font-size: 16px;
  }

  .ant-pagination-item-link {
    border: none;
    color: #0059ab;
  }

  .ant-pagination-disabled .ant-pagination-item-link {
    border: none;
    color: #7b7b7b;
  }

  .ant-pagination-item {
    border: 1px solid white;

    a {
      color: #7b7b7b;
    }
  }

  .ant-pagination-item-active {
    background-color: #0059ab;
    border-radius: 8px;

    a {
      color: white;
    }
  }
`;

const style = {
  info_company: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '0.5px solid #C6C6C6',
    paddingBottom: 12,
    marginBottom: 12
  },

  title: {
    width: '30%',
    color: '#7B7B7B',
  },

  content: {
    width: '70%',
    fontSize: 16,
    color: '#000'
  }
};

export default (props) => {

  const { onOpenModal } = props;

  const handleGetRecord = (value) => {
    onOpenModal && onOpenModal(value);
  }

  const ExpandedRowContent = ({ text }) => {
    return (
      <>
        {
          text.user_reserve.map(item => {
            return (
              <div style={style.info_company} key={item.id} className="info_company">
                <div
                  style={{
                    width: '15%',
                    paddingRight: "12px"
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <span style={style.title}>備蓄品</span>
                    <span style={style.content}>備蓄水</span>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <span style={style.title}>数量</span>
                    <h5 style={style.content}>
                      10ケース
                    </h5>
                  </div>
                </div>
                <div
                  style={{
                    width: '37%',
                    paddingRight: "12px"
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <span style={style.title}>住所</span>
                    <h5 style={style.content}>〒1050011 東京都港区芝公園1-7-6KDX浜松町プレイス６F</h5>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <span style={style.title}>期限</span>
                    <h5 style={style.content}>2030/02/22</h5>
                  </div>
                </div>
                <div
                  style={{
                    width: '20%',
                    paddingRight: "12px"
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <span style={{
                      width: '50%',
                      color: '#7B7B7B',
                    }}>被災者からの支援要請を受け取りますか？</span>
                    <h5 style={{
                      width: '50%',
                      fontSize: 16,
                      color: '#000'
                    }}>受け取る</h5>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <span style={{
                      width: '50%',
                      color: '#7B7B7B',
                    }}>備蓄情報公開設定</span>
                    <h5 style={{
                      width: '50%',
                      fontSize: 16,
                      color: '#000'
                    }}>公開する</h5>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <span style={{
                      width: '50%',
                      color: '#7B7B7B',
                    }}>登録ID</span>
                    <h5 style={{
                      width: '50%',
                      fontSize: 16,
                      color: '#000'
                    }}>20340618</h5>
                  </div>
                </div>
                <div
                  style={{
                    width: '28%'
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <span style={{
                      width: '50%',
                      color: '#7B7B7B',
                    }}>支援要請有無</span>
                    <h5 style={{
                      width: '50%',
                      fontSize: 16,
                      color: '#000'
                    }}>有</h5>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <span style={{
                      width: '50%',
                      color: '#7B7B7B',
                    }}>データ最終更新日</span>
                    <h5 style={{
                      width: '50%',
                      fontSize: 16,
                      color: '#000'
                    }}>2022/06/28 24:00:00</h5>
                  </div>
                </div>
              </div>
            )
          })
        }
        <div
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button onClick={() => handleGetRecord(text.email)}
            style={{
              width: 282,
              height: 34,
              background: '#FFFFFF',
              border: '1px solid #D9D9D9',
              borderRadius: 4,
            }}
          >
            <h5
              style={{
                color: '#0059AB',
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              このユーザのすべての情報をみる
            </h5>
          </Button>
        </div>
      </>

    );
  };

  return (
    <StyledTable
      expandable={{
        expandedRowRender: (record) => (
          <>
            <ExpandedRowContent text={record} className="content_expan" />
          </>
        ),
        expandIcon: (props) => {
          return (
            <button
              style={{ display: 'none' }}
              id={props.record.id}
              onClick={(e) => props.onExpand(props.record, e)}
            />
          );
        }
      }}
      rowClassName={(_, index) => {
        if (index % 2) {
          return 'user-list-page-table-row-even';
        }
        return 'user-list-page-table-row-odd';
      }}
      {...props}
    />
  );
};
