import React, { Fragment } from "react";
import { LocationItemWrapper } from "./styles";
import { Typography } from "antd";
import { formatNumber, LOCATION_STATUS } from "../../utils/constant";

const { Title, Paragraph } = Typography;
const LocationItem = ({ item, name }) => {
  return (
    <Fragment>
      <LocationItemWrapper id={name}>
        {
          item.company_name && item.current_status === LOCATION_STATUS.REQUESTED && (
            <Typography className="location-block">
              <Title className="location-block-title" level={5}>事業者名/自治体名</Title>
              <Paragraph className="location-block-content">{item.company_name}</Paragraph>
            </Typography>
          )
        }
        {
          item.item_name && (
            <Typography className="location-block">
              <Title className="location-block-title" level={5}>備蓄品</Title>
              <Paragraph className="location-block-content">{item.item_name}</Paragraph>
            </Typography>
          )
        }
        <Typography className="location-block">
          <Title className="location-block-title" level={5}>数量</Title>
          <Paragraph className="location-block-content">{formatNumber(item?.quantity)} {item?.item_unit}</Paragraph>
        </Typography>
        {
          item.address && (
            <Typography className="location-block">
              <Title className="location-block-title" level={5}>備蓄場所</Title>
              <Paragraph className="location-block-content">{item.address}</Paragraph>
            </Typography>
          )
        }
      </LocationItemWrapper>
    </Fragment>
  );
};

export default LocationItem;
