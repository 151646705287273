import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  /*** iPhone and iOS Form Input Zoom Fixes ***/
  /* Fix Input Zoom on devices older than iPhone 5: */
  @media screen and (device-aspect-ratio: 2/3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"] {
      font-size: 16px;
    }
  }

  /* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
  @media screen and (device-aspect-ratio: 40/71) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"] {
      font-size: 16px;
    }
  }

  /* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
  @media screen and (device-aspect-ratio: 375/667) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="tel"], input[type="url"] {
      font-size: 16px;
    }
  }

  /* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
  @media screen and (device-aspect-ratio: 9/16) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="tel"], input[type="url"] {
      font-size: 16px;
    }
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', SegoeUI, 'Yu Gothic UI', 'Meiryo UI', "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    background: #F6F9FF;
    width: 100%;
    height: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    overflow-x: hidden;

    #root {
      display: block;
      width: 100%;
      height: 100%;
      background: #F6F9FF;
    }

    .stockpile-modal, .notification-modal, .logout-modal, .note-modal {
      .ant-modal-content {
        background: #FFFFFF;
        border-radius: 8px;
      }

      .ant-modal-body {
        padding: 25px 25px 0;

        p {
          max-width: 260px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #333333;
          margin: 0 auto;
        }
      }

      .ant-modal-footer {
        display: flex;
        column-gap: 15px;
        width: 100%;
        padding: 15px 25px 25px;
        border-top: 0;

        .ant-btn-default, .ant-btn-primary {
          height: auto;
          padding: 10px 15px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          border-radius: 4px;
        }

        .ant-btn-default {
          color: #0059AB;
          width: 45%;
        }

        .ant-btn-primary {
          color: #FFFFFF;
          width: 55%;
        }
      }
    }

    .logout-modal {
      .ant-modal-body {
        padding: 24px 24px 0;

        p {
          max-width: 320px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #333333;
          margin: 0 auto;
        }
      }

      .ant-modal-footer {
        justify-content: center;
        padding: 12px 24px 24px;

        .ant-btn-default {
          color: #0059AB;
          width: 115px;
        }

        .ant-btn-primary {
          color: #FFFFFF;
          width: 115px;
          border: 1px solid #D9D9D9;
          box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
          border-radius: 4px;
        }
      }
    }

    .notification-modal {
      .ant-modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 25px 25px;

        p {
          margin-top: 15px;
        }
      }
    }

    .note-modal {
      .ant-modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 40px;

        p {
          text-align: center;
          max-width: 290px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }
      }
    }
  }
  
  input, select {
    font-size: 100%;
  }
`;

export default GlobalStyle;
