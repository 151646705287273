import styled from "styled-components";

export const UserSupportRequestLocationWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  padding: 25px 15px 80px;
  background-color: white;

  .page-title {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #0059AB;
    margin-bottom: 8px;
  }

  .page-title-note {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-bottom: 25px;
  }

  .row-button{
    margin-bottom: 12px;
  }

  .btn-select-all, .btn-delete-all, .btn-return, .btn-next {
    display: block;
    width: 100%;
    height: auto;
    padding: 12px 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    border-radius: 4px;
  }

  .btn-select-all {
    color: white;
    border: 1px solid transparent;
    background: #0072ff94;
  }

  .btn-delete-all{
    color: white;
    border: 1px solid transparent;
    background: #ff4d4fcf;
  }

  .btn-return {
    color: #0059AB;
    border: 1px solid #D9D9D9;
    background: white;
  }

  .btn-next {
    color: white;
    background: #0059AB;
    border: 1px solid #0059AB;
  }
  
  .btn-back-wrapper{
    position: fixed;
    left: 50%;
    bottom: 0;
    z-index: 99;
    max-width: 375px;
    width: 100%;
    padding: 10px 15px;
    background-color: white;
    transform: translateX(-50%);
  }
`;
