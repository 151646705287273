import styled from "styled-components";

export const UserSearchStockpileInformationWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  padding: 25px 15px 80px;
  background-color: white;

  .ant-form-item {
    margin-bottom: 0;
  }

  .page-title {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #0059AB;
    margin-bottom: 25px;
  }

  .page-help-title {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #0059AB;
    margin: 0 0 9px !important;
  }

  .page-sub-title {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 23px;
    color: #000000;
    margin: 0 0 9px !important;
  }

  .location-title {
    font-size: 12px;
    color: #7B7B7B;
    margin-bottom: 5px;
  }

  .form-wrapper {
    width: 100%;

    .form-block {
      margin-bottom: 15px;

      &-title {
        font-size: 12px;
        color: #7B7B7B;
        margin-bottom: 5px;
      }
    }

    .btn-submit {
      display: block;
      width: 100%;
      height: auto;
      padding: 12px 16px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      background: #0059AB;
      border: 1px solid #0059AB;
      border-radius: 4px;
    }
  }

  .slick-slider {
    margin-bottom: 40px;

    .location-dot {
      display: block;
      width: 12px;
      height: 12px;
      background: #D9D9D9;
      border-radius: 50%;
    }

    .slick-active {
      .location-dot {
        background: #0059AB;
      }
    }
  }

  .btn-search, .btn-back {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 16px;
    padding: 12px 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    background: #53B7B2;
    border: 1px solid #53B7B2;
    border-radius: 4px;
  }

  .btn-back {
    bottom: 0;
    color: #0059AB;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
    border-radius: 4px;
    margin: 0;
  }
  
  .text-center {
    text-align: center;
  }
  
  .btn-back-wrapper {
    position: fixed;
    left: 50%;
    bottom: 0;
    z-index: 99;
    max-width: 375px;
    width: 100%;
    padding: 10px 15px;
    background-color: white;
    transform: translateX(-50%);
  }
`;
