export const actionTypes = {
  FETCH_RESERVE_INFO: 'FETCH_RESERVE_INFO',
  FETCH_RESERVE_DETAIL: 'FETCH_RESERVE_DETAIL',
  SET_NEW_RESERVE: 'SET_NEW_RESERVE',
  SET_EDIT_RESERVE: 'SET_EDIT_RESERVE',
};

export const fetchReserveInfo = (payload) => ({
  type: actionTypes.FETCH_RESERVE_INFO,
  payload
});

export const fetchReserveDetail = (payload) => ({
  type: actionTypes.FETCH_RESERVE_DETAIL,
  payload
});

export const setNewReserve = (payload) => ({
  type: actionTypes.SET_NEW_RESERVE,
  payload
});

export const setEditReserve = (payload) => ({
  type: actionTypes.SET_EDIT_RESERVE,
  payload
});
