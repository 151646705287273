import MainLayout from 'components/Layout/MainLayout';
import CustomButton from 'components/misc/CustomButton';
import Tabs from 'components/misc/Tabs';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Tab from './Tab';

export const TAB_STATUS = {
  waiting: 'waiting',
  replied: 'replied',
  cancelled: 'cancelled'
};
export default function () {
  return (
    <MainLayout
      title="支援要請受信表示"
      footer={
        <Link to="/top-user">
          <CustomButton>戻る</CustomButton>
        </Link>
      }
    >
      <Tabs
        tabs={[
          {
            title: '未回答',
            path: TAB_STATUS.waiting,
            element: <Tab type={TAB_STATUS.waiting} key={TAB_STATUS.waiting} />
          },
          {
            title: '回答済み',
            path: TAB_STATUS.replied,
            element: <Tab type={TAB_STATUS.replied} key={TAB_STATUS.replied} />
          },
          {
            title: 'キャンセル済み',
            path: TAB_STATUS.cancelled,
            element: <Tab type={TAB_STATUS.cancelled} key={TAB_STATUS.cancelled} />
          }
        ]}
      />
    </MainLayout>
  );
}

export const MinHeight = styled.div`
  // height: calc(100vh - 350px);
  // overflow-y: auto;
`;
