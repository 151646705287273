import styled from "styled-components";

export const StockpileWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 20px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-bottom: 60px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);

  .stockpile-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    color: #0059AB;
    margin-bottom: 15px;
  }

  .stockpile-list {
    margin-bottom: 15px;
    border: 1px solid #BFD8E9;
    border-radius: 4px;

    &-head {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #0059AB;
      background: rgba(0, 89, 171, 0.1);

      span {
        display: inline-block;
        padding: 8px 10px;
      }
    }

    &-body {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: rgba(0, 0, 0, 0.85);

      span {
        display: inline-block;
        padding: 8px 10px;

        &.text-left {
          display: block;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #000000;
          text-align: left;
        }
      }
    }
  }

  .form-wrapper {
    width: 100%;

    label {
      display: inline-block;
      padding-right: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 23px;
      color: #7B7B7B;

      &:before {
        position: absolute;
        right: 0;
        top: 3px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        color: #FF676E;
        font-size: 13px !important;
        margin: 0 !important;
      }
    }

    .ant-input-textarea-show-count.ant-input-textarea-in-form-item::after {
      position: absolute;
      top: -25px;
      right: 0;
      font-size: 12px;
      letter-spacing: -1px;
      color: #7B7B7B;
    }

    textarea.ant-input {
      background: #FAFCFE;
      padding: 5px 12px;
      border: 1px solid #0059AB;
      border-radius: 2px;
      resize: none;
    }

    input.ant-input {
      background: #FAFCFE;
      padding: 5px 12px;
      border: 1px solid #0059AB;
      border-radius: 2px;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .btn-submit {
      display: block;
      width: 100%;
      height: auto;
      padding: 12px 16px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      background: #0059AB;
      border: 1px solid #0059AB;
      border-radius: 4px;
      margin-top: 16px;
    }
  }
`;
