export const actionTypes = {
  FETCH_USER_INFO: 'FETCH_USER_INFO',
  SET_EDIT_USER: 'SET_EDIT_USER',
  SET_RESERVE_ADDRESS_REQUEST_HELP: 'SET_RESERVE_ADDRESS_REQUEST_HELP'
};

export const fetchUserInfo = (payload) => ({
  type: actionTypes.FETCH_USER_INFO,
  payload
});

export const setEditUser = (payload) => ({
  type: actionTypes.SET_EDIT_USER,
  payload
});

export const setReserveAddressRequestHelp = (payload) => ({
  type: actionTypes.SET_RESERVE_ADDRESS_REQUEST_HELP,
  payload
});
  