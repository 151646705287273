import styled, { css } from 'styled-components';
import { COLORS } from 'utils/constant';

export const ButtonVariants = {
  SUCCESS: 'SUCCESS',
  INFO: 'INFO',
  DANGER: 'DANGER'
};
export default styled.div`
  border: none;
  background: #eee;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 0px;
  gap: 8px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  border-radius: 4px;
  ${(p) =>
    p.variant === ButtonVariants.INFO &&
    css`
      background: rgba(0, 89, 171, 0.1);
      color: ${COLORS.MAIN_BLUE};
    `}

  ${(p) =>
    p.variant === ButtonVariants.SUCCESS &&
    css`
      background: rgba(95, 216, 142, 0.1);
      color: ${COLORS.GREEN};
      height: 50px;
      line-height: 17px;
    `}
  ${(p) =>
    p.variant === ButtonVariants.DANGER &&
    css`
      background: rgba(255, 103, 110, 0.1);
      color: ${COLORS.RED};
      height: 50px;
      line-height: 17px;
    `}
`;
