import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { Button, Col, Row, Typography } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useSmoothScroll from "react-smooth-scroll-hook";
import { USER_PATH } from "utils/constant";
import { UserMap } from "../index";
import LocationItem from "../LocationItem";
import { LocationListWrapper } from "./styles";

const { Title } = Typography;

const LocationList = ({ locations }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef(null);
  const [ fullScreen, setFullScreen ] = useState(false);
  const [itemId, setItemId] = useState("");
  const { scrollTo } = useSmoothScroll({
    ref,
    speed: 100,
    direction: "x"
  });

  useEffect(() => {
    if (itemId) {
      scrollTo(`#location-item-${itemId}`);
    }
  }, [itemId]);

  const goToRequestLocation = useCallback(() => navigate("/user-support-request-location", { replace: true }), [navigate]);

  const handleFullScreen = () => {
    const map = document.getElementById("map");
    setFullScreen(!fullScreen)
    map.classList.toggle("map");
  }

  return (
    <LocationListWrapper>
      <Row justify="center">
        <Col xs={24}>
          {
            locations.length > 0 &&
            (<>
              <Title className="location-title" level={3}>周辺の備蓄場所</Title>
              <div className="location-scroll" ref={ref}>
                {locations.map((location, index) =>
                  <LocationItem name={`location-item-${location.reserve_id}`}
                                key={`location-item-${index}`}
                                item={location} />
                )}
              </div>
              {location.pathname === USER_PATH.HELP_LOCATION && (
                <Button className="btn-search" type="primary" onClick={goToRequestLocation}>
                  この地点に支援要請する
                </Button>
              )}
            </>)
          }
        </Col>
        <Col xs={24} id="map">
          <div className="text-center" id="map-center">
            <Button className="button-fullscreen" onClick={handleFullScreen}>
              {
                fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />
              }
            </Button>
            <UserMap markers={locations} setItemId={setItemId}/>
          </div>
        </Col>
      </Row>
    </LocationListWrapper>
  );
};

export default LocationList;
