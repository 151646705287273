import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { COLORS } from 'utils/constant';
import Box from './Box';
import Flex from './Flex';

// Tabs components
export default function ({ tabs = [] }) {
  const [tabActive, setTabActive] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const [, tabFocus] = location.hash.split('#');
      const focusIdx = tabs.findIndex((item) => item.path === tabFocus);
      if (focusIdx) {
        setTabActive(focusIdx);
      }
    }
  }, [location]);

  return (
    <>
      <SuperLayout>
        <TabStyled>
          <TabCenter>
            <Flex>
              {tabs.map((tab, index) => (
                <TabItem
                  key={index}
                  onClick={() => setTabActive(index)}
                  active={tabActive === index}
                >
                  {tab.title}
                </TabItem>
              ))}
            </Flex>
          </TabCenter>
        </TabStyled>
      </SuperLayout>
      <Box mt={20} key={tabActive}>
        {tabs[tabActive].element}
      </Box>
    </>
  );
}
const TabStyled = styled.div`
  background: ${COLORS.WHITE};
  width: 100%;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  position: relative;
`;
const TabCenter = styled.div`
  width: 375px;
  margin: auto;
`;

const TabItem = styled.div`
  padding: 10px 0;
  margin: 0 15px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  ${(p) =>
    p.active &&
    css`
      border-bottom: 2px solid ${COLORS.MAIN_BLUE};
      color: ${COLORS.MAIN_BLUE};
    `};
`;
const SuperLayout = styled.div`
  width: 100vw;
  margin-left: calc(-50vw + 50%);
`;
