import { Button } from 'antd';
import styled from 'styled-components';
import { COLORS } from 'utils/constant';

export default styled(Button)`
  color: ${COLORS.MAIN_BLUE};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  width: ${(props) => (props.fitContent ? 'fit-content' : '100%')};
  height: ${(props) => (props.small ? '32px' : '50px')};
  border-radius: 4px;
  padding: 4px 15px;
`;
