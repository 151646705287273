import { message } from 'antd';
import { authenticate } from 'api';
import { ReactComponent as CloseIcon } from 'assets/svg/close-circle.svg';
import { ReactComponent as EmailIcon } from 'assets/svg/file-cloud.svg';
import { Button, Modal, SignUpForm } from 'components';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as EmailSuccess } from '../../assets/svg/tick-circle.svg';
import PublicLayout from '../../components/Layout/PublicLayout';
import utils from './validateFormData';
import { useEffect } from 'react';

const SignUpPage = () => {
  const SignUpVerifyModal = styled(Modal)`
    .ant-modal-body {
      padding: 24px 16px;
      padding-bottom: 0px;
    }
    .ant-modal-close{
      margin: unset;
      height: unset;
      width: unset;
      top: 24px;
      right: 24px;

      .ant-modal-close-x{
        width: unset;
        height: unset;
        line-height: unset;
      }
    }
    .ant-modal-content {
      width: 359px;
      background: #ffffff;
      border-radius: 8px;
      p {
        font-weight: 400;
        font-size: 13px;
        line-height: 23px;
        text-align: center;
        margin: 16px 40px;
        margin-bottom: 24px;
      }
      h1 {
        text-align: center;
        color: #333;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
      }
      h3 {
        text-align: center;
        color: #333;
        font-size: 14px;
      }
    }
    .ant-modal-footer {
      padding: 1px;
      border: none !important;
      border-top: none;
    }
    .email-icon {
      margin-top: 24px;
      margin-bottom: 16px;
      text-align: center;
    }
  `;
  const SuccessModal = styled(Modal)`
    .ant-modal-content {
      border-radius: 8px;

      .ant-modal-close{
        margin: unset;
        width: unset;
        height: unset;
        top: 24px;
        right: 24px;

        .ant-modal-close-x{
          width: unset;
          line-height: unset;
          height: unset;
        }
      }

      .ant-modal-body{
        padding: 24px 24px 0px 24px;

        .email-icon{
          display: flex;
          justify-content: center;      
          margin: 36px 0px 8px;
        }
        p {
          font-weight: 400;
          font-size: 13px;
          line-height: 23px;
          text-align: center;
          color: #333333;
          margin-bottom: unset;
        }
      }
      .ant-modal-footer {
        padding: 24px;
        border: none !important;
        border-top: none;
        .ant-btn {
          height: 50px;
          margin: unset;
        }
      }
    }
  `;
  const [landlineFail, setLandlineFail] = useState(true);
  const [mobileFail, setMobileFail] = useState(true);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [openModalVerify, setOpenModalVerify] = useState(false);
  const [isNotify, setIsNotify] = useState("true");
  const [customerType, setCustomerType] = useState(0);
  const [cityState, setCityState] = useState({ province: '', city: '' });
  const [zipcode, setZipcode] = useState('');
  const navigate = useNavigate();

  const register_id = useSelector(state => state.authenticate.valid_key);

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const code = searchParams.get('code');
  const accesstoken = searchParams.get('accesstoken');

  const onRetry = () => {
    setVisible(false);
    navigate('/login');
  };
  const onVerifyRetry = () => {
    setVisible(false);
    navigate('/login');
  };

  const validateFormData = async (formData) => {
    await utils.validate_customer_type_id(customerType);
    await utils.validate_is_notify(isNotify);
    const formSignUp = await utils.validate_phone_number(
      formData,
      isNotify,
      customerType,
      formData.landline_number,
      formData.mobile_phone_number,
      setLandlineFail,
      setMobileFail,
      landlineFail,
      mobileFail,
      cityState
    );
    return formSignUp;
  };

  const signUpAPI = async (formSignUp) => {
    try {
      const response = await authenticate.signup(formSignUp);
      if (response.ResponseMetadata.HTTPStatusCode === 200) {
        await authenticate.verify_email_signup({ email: formSignUp.email, password: formSignUp.password });
      }
    } catch (err) {
      setLoading(false);
      throw err;
    }
  };

  const activeEmailAPI = async () => {
    try {
      const response = await authenticate.confirm_verify_email({ access_token: accesstoken, email_code: code, email });
      if (response.ResponseMetadata.HTTPStatusCode === 200) {
        setOpenModalVerify(true)
      }
    } catch (err) {
      message.error("アクセストークンの有効期限が切れています。");
    }
  };

  const onSignUp = async (formData) => {
    try {
      setLoading(true);
      const formSignUp = await validateFormData(formData);
      const newFormSignUp = { ...formSignUp, registration_id: `${register_id.first_number}-${register_id.last_number}` };
      await signUpAPI(newFormSignUp);
      setLoading(false);
      setVisible(true);
    } catch (err) {
      message.error('このメールアドレスは既に登録されています。');
    }
  };

  useEffect(() => {
    if (typeof email === 'string' && typeof code === 'string' && typeof accesstoken === 'string' && email && code && accesstoken) {
      const activeEmail = async () => {
        await activeEmailAPI();
      }
      activeEmail();
    }
  }, [])

  return (
    <>
      <PublicLayout isLogoBlue>
        <SignUpForm
          onSubmit={onSignUp}
          loading={loading}
          isNotify={isNotify}
          setIsNotify={setIsNotify}
          customerType={customerType}
          setCustomerType={setCustomerType}
          cityState={cityState}
          setCityState={setCityState}
          zipcode={zipcode}
          setZipcode={setZipcode}
          setLandlineFail={setLandlineFail}
          setMobileFail={setMobileFail}
          validateFormData={validateFormData}
        />

        <SignUpVerifyModal
          visible={visible}
          onOk={onRetry}
          onCancel={onRetry}
          closeIcon={<CloseIcon />}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
        >
          <div className="email-icon">
            <EmailIcon width={111} height={93} />
          </div>
          <p>
            アカウント認証のURLが記載されたメールを送信しました。
            <br />
            お送りしたメールよりお手続きください。
          </p>
        </SignUpVerifyModal>
      </PublicLayout>
      <SuccessModal
        visible={openModalVerify}
        onOk={onVerifyRetry}
        onCancel={onVerifyRetry}
        footer={
          <Button onClick={onVerifyRetry} type="primary">
            ログイン
          </Button>
        }
      >
        <div className="email-icon">
          <EmailSuccess width={80} height={80} />
        </div>
        <p>アカウントの登録が完了しました。</p>
      </SuccessModal>
    </>
  );
};

export default SignUpPage;
