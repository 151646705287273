import styled from 'styled-components';
import Status from 'components/misc/Status';

export default function ({ actions = [], children }) {
  return (
    <Styled>
      <AbsoluteHeader>
        {actions.map((item, idx) => (
          <Status {...item} key={idx}>
            {item.title}
          </Status>
        ))}
      </AbsoluteHeader>
      {children}
    </Styled>
  );
}

const Styled = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 16px 15px;
  position: relative;
  margin-bottom: 15px;
`;
const AbsoluteHeader = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  width: 53%;
`;
