import filePDF3 from "assets/pdf/file3.pdf";
import { Button, Modal } from "components";
import styled from "styled-components";
import axios from "../../../../node_modules/axios/index";
const TermAggrement2 = ({ check_2, setCheck2, setChecked2 }) => {
  const StyleTermAggreement2 = styled(Modal)`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    .ant-modal-body {
      padding: 15px;
      background-color: #f2f5fe;
    }
    .ant-modal-footer {
      background-color: #f2f5fe;
    }
  `;

  const StyleTermAggreement = styled.div`
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    .term-aggrement-header {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #0059ab;
    }
    .term-aggrement-content {
      height: 550px;
      overflow-y: scroll;
      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      font-size: 13px;
      line-height: 23px;
      text-align: left;
      color: #333333;
      ol.ol-parent {
        margin-bottom: unset;
        padding-left: 3px;
        margin-left: 0px;
        counter-reset: item;
        li {
          display: block;
          &::before {
            content: "(" counters(item, ".") ") ";
            counter-increment: item;
            font-weight: bold;
          }
        }
        ol {
          counter-reset: item;
          padding-left: 15px;
          & > li {
            display: block;
            &::before {
              content: "(" counters(item, ".") ") ";
              counter-increment: item;
            }
          }
        }
      }
      ul {
        padding: 5px;
        margin-left: 10px;
      }
      p {
        text-align: left;
        margin-bottom: unset;
      }
    }
    .term-aggrement-button {
      display: flex;
      margin-top: 10px;
      .button-copy {
        color: #0059ab;
        font-weight: 700;
      }
      .button-agree {
        margin-left: 10px;
        font-weight: 700;
      }
    }
    .button-cancel {
      color: #0059ab;
      font-weight: 700;
    }
  `;

  const handleDowloadAggrement = () => {
    axios({
      url: filePDF3,
      method: "GET",
      responseType: "blob"
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "UACJ水の架け橋ネットワーク_ご利用規約.pdf"
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  return (
    <StyleTermAggreement2
      visible={check_2}
      closable={false}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      onOk={() => {
        setCheck2(false);
        setChecked2(true);
      }}
      onCancel={() => {
        setCheck2(false);
        setChecked2(false);
      }}
    >
      <StyleTermAggreement>
        <div className="term-aggrement-header">水の架け橋ネットワーク利用規約</div>
        <br />
        <div className="term-aggrement-content">
          <p>
            株式会社
            UACJ（以下「当社」といいます。）がユーザーに提供する水の架け橋ネットワーク（以
            下「本サービス」といいます。）は、この「水の架け橋ネットワーク利用規約」（以下「本規約」
            といいます。）に従って提供されます。
          </p>
          <p>
            <ul>
              <li>
                <b>第１条（適用）</b>
                <ol className="ol-parent">
                  <li>
                    本規約は、本サービスの利用に関する当社とユーザーの間の一切の関係に適用されま
                    す。本規約の内容に同意しない場合、本サービスを利用することはできません。
                  </li>
                  <li>
                    ユーザーは本サービスを実際に利用することによって本規約に有効かつ取消不能な
                    同意をしたものとみなされます。
                  </li>
                </ol>
              </li>
              <li>
                <b>第２条（本サービスによりユーザーに提供される主要な機能）</b>
                <p>本サ―ビスは、以下の機能をユーザーに提供します。</p>
                <ol className="ol-parent">
                  <li>他のユーザーへの被災事実と支援要請の発信</li>
                  <li>支援要請に対するユーザーの支援可否の照会及び回答受付</li>
                  <li>
                    支援可能と回答したユーザー（以下、「支援可能者」といいます。）の災害用備蓄水
                    「水の架け橋」の備蓄場所の位置情報と備蓄量の情報の支援要請者への提供
                  </li>
                  <li>支援要請をしたユーザーによる、支援要請を行う支援可能者の選択</li>
                  <li>
                    支援要請をしたユーザーと、前号により選択された支援可能者の電話番号とメール
                    アドレスの相互開示
                  </li>
                </ol>
              </li>
              <li>
                <b>第３条（ユーザー情報の利用）</b>
                <p>
                  1.当社は、本サービスに関して当社が取得したユーザーの個人情報について、以下の目
                  的で利用します。
                </p>
                <ol className="ol-parent">
                  <li>本サービスの提供、運営、管理のため</li>
                  <li>
                    ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）
                  </li>
                  <li>
                    メンテナンス、重要なお知らせなど必要に応じユーザーにご連絡するため
                  </li>
                  <li>
                    利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとする
                    ユーザーの特定をし、ご利用をお断りするため
                  </li>
                  <li>
                    ユーザーにご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていた
                    だくため
                  </li>
                  <li>上記の利用目的に付随する目的</li>
                </ol>
                <p>
                  2.当社は、前項に定める目的の達成に必要な範囲で、ユーザーの個人情報を第三者に提
                  供する場合があり、ユーザーは当社ユーザーの個人情報を第三者へ提供することに同意
                  します。
                </p>
              </li>
              <li>
                <b>第４条（ユーザー情報の取得）</b>
                <p>当社が取得するユーザー情報には以下の情報が含まれます。</p>
                <ol className="ol-parent">
                  <li>個人情報：氏名、所属組織名、住所、電話番号、メールアドレス</li>
                  <li>
                    その他情報：
                    災害用備蓄水「水の架け橋」の備蓄情報（備蓄場所の位置情報、備蓄
                    量など）
                  </li>
                </ol>
              </li>
              <li>
                <b>第５条（個人情報の取り扱い）</b>
                <ol className="ol-parent">
                  <li>
                    ユーザーは、当社が、本規約とは別に定める「プライバシーポリシー」に基づき、ユ
                    ーザーが本サービスを通じて当社に提供した個人情報（生存する個人に関する情報であ
                    って、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別する
                    ことができるもの（他の情報と容易に照合することができ、それにより特定の個人を識
                    別することができることとなるものを含む））を取り扱うことに同意します。
                  </li>
                  <li>
                    {" "}
                    支援要請をしたユーザーと、当該ユーザーに選択された支援可能者であるユーザー
                    に提供されたそれぞれの個人情報は、これらのユーザー間の責任において、取り扱われ
                    るものとします。これらのユーザー間による個人情報の漏洩等の問題が生じた場合、ユ
                    ーザー間でこれを解決するものとし、当社は一切の責任を負わないものとします。
                  </li>
                </ol>
              </li>
              <li>
                <b>第６条（禁止事項について）</b>
                <p>ユーザーは、以下に定める事項を行ってはならないものとします｡</p>
                <ol className="ol-parent">
                  <li>本規約に違反する行為</li>
                  <li>公序良俗に反する行為または法令に違反する行為</li>
                  <li>虚偽の情報、当社又は第三者の権利を侵害するおそれのある行為</li>
                  <li>
                    当社の意思に反して広告、勧誘情報などを掲載、転送する行為を含む一切の勧誘ま
                    たは営業行為
                  </li>
                  <li>本サービスの運営を妨げる一切の行為</li>
                  <li>
                    本サービスの利用上知りえた当社、他のユーザー、その他第三者の情報を漏洩し又
                    は本サービスの利用以外の目的で利用する行為
                  </li>
                  <li>
                    本サービスの利用上知りえた当社、他のユーザー、その他第三者を誹謗・中傷し、
                    名誉信用を傷つけ、またはプライバシーを侵害等する行為
                  </li>
                  <li>複製及び解析を行う行為</li>
                  <li>その他当社が不適切と判断する一切の行為</li>
                </ol>
              </li>
              <li>
                <b>第７条（本規約の違反等について）</b>
                <ol className="ol-parent">
                  <li>
                    ユーザーが以下の各号に該当した場合、当社は、当社の定める期間、本サービスの利
                    用を認めないこと、又はユーザーの本サービスの利用資格を取り消すことができるもの
                    とします｡
                    <ol className="ol-child">
                      <li>第 6 条（禁止事項について）に違反した場合</li>
                      <li>当社に対して事実に反する内容の届出または通知を行なった場合</li>
                      <li>当社又は第三者に不当に迷惑をかけたと当社が判断した場合</li>
                      <li>本サービスの運営を妨げ、又はその恐れのある行為があった場合</li>
                      <li>本規約に違反した場合</li>
                      <li>法令に違反した場合</li>
                      <li>
                        その他、本サービスの利用者として不適切であると当社が判断した場合
                      </li>
                    </ol>
                  </li>
                  <li>
                    .前項に定める当社の措置によりユーザーに損害が生じたとしても、当社はユーザーに
                    生じたすべての損害を賠償する責任を負わないものとします｡
                  </li>
                  <li>
                    ユーザーが本サービスに関連して、当社又は第三者に損害を与えた場合、ユーザーは、
                    その損害を直ちに賠償するものとします｡
                  </li>
                </ol>
              </li>
              <li>
                <b>第８条（本サービスの提供条件）</b>
                <ol className="ol-parent">
                  <li>
                    当社は、メンテナンス等のために、ユーザーに通知することなく、本サービスを停止
                    し、又は変更することがあります｡
                  </li>
                  <li>
                    本サービスの提供を受けるために必要な機器、通信手段などは、ユーザーの費用と責
                    任で備えるものとします｡
                  </li>
                  <li>
                    当社は、本サービスに中断、中止その他の障害が生じないことを保証しません｡
                  </li>
                  <li>
                    本サービスの取り扱いに関しては、国内及び外国の法令、他の電気通信事業者が定める契約約款等により制限されることがあります｡
                  </li>
                </ol>
              </li>
              <li>
                <b>第９条（当社の責任）</b>
                <ol className="ol-parent">
                  <li>
                    本サービスは、支援可能者がいる場合に支援要請者と支援可能者の相互の連絡を可能
                    にすることを目的としたものであり、支援可能者の有無および実際の支援の実施可否や
                    その結果、並びに備蓄水の返還について、当社が保証するものではありません。
                  </li>
                  <li>
                    本サービスは、支援可能者がいる場合に支援要請者と支援可能者の相互の連絡を可能
                    にすることを目的としたものであり、支援可能者の有無および実際の支援の実施可否や
                    その結果、並びに備蓄水の返還について、当社が保証するものではありません。
                  </li>
                  <li>
                    当社は、本サービスに関して、ユーザーが被った損害につき、当社に故意又は重過失
                    がない限り、何ら責任を負わないものとします｡また、当社が責任を負う場合における損
                    害賠償責任は、ユーザーが購入した当該損害に関連する災害用備蓄水「水の架け橋」の
                    代金額を上限額とします。
                  </li>
                  <li>
                    当社は、本規約の他の条項にかかわらず、管理可能な範囲を超えたシステム上の不具
                    合、天災、事変、原因不明のネットワーク障害その他の不可抗力により、本サービスの
                    履行を妨げられた場合には、当社に故意又は重過失がない限り、当社は当該不履行に基
                    づく責任につき免除されるものとします｡
                  </li>
                </ol>
              </li>
              <li>
                <b>第 10 条（業務委託）</b>
                <p>
                  当社は、本サービスの提供業務の全部又は一部を第三者に委託することができるものと
                  します。この場合、当社は、当該委託先に対し、本規約に定める当社の義務と同等の義
                  務を課すものとします。
                </p>
              </li>
              <li>
                <b>第 11 条（知的財産権）</b>
                <ol className="ol-parent">
                  <li>
                    本サービスに関して当社が使用する会社名、サービス名、商標、ロゴマーク等（以下
                    「商標等」といいます）は、当社又は当該権利を有する第三者が保有するものであり、
                    本サービス及び本アプリの提供は、ユーザーに対し商標等の利用その他の権利を許諾す
                    るものではありません。
                  </li>
                  <li>
                    本サービス及び本サービスを構成する素材（文字、写真、映像、音声等）に関する知
                    的財産権及びその他の権利の一切の権利は、当社又は当該権利を有する第三者に帰属し
                    ます。
                  </li>
                </ol>
              </li>
              <li>
                <b>第 12 条（権利義務の譲渡）</b>
                <p>
                  ユーザーは、本サービスに係るいかなる権利又は義務も第三者に移転又は譲渡すること
                  はできないものとします｡
                </p>
              </li>
              <li>
                <b>第 13 条（登録事項の変更）</b>
                <ol className="ol-parent">
                  <li>
                    ユーザーは、登録事項に変更のあった場合、すみやかに当社の定める手続きまたは本
                    サービス内の所定の方法により当社に届け出るものとします｡この届出のない場合、当
                    社は、登録事項の変更のないものとして取り扱うものとします｡
                  </li>
                  <li>
                    ユーザーは、当社に登録されたメールアドレスを変更したことを当社に届け出なかっ
                    た場合、本サービスを利用できなくなる場合があることを了承するものとします｡
                  </li>
                </ol>
              </li>
              <li>
                <b>第 14 条（当社からの通知）</b>
                <ol className="ol-parent">
                  <li>
                    当社は、ユーザーに対し、本サービスに関する必要な事項について、当社に登録され
                    たメールアドレスへの電子メールの送信その他当社が適当であると判断する方法によ
                    り、通知又は連絡等を行うものとします｡
                  </li>
                  <li>
                    ユーザーが連絡先の変更等を怠ったために当社からの通知又は連絡等が遅延又は不
                    着となった場合、通常到達すべきときに到達したものとみなします｡
                  </li>
                </ol>
              </li>
              <li>
                <b>第 15 条（本規約･本サービスの変更）</b>
                <ol className="ol-parent">
                  <li>
                    当社は、第 14
                    条（当社からの通知）に定める方法により通知又は連絡等を行うことに
                    よって、本規約を変更することができるものとします｡本規約を変更した場合、本サービ
                    スに関する一切の事項は変更後の規約によるものとします｡
                  </li>
                  <li>
                    当社は、本サービスの内容をいつでも変更、追加又は廃止することができるものとし
                    ます｡
                  </li>
                  <li>
                    当社が合併又は事業譲渡等の理由により、本規約上の地位を第三者に承継または譲り
                    受けさせる必要が生じた場合、当社は、ユーザーに対して本サービス内で通知すること
                    によって、本規約上の地位を当該第三者に承継または譲り受けさせることができるもの
                    とします。
                  </li>
                </ol>
              </li>
              <li>
                <b>第 16 条（サービスの中止・終了）</b>
                <ol className="ol-parent">
                  <li>
                    当社は、当社の都合により、ユーザーの同意を得ることなく及び何ら補償の義務を負
                    うことなく、いつでも本サービスを廃止することができるものとします｡
                  </li>
                  <li>
                    当社は、次の各号の一に該当する場合、本サービスの全部又は一部の提供を中断でき
                    るものとし、当該中断に起因してユーザーその他の第三者に損害が生じたとしても、一
                    切の責任を負わないものとします。
                    <ol className="ol-child">
                      <li>
                        火災、感染症の蔓延、停電、天災地変、戦争、暴動、労働争議等の事由により、本
                        サービスの提供が困難な場合
                      </li>
                      <li>
                        本サービスに必要なシステムの全部又は一部の保守・点検を行う場合
                      </li>
                      <li>
                        本サービスに必要なシステムの全部又は一部に故障、不具合等が生じた場合
                      </li>
                      <li>
                        法令又は政府機関等の要請により、本サービスの提供を停止する場合
                      </li>
                      <li>その他当社がやむをえないと判断した場合</li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <b>第 17 条（反社会的勢力の排除）</b>
                <ol className="ol-parent">
                  <li>
                    <b>
                      ユーザーは、次の各号に定める事項のいずれか一にも該当しないことを表明し、かつ
                      将来にわたっても該当しないことを表明し、保証するものとします。
                    </b>
                    <ol className="ol-child">
                      <li>
                        自ら（法人その他の団体にあっては、自らの役員を含みます。）が、暴力団、暴力
                        団員、暴力団員でなくなった時から 5
                        年を経過しない者、暴力団準構成員、暴力団関
                        係企業、総会屋、社会運動等標ぼうゴロ又は特殊知能暴力集団等その他これらに準じ
                        る者（以下総称して「暴力団員等」といいます。）であること。
                      </li>
                      <li>
                        ユーザーが法人その他の団体の場合にあっては、暴力団員等が経営を支配している
                        と認められる関係を有すること。
                      </li>
                      <li>
                        ユーザーが法人その他の団体の場合にあっては、暴力団員等が経営に実質的に関与
                        していると認められる関係を有すること。
                      </li>
                      <li>
                        自ら若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をも
                        って取引を行うなど、暴力団員等を利用していると認められる関係を有すること。
                      </li>
                      <li>
                        暴力団員等に対して資金等を提供し、又は便宜を供与するなどの関与をしていると
                        認められる関係を有すること。
                      </li>
                      <li>
                        ユーザーが法人その他の団体の場合にあっては、自らの役員又は自らの経営に実質
                        的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること。
                      </li>
                    </ol>
                  </li>
                  <li>
                    <b>
                      ユーザーは、自ら又は第三者をして次の各号に定める行為をしないことを表明し、保
                      証します。
                    </b>
                    <ol className="ol-child">
                      <li>
                        当社または第三者に対する「暴力団員による不当な行為の防止等に関する法律」第
                        ９条各号に定める暴力的要求行為
                      </li>
                      <li>当社または第三者に対する法的な責任を超えた不当な要求行為</li>
                      <li>当社に対し、脅迫的な言動または暴力を用いる行為</li>
                      <li>
                        偽計または威力を用いて相手方の業務を妨害し、または信用を毀損する行為
                      </li>
                      <li>前各号に準ずる行為</li>
                    </ol>
                  </li>
                  <li>
                    ユーザーは、ユーザーが反社会的勢力から不当要求又は業務妨害等の不当介入を受け
                    た場合は、これを拒否し、不当介入があった時点で、速やかに不当介入の事実を当社に
                    報告し、当社の捜査機関への通報及び当社の報告に必要な協力を行うものとします。
                  </li>
                  <li>
                    当社は、ユーザーに前三項のいずれかの規定に違反している事実が発覚（報道された
                    ことを含みます）したときは、何らの催告なしに、かつ、損害賠償・損失補償その他何
                    らの義務も負うことなく、本規約に基づく契約等その他ユーザーと当社との間で締結し
                    たすべての契約の全部または一部を解除することができるものとします。なお、本項に
                    よる解除が行われた場合であっても、ユーザーは当社に対し、何らの請求、主張、異議
                    申立ても行わないものとし、かつ、当社は、本項による解除によっても、ユーザーに対
                    する損害賠償請求は妨げられないものとします。
                  </li>
                </ol>
              </li>
              <li>
                <b>第 18 条（準拠法）</b>
                <p>本規約に関する準拠法は日本法とします｡</p>
              </li>
              <li>
                <b>第 19 条（管轄裁判所）</b>
                <p>
                  ユーザーと当社との間で訴訟が生じた場合、東京地方裁判所を第一審の専属的合意管轄
                  裁判所とします｡
                </p>
              </li>
            </ul>
          </p>
        </div>
        <div className="term-aggrement-button">
          <Button onClick={handleDowloadAggrement} className="button-copy">
            ダウンロード
          </Button>
          <Button
            onClick={() => {
              setCheck2(false);
              setChecked2(true);
            }}
            type="primary"
            className="button-agree"
          >
            同意する
          </Button>
        </div>
        <Button
          style={{ marginTop: "10px" }}
          onClick={() => {
            setCheck2(false);
            setChecked2(false);
          }}
          type="cancel"
          className="button-cancel"
        >
          キャンセル
        </Button>
      </StyleTermAggreement>
    </StyleTermAggreement2>
  );
};

export default TermAggrement2;
