import { message } from '../../../node_modules/antd/lib/index';
const LANDLINE_NUMBER_REGEX = '^[0-9]{10}$';
const MOBIPHONE_NUMBER_REGEX = '^[0-9]{11}$';

/*eslint no-unreachable: "error"*/
export default {
  validate_is_notify: (is_notify) => {
    if (is_notify === '') {
      message.error('被災者からの支援要請を受け取りますか？: 入力必須項目です。');
      throw new Error('被災者からの支援要請を受け取りますか？: 入力必須項目です。');
    }
  },
  validate_customer_type_id: (customerType) => {
    if (customerType === 0) {
      message.error('民間 / 自治体: 入力必須項目です。');
      throw new Error('民間 / 自治体: 入力必須項目です。');
    }
  },
  validate_phone_number: (
    formData,
    isNotify,
    customerType,
    landline_number,
    mobile_phone_number,
    setLandlineFail,
    setMobileFail,
    landlineFail,
    mobileFail,
    cityState
  ) => {
    const form = {
      company_name: formData.company_name,
      email: formData.email,
      password: formData.password,
      customer_type_id: customerType,
      name: formData.name,
      zip_code: formData.zip_code,
      province: cityState.province,
      city: cityState.city,
      street: formData.street,
      is_notify: isNotify,
      landline_number: formData.landline_number,
      mobile_phone_number: formData.mobile_phone_number
    };
    const form_landline = {
      company_name: formData.company_name,
      email: formData.email,
      password: formData.password,
      customer_type_id: customerType,
      name: formData.name,
      zip_code: formData.zip_code,
      province: cityState.province,
      city: cityState.city,
      street: formData.street,
      is_notify: isNotify,
      landline_number: formData.landline_number
    };
    const form_mobile = {
      company_name: formData.company_name,
      email: formData.email,
      password: formData.password,
      customer_type_id: customerType,
      name: formData.name,
      zip_code: formData.zip_code,
      province: cityState.province,
      city: cityState.city,
      street: formData.street,
      is_notify: isNotify,
      mobile_phone_number: formData.mobile_phone_number
    };
    if (
      (landline_number == null && mobile_phone_number == null) ||
      (landline_number === '' && mobile_phone_number === '')
    ) {
      message.error('固定電話または携帯電話番号を入力してください');
      throw new Error('固定電話または携帯電話番号を入力してください');
    }
    if (
      landline_number.match(LANDLINE_NUMBER_REGEX) &&
      mobile_phone_number.match(MOBIPHONE_NUMBER_REGEX)
    ) {
      setLandlineFail(false);
      setMobileFail(false);
      return form;
    } else if (
      (landline_number.match(LANDLINE_NUMBER_REGEX) && mobile_phone_number === '') ||
      (landline_number.match(LANDLINE_NUMBER_REGEX) && mobile_phone_number == null)
    ) {
      setLandlineFail(false);
      return form_landline;
    } else if (
      (mobile_phone_number.match(MOBIPHONE_NUMBER_REGEX) && landline_number === '') ||
      (mobile_phone_number.match(MOBIPHONE_NUMBER_REGEX) && landline_number === null)
    ) {
      setMobileFail(false);
      return form_mobile;
    } else {
      if (landline_number.match(LANDLINE_NUMBER_REGEX)) {
        setLandlineFail(false);
      } else {
        message.error('10桁の半角数字、ハイフン(-)なしで入力してください。');
        throw new Error('10桁の半角数字、ハイフン(-)なしで入力してください。');
      }
      if (mobile_phone_number.match(MOBIPHONE_NUMBER_REGEX)) {
        setMobileFail(false);
      } else {
        message.error('11桁の半角数字、ハイフン(-)なしで入力してください。');
        throw new Error('11桁の半角数字、ハイフン(-)なしで入力してください。');
      }
      if (
        landline_number.match(LANDLINE_NUMBER_REGEX) == null ||
        mobile_phone_number == ''
      ) {
        message.error('10桁の半角数字、ハイフン(-)なしで入力してください。');
        throw new Error('10桁の半角数字、ハイフン(-)なしで入力してください。');
      }
      if (
        mobile_phone_number.match(MOBIPHONE_NUMBER_REGEX) == null &&
        landline_number === ''
      ) {
        message.error('11桁の半角数字、ハイフン(-)なしで入力してください。');
        throw new Error('11桁の半角数字、ハイフン(-)なしで入力してください。');
      }
    }
  }
};
