import user from 'api/user';
import styled from 'styled-components';
import { Empty, Spin } from 'antd';
import Flex from 'components/misc/Flex';
import useFetch from 'hooks/useFetch';
import CardInfo from './CardInfo';
import { MinHeight } from './index';

export default function ({ type }) {
  const { data, loading, doFetch } = useFetch({
    fetcher: () => user.getUserRequested(type)
  });

  if (loading)
    return (
      <MinHeight>
        <Flex margin="30px" alignItems="center" justifyContent="center">
          <Spin />
        </Flex>
      </MinHeight>
    );
  return (
    <MinHeight>
      {data.length ? (
        data.map((item, idx) => (
          <CardInfo {...item} key={idx} type={type} refetch={doFetch} />
        ))
      ) : (
        <EmptyStyle
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>データなし</span>}
        />
      )}
    </MinHeight>
  );
}

const EmptyStyle = styled(Empty)`
  height: 600px;
  margin: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
