import { apiUrl } from "api/utils";
import baseApi from "../base";

const generateParams = (objectData) => Object.entries(objectData).map(([key, val]) => {
  if (val !== "" && val !== undefined) {
    return `${key}=${val}`;
  }
}).filter(x => x).join("&");

export const SEARCH_RESERVE = apiUrl("user/search_reserve");
export const REQUEST_HELP = apiUrl("user/request_help");

export default {
  sendRequestHelp: async (param) => {
    return await baseApi.authRequest(REQUEST_HELP, {
      method: 'POST',
      body: JSON.stringify(param)
    });
  },
  getUserSearchReserve: async (param) => {
    const objectData = {
      city: param.city,
      province: param.province,
      item_name: param.item_name,
      other_item_name: param.other_item_name,
      quantity: param.quantity,
      type: param.type
    };
    let queryParams = generateParams(objectData);
    return await baseApi.authRequest(`${SEARCH_RESERVE}?${queryParams}`, {
      method: "GET"
    });
  },
};
