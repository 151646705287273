import { Title } from 'components/misc/Text';
import styled, { css } from 'styled-components';
import { Spin } from 'antd';
import Flex from 'components/misc/Flex';

export default function ({ title, children, loading, footer }) {
  return (
    <Styled hasFooter={!!footer}>
      {title && (
        <Title padding="1rem 0" textAlign="center" fontSize={16}>
          {title}
        </Title>
      )}
      <Content>
        {loading && (
          <Flex justifyContent="center" margin={10}>
            <Spin />
          </Flex>
        )}

        {children}
      </Content>
      {footer && (
        <Footer>
          <MainLayoutContainer>{footer}</MainLayoutContainer>
        </Footer>
      )}
    </Styled>
  );
}
const Styled = styled.div`
  margin: auto;
  width: 100%;
  padding: 0 15px 20px;
  ${(p) =>
    p.hasFooter &&
    css`
      padding-bottom: 80px;
    `}
`;
const Content = styled.div``;
const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 20px 25px;
  background: #f6f9ff;
`;
const MainLayoutContainer = styled.div`
  max-width: 345px;
  margin: auto;
`;
