export const actionTypes = {
  GET_PROVINCE: 'GET_PROVINCE',
  GET_CUSTOMER_TYPE: 'GET_CUSTOMER_TYPE',
  GET_ITEM_UNIT: 'GET_ITEM_UNIT',
  GET_ITEM_TYPE: 'GET_ITEM_TYPE',
  FETCH_MASTER_DATA_SUCCESS: 'FETCH_MASTER_DATA_SUCCESS'
};

export const fetchProvince = (payload) => ({
  type: actionTypes.GET_PROVINCE,
  payload
});

export const fetchCustomerType = (payload) => ({
  type: actionTypes.GET_CUSTOMER_TYPE,
  payload
});

export const fetchItemType = (payload) => ({
  type: actionTypes.GET_ITEM_TYPE,
  payload
});

export const fetchItemUnit = (payload) => ({
  type: actionTypes.GET_ITEM_UNIT,
  payload
});

export const fetchedMasterDataSuccess = () => ({
  type: actionTypes.FETCH_MASTER_DATA_SUCCESS
});