import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Spin } from "antd";
import BlueDot from "assets/svg/blue-location.svg";
import GreenDot from "assets/svg/green-location.svg";
import RedDot from "assets/svg/red-location.svg";
import { WATER_ITEM_TYPE } from "utils/constant";

const containerStyle = {
  margin: "0 auto",
  width: "100%",
  height: "367px"
};

const options = {
  mapTypeControl: false,
  fullscreenControl: false,
  styles: [{
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      { visibility: "off" }
    ]
  }],
  minZoom: 1,
  maxZoom: 15
};

const UserMap = (props) => {
  const { markers, setItemId } = props;
  const [key, setKey] = useState(null);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    language: "ja",
    region: "JP"
  });

  const handleOnLoad = (map) => {
    let check_duplicate = [];
    let bounds = new window.google.maps.LatLngBounds();

    markers.forEach(element => {
      let lat = Number(element.latitude);
      let lng = Number(element.longitude);
      let str_position = `${lat}, ${lng}`;
      while (check_duplicate.includes((str_position))) {
        let rand = Number((Math.random() * (0.000005 - 0.000002) + 0.000002).toFixed(8));
        if (Math.round(Math.random())) {
          lat += Math.random() < 0.5 ? -rand : rand;
        } else {
          lng += Math.random() < 0.5 ? -rand : rand;
        }
        str_position = `${lat}, ${lng}`;
      }
      check_duplicate.push(str_position);

      let image_url = GreenDot;
      if (element.item_name === WATER_ITEM_TYPE) {
        if(element.is_me === 'true'){
          image_url = RedDot;
        } else {
          image_url = BlueDot;
        }
      }

      let marker = new window.google.maps.Marker({
        position: { lat: lat, lng: lng },
        element: element,
        map,
        icon: {
          url: image_url
        }
      });

      let infowindow = new window.google.maps.InfoWindow({
        content: `<div style="display: flex; flex-direction: column; align-items: flex-start;">
          <span style="font-weight: 700; font-size: 16px;">${element.item_name}</span>
          <span style="font-weight: 700;">${element.quantity} ${element.item_unit}</span>
        </div>`,
      });

      marker.addListener("click", () => {
        setItemId(marker.element?.reserve_id);
        infowindow.open({
          anchor: marker,
          map,
          shouldFocus: false,
        });
      });
      bounds.extend(marker.getPosition());
    });

    if (markers.length !== 0) {
      let latLng = new window.google.maps.LatLng(markers[0].latitude, markers[0].longitude);
      map.setCenter(latLng);
      map.fitBounds(bounds);
    }
  };

  const renderMap = () => (
    <GoogleMap
      key={key}
      onLoad={handleOnLoad}
      zoom={12}
      center={{ lat: 35.658581, lng: 139.745438 }}
      mapContainerStyle={containerStyle}
      options={options}
    />
  );

  useEffect(() => {
    setKey(new Date());
  }, [markers]);

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <Spin />;
};

export default React.memo(UserMap);
