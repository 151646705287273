import { useState } from 'react';
import { ButtonVariants } from 'components/misc/Status';
import Button from 'components/Button/index';
import Card from 'components/misc/Card';
import Flex from 'components/misc/Flex';
import { TextWithLabel } from 'components/misc/Text';
import ModalConfirmCancelSingle from './ModalConfirmCancelSingle';
import Box from 'components/misc/Box';
import styled from 'styled-components';
import { formatNumber } from 'utils/constant';

export const REQUEST_STATUS = {
  PENDING: 1,
  APPROVED: 2,
  REJECT: 3
};

export default function ({ refetch, ...item }) {
  const [confirmCancel, setConfirmCancel] = useState(false);

  const changeStatusName = (status) => {
    if(status === "支援要請不可"){
      return (
        <>
          回答あり
          <br/>
          「支援困難または不可能」
        </>
      )
    } else if(status === '支援要請可能'){
      return (
        <>
          回答あり「支援可」
          <br/>
          下記で連絡を取ってください
        </>
      );
    } else if(status === "支援要請中"){
      return '支援要請中(返信待ち)'
    }
  }

  return (
    <Card
      key={item.id}
      actions={[
        {
          title: changeStatusName(item.current_status_name),
          onClick: () => {},
          variant: {
            [REQUEST_STATUS.PENDING]: ButtonVariants.INFO,
            [REQUEST_STATUS.APPROVED]: ButtonVariants.SUCCESS,
            [REQUEST_STATUS.REJECT]: ButtonVariants.DANGER
          }[item.current_status]
        }
      ]}
    >
      {item.current_status === REQUEST_STATUS.APPROVED && <TextWithLabel label="事業者名/自治体名" text={item.company_name} />}
      <TextWithLabel label="備蓄品" text={item.item_name} />
      <TextWithLabel label="数量" text={'' + formatNumber(item.quantity) + ' ' + item.item_unit} />
      <TextWithLabel
        label="備蓄場所"
        text={[
          item.zip_code ? '〒' + item.zip_code + ' ' : false,
          item.province,
          item.city,
          item.street
        ]
          .filter((item) => !!item)
          .join('')}
      />

      {item.person_in_charge && (
        <Box>
          {item.person_in_charge.map((item, idx) => (
            <Box key={idx}>
              <TextWithLabel label={`担当者 ${idx + 1}`} text={item.name} />
              <TextWithLabel label={`メールアドレス ${idx + 1}`} text={item.email} />
              <TextWithLabel
                label={
                  <span>
                    担当者電話番号 {idx + 1}
                    <br />
                    （携帯）
                  </span>
                }
                text={item.mobile_phone_number}
              />
              <TextWithLabel label={`（固定）`} text={item.landline_number} />
            </Box>
          ))}
        </Box>
      )}
      {item.current_status === REQUEST_STATUS.PENDING && (
        <Flex flexDirection={'row'} alignItems="flex-start" mt={16}>
          <div>
            <AcceptButton onClick={() => setConfirmCancel(true)}>キャンセル</AcceptButton>
          </div>
        </Flex>
      )}
      {confirmCancel && (
        <ModalConfirmCancelSingle
          {...item}
          onClose={(shouldRefetch) => {
            if (shouldRefetch) refetch();
            setConfirmCancel(false);
          }}
        />
      )}
    </Card>
  );
}

const AcceptButton = styled(Button)`
  color: #0059ab;
  font-weight: bold;
  padding: 12px 16px;
  height: 50px;
  width: 114px;
`;
