export const actionTypes = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  SAVE_VALID_KEY: 'SAVE_VALID_KEY',
};

export const loginSuccess = (payload) => ({
  type: actionTypes.LOGIN_SUCCESS,
  payload
});

export const saveValidKey = (payload) => ({
  type: actionTypes.SAVE_VALID_KEY,
  payload
});
