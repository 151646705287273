import { combineReducers } from 'redux';
import authenticate from 'state/reducers/authenticate';
import reserveInfo from 'state/reducers/reserveInfo';
import masterData from 'state/reducers/masterData';
import userInfo from 'state/reducers/userInfo';
import { RESET_ALL_AFTER_LOGOUT } from 'utils/constant';

const appReducer = combineReducers({
  authenticate,
  reserveInfo,
  masterData,
  userInfo
});

const rootReducer = (state, action) => {
  if (action?.type === RESET_ALL_AFTER_LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
