export const subscribedEvents = [
  "mousemove",
  "keydown",
  "wheel",
  "mousewheel",
  "mousedown",
  "touchstart",
  "touchmove",
  "MSPointerDown",
  "MSPointerMove",
  "visibilitychange"
];

export const RESET_ALL_AFTER_LOGOUT = "RESET_ALL_AFTER_LOGOUT";
export const ERROR_MESSAGES = {
  EMPTY: "入力必須項目です",
  DATA_INVALID: "データが無効です",
  REQUIRED: "入力必須項目です。",
  MASTER_ITEM_AND_OTHER_ITEM: "選択可能な備蓄品です。",
  PASSWORD_NOT_MATCH: "8文字以上の半角英数記号を入力してください(大文字、小文字、数字、記号の全てを含む)",
  CONFIRM_PASSWORD_NOT_MATCH: "新パスワードと違います。",
  OLD_PASSWORD_INVALID: "旧パスワードに誤りがあります。"
};

export const USER_PATH = {
  STORE_LOCATION: "/user-search-store-location",
  HELP_LOCATION: "/user-send-request"
};
export const LOCATION_STATUS = {
  REQUESTING: 1,
  REQUESTED: 2,
  NOT_SUPPORT: 3
};

export const COLORS = {
  MAIN_BLUE: "#0059AB",
  DARK_GRAY: "#7B7B7B",
  BLACK: "#000",
  TRIPLE_3: "#333",
  WHITE: "#fff",
  GREEN: "#5fd88e",
  RED: "#ff676e"
};

export const EMAIL_REGEX = new RegExp(
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
);
export const NUMBER_REGEX = new RegExp(/^[0-9]*$/);

export const PASSWORD_REGEX = new RegExp(
  /(?=^.{8,}$)(?=.*[0-9])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*/
);

export const formatNumber = (value) => {
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const WATER_ITEM_TYPE = "備蓄水";