import React, { Fragment, useContext, useEffect, useState } from "react";
import { StockpileWrapper } from "./styles";
import { Button, Col, Form, Input, Modal, Row, Typography } from "antd";
import { ERROR_MESSAGES, formatNumber } from "utils/constant";
import { LocationsContext } from "../../contexts/Location";

const { Title } = Typography;
const initialValues = {
  sender_name: "",
  sender_phone: "",
  message_content: ""
};
const StockpileItem = ({ name, address, sendStockpileItem, loading }) => {
  const { locationUserDefaultData } = useContext(LocationsContext);
  const [userDefaultData] = locationUserDefaultData;
  const [modalVisible, setModalVisible] = useState(false);
  const [param, setParam] = useState(false);
  const [fields, setFields] = useState([]);
  const [form] = Form.useForm();
  const confirm = (value) => {
    let paramRequest = { ...value, reserve_ids: address.map(item => item.reserve_id) };
    setParam(paramRequest);
    setModalVisible(true);
  };

  const getPhoneNumber = data => {
    if(data?.mobile_phone_number) {
      return data.mobile_phone_number;
    }
    if(data?.landline_number) {
      return data.landline_number;
    }
    return "";
  };

  const setFieldsData = (data) => {
    const defaultUser = [
      {
        name: ["sender_name"],
        value: data?.name || ""
      },
      {
        name: ["sender_phone"],
        value: getPhoneNumber(data)
      }
    ];
    setFields(defaultUser);
  };

  const okConfirm = () => {
    sendStockpileItem(name, param);
  };

  useEffect(() => {
    if (userDefaultData.length > 0) {
      setFieldsData(userDefaultData[0]);
    }
  }, []);

  useEffect(() => {
    if (loading === false) {
      setModalVisible(false);
    }
  }, [loading]);

  return (
    <Fragment>
      <StockpileWrapper>
        <Title className="stockpile-title" level={3}>備蓄品：{name}</Title>
        <div className="stockpile-list">
          <div className="stockpile-list-head">
            <Row gutter={[0, 0]}>
              <Col xs={7}>
                <span className="text-center">数量</span>
              </Col>
              <Col xs={17}>
                <span className="text-center">備蓄場所</span>
              </Col>
            </Row>
          </div>
          <div className="stockpile-list-body">
            {address.length > 0 && address.map((item, index) => (
              <div key={`stockpile-list-body-item-${index}`} className="stockpile-list-body-item">
                <Row gutter={[0, 0]}>
                  <Col xs={7}>
                    <span className="text-left">{formatNumber(item.quantity)}{item.item_unit}</span>
                  </Col>
                  <Col xs={17}>
                    <span className="text-left">{item.address}</span>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        </div>
        <Form
          className="form-wrapper"
          form={form}
          fields={fields}
          layout="vertical"
          initialValues={initialValues}
          onFinish={confirm}
        >
          <Row gutter={[16, 16]} align="top">
            <Col xs={12}>
              <Form.Item
                name="sender_name"
                label={"支援要請者"}
                rules={[
                  {
                    required: true,
                    message: ERROR_MESSAGES.EMPTY
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="sender_phone"
                label={"支援要請者の連絡先"}
                rules={[
                  {
                    required: true,
                    message: ERROR_MESSAGES.EMPTY
                  },
                  {
                    pattern: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                    message: ERROR_MESSAGES.DATA_INVALID
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="message_content"
                label={"メッセージ"}
                rules={[
                  {
                    required: true,
                    message: ERROR_MESSAGES.EMPTY
                  }
                ]}
              >
                <Input.TextArea rows={5} showCount maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button className="btn-submit" type="primary" htmlType="submit">この内容で支援要請する</Button>
          </Form.Item>
        </Form>
        <Modal
          centered
          className="stockpile-modal"
          visible={modalVisible}
          cancelText={"キャンセル"}
          okText={"要請する"}
          closable={false}
          width={325}
          confirmLoading={loading}
          onOk={() => okConfirm()}
          onCancel={() => setModalVisible(false)}
        >
          <p>この内容で支援要請します。</p>
          <p>よろしいですか？</p>
        </Modal>
      </StockpileWrapper>
    </Fragment>
  );
};

export default StockpileItem;
