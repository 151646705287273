import { Input, Button } from 'components';
import { Form } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { number } from '../../../node_modules/bfj/src/events';
import { useEffect } from 'react';
import PublicLayout from '../../components/Layout/PublicLayout';
import { ERROR_MESSAGES } from "utils/constant";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 15px;
  padding: 150px 0 0;

  .log-in {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #0059ab;
    text-align: center;
  }

  .description {
    font-weight: 400;
    color: #333;
    font-size: 12px;
    line-height: 150%;
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px 16px;
    background-color: white;
    border-radius: 8px;

    .ant-form-item {
      margin-bottom: 16px;
    }
  }
`;

const StyledForgotPassword = styled.div`
  font-weight: 700;
  width: 100%;
  text-align: center;
  font-size: 16px;
  margin-bottom: 16px;
  line-height: 24px;

  a {
    color: #003974;
  }
`;

const ForgotPasswordForm = ({ onSubmit, loading, setPhoneNumber, phoneNumber }) => {
  const onFinish = (values) => {
    onSubmit && onSubmit(values);
  };

  return (
    <PublicLayout isLogoBlue>
      <StyledDiv>
        <Form onFinish={onFinish} autoComplete="off">
          <div className="log-in">パスワード忘れ</div>
          <div className="description">
            ご登録のメールアドレス宛に <br />
            「パスワード再設定のご案内」メールを送信します。
            <br />
            アカウントIDおよびご登録の電話番号を入力し <br />
            「送信」ボタンを押下してください。
          </div>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: ERROR_MESSAGES.REQUIRED },
              { type: 'email', message: '入力されたメールアドレスは有効ではありません。' }
            ]}
          >
            <Input name="email" type="text" placeholder="アカウントID" />
          </Form.Item>
          <Form.Item
            name="phone_number"
            rules={[
              { required: true, message: ERROR_MESSAGES.REQUIRED },
              {
                pattern: /^[0-9]{10,11}$/,
                message: '半角数字、ハイフン(-)なしで入力してください。'
              }
            ]}
          >
            <Input name="phone_number" type="text" placeholder="代表者の電話番号" />
          </Form.Item>
          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              送信
            </Button>
          </Form.Item>
          <StyledForgotPassword>
            <Link to="/login">戻る</Link>
          </StyledForgotPassword>
        </Form>
      </StyledDiv>
    </PublicLayout>
  );
};

export default ForgotPasswordForm;
