import { Input, Button, Modal } from 'components';
import { Form, message, Typography } from 'antd';
import styled from 'styled-components';
import { ReactComponent as EyeVisible } from 'assets/svg/eye-visible.svg';
import { ReactComponent as EyeInVisible } from 'assets/svg/eye-invisible.svg';
import PublicLayout from '../Layout/PublicLayout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authenticate } from 'api';
import EmailSuccess from '../../assets/svg/EmailSuccess';
import React, { useState, useEffect } from 'react';
import { ReactComponent as EmailIcon } from 'assets/svg/file-cloud.svg';
import { ERROR_MESSAGES, PASSWORD_REGEX } from "utils/constant";
import jwt_decode from 'jwt-decode';
import localStorage from "utils/localStorage";

const StyledDiv = styled.div`
  margin : 0 15px;
  padding: 174px 0 0;
  width: 343px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  @media (max-width: 375px) {
    width: 100%;
    min-width: 350px;
    margin: unset !important;
  }

  .form-style {
    padding: 0 40px;
  }
  .change-password {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    color: #0059ab;
    text-align: center;
  }

  .description {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 12px;
    margin-bottom: 24px;
    text-align: center;
  }

  form {
    width: 100%;
    
    .ant-form-item {
      margin-bottom: 16px;
    }
  }
  .ant-input {
    font-size: 16px !important;
  }
  input {
    font-size: 16px !important;
    color: #7b7b7b !important;
    &::placeholder {
      color: #7b7b7b;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .btn-submit-password {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    height: 50px;
  }
  .btn-cancel {
    margin-bottom:4px;
    color: #003974;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }
  .btn-cancel-password {
    width: 100%;
    padding-top: 16px;
    border: none;
    background-color: white;
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

const ConfirmEmailModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;

    button {
      top: 24px;
      right: 24px;
      margin: unset;
      width: unset;
      height: unset;

      .ant-modal-close-x {
        width: unset;
        height: unset;
        line-height: unset;
      }
    }

    .ant-modal-body{
      padding: 24px !important;
      text-align: center;

      .email-icon{
        margin: 36px 0px 8px;
      }

      .text-confirm-email{
        color: #333333;
      }
    }
  }
`;

const SuccessModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;

    button {
      top: 24px;
      right: 24px;
      margin: unset;
      width: unset;
      height: unset;

      .ant-modal-close-x {
        width: unset;
        height: unset;
        line-height: unset;
      }
    }

    .ant-modal-body{
      padding: 24px;

      .email-icon{
        display: flex;
        justify-content: center;
        margin: 36px 0px 8px;
      }

      h5{
        padding-bottom: unset;
        margin-bottom: unset;
      }
    }
  }
  .ant-modal-footer {
    .ant-btn {
      height: 50px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      width: 100%;
    }
  }
  .ant-modal-body {
    border-radius: 8px;
  }
  .ant-modal-content p {
    padding: 0px;
  }
`;

const ChangePasswordUserForm = ({
  loading,
  handleSubmit,
  setLoading,
  onSubmit,
  isVisible,
  setIsVisible
}) => {
  const [form] = Form.useForm(); //controlled form != uncontrolled form (native html form behavior) useRef
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const { Title } = Typography;
  const [searchParams] = useSearchParams();
  var idToken = localStorage.load("idToken");
  var decoded = jwt_decode(idToken);
  const sendPassword = async (param) => {
    try {
      const response = await authenticate.userChangedPassword(param);
      setVisible(true);
    } catch (error) {
      message?.error(error.message);
      setLoading(false);
    }

  };

  const email = decoded.email;
  const code = searchParams.get('code');
  const access_token = searchParams.get('authorization');
  const verifyEmail = async ({ newEmail }) => {
    try {
      const verify_mail = await authenticate.confirm_verify_change_password({
        email: newEmail,
        email_code: code,
        access_token
      });
      setIsVisible(true);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    const newEmail = searchParams.get('email');

    if (code && access_token) {
      verifyEmail({ newEmail });
    }
  }, [code, access_token]);
  const handleOnSubmit = () => {
    if (email !== undefined && email !== '') {
      sendPassword({ email, ...form.getFieldsValue() });
    } else {
      return;
    }
  };
  const onForceLogout = async () => {
    try {
      await authenticate.logout();
      navigate('/login');
    } catch (error) {
      //
    }
  };
  const onVerifyRetry = () => {
    setIsVisible(false);
    onForceLogout();
  };

  const onRetry = () => {
    setVisible(false);
    navigate('/user-information');
  };
  const handleCancelChangePassword = () => {
    navigate('/user-information');
  }

  return (
    <PublicLayout isLogoBlue>
      <StyledDiv>
        <div className="change-password">パスワード変更</div>
        <div className="description">
          現在のパスワードと新しいパスワードを入力してください。
        </div>
        <Form form={form} onFinish={handleOnSubmit} autoComplete="off" className="form-style">
          <Form.Item
            name="old_password"
            rules={
              [
                { required: true, message: ERROR_MESSAGES.REQUIRED },
              ]
            }
          >
            <Input
              id="old_password"
              name="old_password"
              type="password"
              placeholder="旧パスワード"
              iconRender={(visible) =>
                visible ? (
                  <EyeVisible width={16} height={16} />
                ) : (
                  <EyeInVisible width={16} height={16} />
                )
              }
            />
          </Form.Item>

          <Form.Item
            name="new_password"
            rules={
              [
                { required: true, message: ERROR_MESSAGES.REQUIRED },
                {
                  pattern: PASSWORD_REGEX,
                  message: ERROR_MESSAGES.PASSWORD_NOT_MATCH
                }
              ]
            }
          >
            <Input
              id="new_password"
              name="new_password"
              type="password"
              placeholder="新パスワード"
              iconRender={(visible) =>
                visible ? (
                  <EyeVisible width={16} height={16} />
                ) : (
                  <EyeInVisible width={16} height={16} />
                )
              }
            />
          </Form.Item>
          <Form.Item
            dependencies={['password']}
            name="retypePassword"
            rules={
              [
                { required: true, message: ERROR_MESSAGES.REQUIRED },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('new_password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(ERROR_MESSAGES.CONFIRM_PASSWORD_NOT_MATCH));
                  },
                }),
              ]
            }
          >
            <Input
              id="confirm"
              name="confirm"
              type="password"
              placeholder="新パスワード（確認用)"
              iconRender={(visible) =>
                visible ? (
                  <EyeVisible width={16} height={16} />
                ) : (
                  <EyeInVisible width={16} height={16} />
                )
              }
            />
          </Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            className="btn-submit-password"
          >
            変更
          </Button>
          <Form.Item>
            <button className="btn-cancel btn-cancel-password"
              onClick={handleCancelChangePassword}>
              戻る
            </button>
          </Form.Item>
        </Form>
        <ConfirmEmailModal
          visible={visible}
          onOk={onRetry}
          onCancel={onRetry}
          footer={false}
        >
          <div className="email-icon">
            <EmailIcon width={111} height={93} stretch="medium" />
          </div>
          <p className="text-confirm-email">
            パスワード変更手続き認証のURLが記載されたメールを送信しました。
            <br />
            お送りしたメールよりお手続きください。
          </p>
        </ConfirmEmailModal>
        <SuccessModal
          width={333}
          height={204}
          visible={isVisible}
          onOk={onVerifyRetry}
          onCancel={onVerifyRetry}
          footer={false}
        >
          <div className="email-icon">
            <EmailSuccess width={66.67} height={66.67} />
          </div>
          <Title level={5} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '24px' }}>アカウントの登録が完了しました。</Title>
        </SuccessModal>
      </StyledDiv>
    </PublicLayout>
  );
};

export default ChangePasswordUserForm;
