import { useState } from 'react';
import Button from 'components/Button/index';
import Box from 'components/misc/Box';
import Flex from 'components/misc/Flex';
import Modal from 'components/misc/Modal';
import SuccessInfo from 'components/misc/SuccessInfo';
import { Title } from 'components/misc/Text';
import { COLORS } from 'utils/constant';
import useFetch from 'hooks/useFetch';
import user from 'api/user';
import CustomButton from 'components/misc/CustomButton';
import { useNavigate } from "react-router-dom";

const fakeApi = () => {
  return new Promise((res) => {
    setTimeout(() => {
      res(true);
    }, 1000);
  });
};

export const MODAL_HELP_TYPE = {
  CAN_HELP: 'CAN_HELP',
  CAN_NOT_HELP: 'CAN_NOT_HELP'
};

export default function ModalConfirm({ onClose, type, id }) {
  const navigate = useNavigate();
  const [isSuccess, setIsSucecss] = useState(false);

  const { loading, doFetch: onOk } = useFetch({
    prefetched: false,
    fetcher: () => type === MODAL_HELP_TYPE.CAN_HELP ? user.acceptUserRequested(id) : user.rejectUserRequested(id),
    onSuccess: () => {
      setIsSucecss(true);
    }
  });

  const backToTOP = () => {
    navigate("/top-user");
  }

  if (isSuccess)
    return (
      <Modal 
        onClose={() => onClose(true)}
        footer={
          <Button onClick={backToTOP} type="primary">
            TOP画面に戻る
          </Button>
        }
      >
        <SuccessInfo title="送信完了しました。" onSuccess={() => onClose(true)}/>
      </Modal>
    );
  return (
    <Modal backdropClosed={false}>
      <Box mb={20}>
        {type === MODAL_HELP_TYPE.CAN_HELP ? (
          <Title color={COLORS.TRIPLE_3} textAlign="center">
            「支援可能」で回答します。
            <br /> よろしいですか？
          </Title>
        ) : (
          <Title color={COLORS.TRIPLE_3} textAlign="center">
            「支援不可」で回答します。
            <br /> よろしいですか？
          </Title>
        )}
      </Box>
      <Flex>
        <CustomButton onClick={() => onClose(false)}>キャンセル</CustomButton>
        <Flex margin={10} />
        <Button type="primary" loading={loading} onClick={onOk}>
          OK
        </Button>
      </Flex>
    </Modal>
  );
}
