import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import Button from 'components/Button/index';
import Box from 'components/misc/Box';
import Flex from 'components/misc/Flex';
import Modal from 'components/misc/Modal';
import { Label, Title } from 'components/misc/Text';
import { COLORS } from 'utils/constant';
import SuccessInfo from 'components/misc/SuccessInfo';
import useFetch from 'hooks/useFetch';
import user from 'api/user';
import CustomButton from 'components/misc/CustomButton';

const { TextArea } = Input;

export default function ModalConfirmCancelSingle({
  customer_support_id,
  onClose,
  ...rest
}) {
  const [text, setText] = useState('');
  const [isSuccess, setIsSucecss] = useState(false);
  const { loading, doFetch: cancelRequest } = useFetch({
    prefetched: false,
    fetcher: () =>
      user.cancelRequest({ customer_support_id, ...rest, cancel_message: text }, true),
    onSuccess: () => {
      setIsSucecss(true);
    }
  });
  if (isSuccess)
    return (
      <Modal onClose={() => onClose(true)}>
        <SuccessInfo title="送信完了しました。" onSuccess={() => onClose(true)}/>
      </Modal>
    );
  return (
    <Modal>
      <Box mb={15}>
        <Title color={COLORS.TRIPLE_3} textAlign="center">
          支援先にキャンセルを通知します。
          <br />
          よろしいですか？
        </Title>
      </Box>
      <Label required>メッセージ</Label>
      <TextAreaStyled>
        <TextArea
          onChange={(e) => setText(e.target.value)}
          value={text}
          showCount
          maxLength={100}
          placeholder="支援者へメッセージ等あればご記入ください。"
          resize={false}
        />
      </TextAreaStyled>
      <Flex mt={20}>
        <CustomButton type="secondary" onClick={() => onClose(false)}>
          キャンセル
        </CustomButton>
        <Flex margin={10} />
        <Button type="primary" disabled={!text.trim()} loading={loading} onClick={cancelRequest}>
          送信
        </Button>
      </Flex>
    </Modal>
  );
}

export const TextAreaStyled = styled.div`
  position: relative;
  .ant-input {
    background-color: #fafcfe;
    color: ${COLORS.TRIPLE_3};
    font-size: 16px;
  }
  .ant-input-textarea-show-count::after {
    color: ${COLORS.DARK_GRAY};
    font-size: 12px;
    position: absolute;
    top: -20px;
    right: 0;
  }
`;
