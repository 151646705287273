import styled from 'styled-components';
import { alignItems, flexDirection, height, justifyContent, margin, width } from './styledUtils';

export default styled.div(
  {
    display: 'flex'
  },
  alignItems,
  justifyContent,
  flexDirection,
  margin,
  height,
  width,
  (p) => p.flex && { flex: p.flex }
);
