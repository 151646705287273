import { useState, useEffect } from 'react';
import { LoginForm, Modal, Button } from 'components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authenticate } from 'api';
import localStorage, { ID_TOKEN, ACCESS_TOKEN, SESSION, REFRESH_TOKEN } from 'utils/localStorage';
import { message } from 'antd';
import styled from 'styled-components';
import { loginSuccess } from 'state/actions/authenticate';
import { ReactComponent as WarningIcon } from 'assets/svg/warning-icon.svg';
import master from 'api/master/index';
import {
  fetchCustomerType,
  fetchedMasterDataSuccess,
  fetchItemType,
  fetchItemUnit,
  fetchProvince
} from 'state/actions/masterData';

const CHALLENGE_NAME = 'NEW_PASSWORD_REQUIRED';

const LoginModal = styled(Modal)`
  .ant-modal-body {
    padding: unset;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .ant-modal-content {
    border-radius: 8px;
    padding: 24px;
    .ant-modal-close {
      top: 24px;
      right: 24px;
      margin: unset;
      width: unset;
      height: unset;

      .ant-modal-close-x {
        width: unset;
        height: unset;
        line-height: unset;
      }
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 23px;
      text-align: center;
    }
    h1 {
      text-align: center;
      color: #333;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 16px;
    }
    h3 {
      text-align: center;
      color: #333;
      font-size: 14px;
      margin: 0;
    }
  }
  .ant-modal-footer {
    border-top: none;
    padding: unset;
    margin-top: 12px;
    button{
      height: 42px !important;
    }
  }
  .warning-icon {
    text-align: center;
  }
`;

const LoginPage = () => {
  const error5Times =
    '5回失敗したため、アカウントがロックされました。5分後にロックが解除されます。';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const fetchedMasterData = useSelector((state) => {
    return state?.masterData.fetched;
  });
  const onLogin = async (formData) => {
    try {
      setLoading(true);
      const response = await authenticate.login({
        email: formData.email,
        password: formData.password
      });

      dispatch(
        loginSuccess({ ...response, email: formData.email, password: formData.password })
      );
      setLoading(false);

      if (response?.challengeName && response.challengeName === CHALLENGE_NAME) {
        localStorage.save(SESSION, response.session);
        navigate('/change-password');
      } else {
        if (response.isUser) {
          localStorage.save(ID_TOKEN, response.idToken);
          localStorage.save(REFRESH_TOKEN, response.refreshToken);
          localStorage.save(ACCESS_TOKEN, response.accessToken);
          if(response.num_reserve > 0){
            navigate('/top-user');
          } else {
            navigate('/list-information');
          }
        } else {
          message?.error('Only user role can access this site!');
        }
      }
    } catch (error) {
      if (error.message === error5Times) {
        setVisible(true);
        setLoading(false);
        message;
      } else {
        message?.error(error.message);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (fetchedMasterData === false) {
      const fetchData = async () => {
        try {
          const province = master.getProvince();
          const customerType = master.getCustomerType();
          const itemType = master.getItemType();
          const itemUnit = master.getItemUnit();
          const provinceResponse = await province;
          const customerTypeResponse = await customerType;
          const itemTypeResponse = await itemType;
          const itemUnitResponse = await itemUnit;
          dispatch(fetchProvince(provinceResponse));
          dispatch(fetchCustomerType(customerTypeResponse));
          dispatch(fetchItemType(itemTypeResponse));
          dispatch(fetchItemUnit(itemUnitResponse));
          localStorage.save('ITEM_TYPE', JSON.stringify(itemTypeResponse));
          localStorage.save('ITEM_UNIT', JSON.stringify(itemUnitResponse));
          localStorage.save('PROVINCE', JSON.stringify(provinceResponse));
          localStorage.save('CUSTOMER_TYPE', JSON.stringify(customerTypeResponse));
          dispatch(fetchedMasterDataSuccess());
        } catch (error) {
          message?.error(error.message);
        }
      };
      fetchData();
    }
  }, []);
  const onRetry = () => {
    setVisible(false);
    setLoading(false);
  };
  return (
    <>
      <LoginForm onLogin={onLogin} loading={loading} />
      <LoginModal
        visible={visible}
        onOk={onRetry}
        onCancel={onRetry}
        footer={
          <Button onClick={onRetry} type="primary">
            リトライ
          </Button>
        }
      >
        <div className="warning-icon">
          <WarningIcon width={80} height={80} />
        </div>
        <h1>警告</h1>
        <p>5回失敗したため、アカウントがロックされました。 5分後にロックが解除されます</p>
      </LoginModal>
    </>
  );
};

export default LoginPage;
