import filePDF2 from "assets/pdf/file2.pdf";
import { Button, Modal } from "components";
import styled from "styled-components";
import axios from "../../../../node_modules/axios/index";

const TermAggrement3 = ({ check_3, setCheck3, setChecked3 }) => {
  const StyleTermAggreement3 = styled(Modal)`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    .ant-modal-body {
      padding: 15px;
      background-color: #f2f5fe;
    }
    .ant-modal-footer {
      background-color: #f2f5fe;
    }
  `;
  const StyleTermAggreement = styled.div`
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    .term-aggrement-header {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #0059ab;
    }
    .term-aggrement-content {
      height: 550px;
      overflow-y: scroll;
      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      font-size: 13px;
      line-height: 23px;
      text-align: left;
      color: #333333;
      ol.ol-parent {
        margin-bottom: unset;
        padding-left: 3px;
        margin-left: 0px;
        counter-reset: item;
        li {
          display: block;
          &::before {
            content: "(" counters(item, ".") ") ";
            counter-increment: item;
            font-weight: bold;
          }
        }
        ol {
          counter-reset: item;
          padding-left: 15px;
          & > li {
            display: block;
            &::before {
              content: "(" counters(item, ".") ") ";
              counter-increment: item;
            }
          }
        }
      }
      ul {
        padding: 5px;
        margin-left: 10px;
      }
      p {
        text-align: left;
        margin-bottom: unset;
        margin-bottom: unset;
      }
    }
    .term-aggrement-button {
      display: flex;
      margin-top: 10px;
      .button-copy {
        color: #0059ab;
        font-weight: 700;
      }
      .button-agree {
        margin-left: 10px;
        font-weight: 700;
      }
    }
    .button-cancel {
      color: #0059ab;
      font-weight: 700;
    }
  `;

  const handleDowloadAggrement = () => {
    axios({
      url: filePDF2,
      method: "GET",
      responseType: "blob"
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "UACJ水の架け橋ネットワーク_プライバシーポリシー.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  return (
    <StyleTermAggreement3
      visible={check_3}
      onOk={() => {
        setCheck3(false);
        setChecked3(true);
      }}
      onCancel={() => {
        setCheck3(false);
        setChecked3(false);
      }}
      closable={false}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
    >
      <StyleTermAggreement>
        <div className="term-aggrement-header">
          水の架け橋ネットワーク プライバシーポリシー
        </div>
        <br />
        <div className="term-aggrement-content">
          <p>
            株式会社
            UACJ（以下、「当社」といいます。）は、ユーザーに提供する水の架け橋ネットワーク
            （以下、「本サービス」といいます。）における、ユーザーの個人情報の取扱いについて、以下の
            とおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
          </p>
          <ul>
            <li>
              <b>第１条（適用）</b>
              <p>
                「個人情報」とは、個人情報の保護に関する法律（平成 15 年法律第 57
                号。以下「個人情 報保護法」といいます。）第 2 条第 1
                項により定義される「個人情報」を指すものとし、生存
                する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連
                絡先その他の記述等により特定の個人を識別できる情報及び容貌、指紋、声紋にかかるデー
                タ、及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個
                人識別情報）を指します。
              </p>
            </li>
            <li>
              <b>第 2 条（個人情報の収集方法）</b>
              <p>
                当社は、ユーザーが利用登録、及び利用する際に下記の情報を取得することがあります。
              </p>
              <ol className="ol-parent">
                <li>個人情報：氏名、所属組織名、住所、電話番号、メールアドレス</li>
                <li>
                  その他情報：災害用備蓄水「水の架け橋」の備蓄情報（備蓄場所の位置情報、備蓄量
                  など）
                </li>
              </ol>
            </li>
            <li>
              <b>第 3 条（個人情報を収集・利用する目的）</b>
              <p>当社が個人情報を収集・利用する目的は、以下のとおりです。</p>
              <ol className="ol-parent">
                <li>本サービスの提供、運営、管理のため</li>
                <li>
                  ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）
                </li>
                <li>
                  メンテナンス、重要なお知らせなど必要に応じユーザーにご連絡するため
                </li>
                <li>
                  利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユ
                  ーザーの特定をし、ご利用をお断りするため
                </li>
                <li>
                  ユーザーにご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていただ
                  くため
                </li>
                <li>上記の利用目的に付随する目的</li>
              </ol>
            </li>
            <li>
              <b>第 4 条（利用目的の変更）</b>
              <ol className="ol-parent">
                <li>
                  当社は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報
                  の利用目的を変更するものとします。
                </li>
                <li>
                  利用目的の変更を行った場合には、変更後の目的について、当社所定の方法により、ユー
                  ザーに通知し、またはウェブサイトまたは本サービス内に公表するものとします。
                </li>
              </ol>
            </li>
            <li>
              <b>第 5 条（個人情報の第三者提供）</b>
              <ol className="ol-parent">
                <li>
                  当社は、次に掲げる場合または第 3
                  項に規定する場合を除いて、あらかじめユーザーの同
                  意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護
                  法その他の法令で認められる場合を除きます。
                  <ol>
                    <li>法令に基づく場合</li>
                    <li>
                      人の生命、身体または財産の保護のために必要がある場合であって、ご本人様の同意
                      を得ることが困難である場合
                    </li>
                    <li>
                      公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であっ
                      て、ご本人様の同意を得ることが困難である場合
                    </li>
                    <li>
                      国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行
                      することに対して協力する必要がある場合であって、ご本人様の同意を得ることにより当該
                      事務の遂行に支障を及ぼすおそれがある場合
                    </li>
                  </ol>
                </li>
                <li>
                  前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しない
                  ものとします。
                  <ol>
                    <li>
                      当社が第 3
                      条に定める利用目的の達成に必要な範囲内において個人情報の取扱いの全
                      部または一部を委託する場合
                    </li>
                    <li>
                      合併その他の事由による事業の承継に伴って個人情報が提供される場合
                    </li>
                    <li>
                      個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して
                      利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該
                      個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知
                      し、または本人が容易に知り得る状態に置いた場合
                    </li>
                  </ol>
                </li>
                <li>
                  第 1 項の規定にかかわらず、ユーザーは第 3
                  条に定める利用目的の達成に必要な範囲内
                  において、当社が取得した個人情報が第三者に提供されることがあることに同意します。
                </li>
              </ol>
            </li>

            <li>
              <b>第 6 条（共同利用）</b>
              <p>
                当社および当社国内子会社各社は、保有する個人データを次のとおり共同利用いたします。
                なお、これとは別に、予めご本人様に個別に通知した場合は、当該通知の内容に従い、当該
                個人データを共同利用することがあります。
                <ol className="ol-parent">
                  <li>
                    共同して利用される個人データの項目
                    氏名、住所、電話番号、メールアドレス、および災害用備蓄水「水の架け橋」の備蓄情報（備
                    蓄場所の位置情報、備蓄量など）
                  </li>
                  <li>共同して利用する者の範囲 当社および当社国内子会社各社</li>
                  <li>共同して利用する者の利用目的 第 3 条に規定する利用目的</li>
                  <li>
                    共同して利用する個人情報の管理について責任を有する者 株式会社 UACJ
                  </li>
                </ol>
              </p>
            </li>
            <li>
              <b>第 7 条（個人情報の開示）</b>
              <ol className="ol-parent">
                当社は、ユーザーから個人情報の開示を求められたときは、ユーザーご本人からの請求であ
                ることを確認した上で、ユーザーに対し、遅滞なくこれを開示します。ただし、開示するこ
                とにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開
                示しない決定をした場合には、その旨を遅滞なく通知します。
                <li> ユーザーまたは第三者の権利利益を害するおそれがある場合 </li>
                <li>ユーザーまたは第三者の権利利益を害するおそれがある場合</li>
                <li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
                <li>その他法令に違反することとなる場合</li>
              </ol>
            </li>
            <li>
              <b>第８条（個人情報の訂正および削除）</b>
              <ol className="ol-parent">
                <li>
                  ユーザーは、当社の保有する自己の個人情報が誤った情報である場合には、当社が定める
                  手続きにより、当社に対して個人情報の訂正、追加または削除（以下、「訂正等」といいます。）
                  を請求することができます。
                </li>
                <li>
                  .当社は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合に
                  は、遅滞なく、当該個人情報の訂正等を行うものとします。
                </li>
                <li>
                  当社は、前項の規定に基づき訂正等を行った場合、または訂正等を行わない旨の決定をし
                  たときは遅滞なく、これをユーザーに通知します。
                </li>
              </ol>
            </li>
            <li>
              <b>第９条（個人情報の利用停止等）</b>
              <ol className="ol-parent">
                <li>
                  当社は、ユーザーから、個人情報が利用目的の範囲を超えて取り扱われているという理由、
                  または不正の手段により取得されたものであるという理由により、その利用の停止または消
                  去（以下、「利用停止等」といいます。）を求められた場合には、ユーザーご本人からの請求
                  であることを確認した上で、遅滞なく必要な調査を行います。
                </li>
                <li>
                  前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく、
                  当該個人情報の利用停止等を行います。
                </li>
                <li>
                  当社は、前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の
                  決定をしたときは、遅滞なく、これをユーザーに通知します。
                </li>
                <li>
                  前二項にかかわらず、利用停止等に多額の費用を有する場合その他利用停止等を行うこと
                  が困難な場合であって、ユーザーの権利利益を保護するために必要なこれに代わるべき措置
                  をとれる場合は、この代替策を講じるものとします。
                </li>
              </ol>
            </li>
            <li>
              <b>第 10 条（プライバシーポリシーの変更）</b>
              <ol className="ol-parent">
                <li>
                  本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザー
                  に通知することなく、変更することができるものとします。
                </li>
                <li>
                  当社が別途定める場合を除いて、変更後のプライバシーポリシー、本ウェブサイトまたは
                  本サービス内に掲載したときから効力を生じるものとします。
                </li>
                <li>
                  本 ポ リ シ ー に 定 め の な い 事 項 に つ い て は 、 当 社 の プ ラ
                  イ バ シ ー ポ リ シ ー
                  （https://www.uacj.co.jp/privacy_treatment.htm）が適用されます。
                </li>
              </ol>
            </li>
            <li>
              <b>第 11 条（お問い合わせ窓口）</b>
              <p>本ポリシーに関するお問い合わせは、下記の窓口までお願いいたします。</p>
              <p>住所：東京都千代田区大手町 1-7-2 東京サンケイビル</p>
              <p>社名：株式会社 UACJ</p>
              <p>担当部署：経営戦略本部</p>
              <p>
                E メールアドレス：<a href="">sales_mizuno-kakehashi@ml.uacj.co.jp</a>
              </p>
            </li>
          </ul>
        </div>
        <div className="term-aggrement-button">
          <Button onClick={handleDowloadAggrement} className="button-copy">
            ダウンロード
          </Button>
          <Button
            onClick={() => {
              setCheck3(false);
              setChecked3(true);
            }}
            type="primary"
            className="button-agree"
          >
            同意する
          </Button>
        </div>
        <Button
          style={{ marginTop: "10px" }}
          onClick={() => {
            setCheck3(false);
            setChecked3(false);
          }}
          type="cancel"
          className="button-cancel"
        >
          キャンセル
        </Button>
      </StyleTermAggreement>
    </StyleTermAggreement3>
  );
};

export default TermAggrement3;
