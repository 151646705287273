import styled from "styled-components";

export const LocationItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 215px;
  min-width: 215px;
  max-width: 215px;
  margin: 10px 20px 20px 0;
  padding: 15px 10px;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important;

  .location-block {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &-title {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: #7B7B7B;
      margin-bottom: 5px;
    }

    &-content {
      display: block;
      width: 100%;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #333333;
      margin-bottom: 0;
      white-space: break-spaces;
    }
  }
`;
