import { master } from 'api';
const geocode = async (zipcode) => {
  const response = await master.getAddressFromZipCode(zipcode);
  let province = '';
  let city = '';
  let neighborhood = '';
  if (response.ok) {
    let responseText = await response.text();
    responseText = JSON.parse(responseText);
    let address = responseText.results[0].address_components;
    address.forEach((element) => {
      if (element.types.includes('administrative_area_level_1', 'political')) {
        province = element.long_name;
      }
      if (element.types.includes('sublocality', 'political')) {
        neighborhood = element.long_name;
      }
      if (element.types.includes('locality', 'political')) {
        city = element.long_name;
      }
      if (element.types.includes('country', 'political')) {
        if (element.long_name != '日本') {
          province = '';
          city = '';
        }
      }
    });
    city += neighborhood;
  }
  return { province, city };
};

export default geocode;
