import useLockBodyScroll from 'hooks/useLockBodyScroll';
import styled from 'styled-components';

export default function ({ children, backdropClosed = true, onClose = () => {} }) {
  useLockBodyScroll();
  
  const onBackdropClick = (e) => {
    e.stopPropagation();
    if (backdropClosed) {
      onClose();
    }
  };

  return (
    <ModalRoot>
      <Backdrop onClick={onBackdropClick} />
      <ModalMain>{children}</ModalMain>
    </ModalRoot>
  );
}

const ModalRoot = styled.div`
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  inset: 0;
`;
const ModalMain = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 24px;
  z-index: 1001;
  position: relative;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: calc(100% - 20px);
  margin: auto;
  width: 333px;
`;
const Backdrop = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
`;
