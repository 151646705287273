import { Modal as AModal } from 'antd';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'assets/svg/close-circle.svg';

const SModal = styled(AModal)`
  .ant-modal-footer {
    border: none !important;
    border-top: none;
    padding: 16px 33.5px 24px;
    .ant-btn {
      height: 34px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      width: 100%;
    }
  }

  .ant-modal-close {
    margin: 24px 30px;
    width: 4.72px;
    height: 4.72x;

    .ant-modal-close-x {
      width: 4.72px;
      height: 4.72x;
      line-height: 24px;
    }
  }

  .ant-modal-body {
    padding-bottom: 0;
  }

  .ant-modal-content {
    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 23px;
      text-align: center;
    }
    h1 {
      text-align: center;
      color: #333;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 16px;
    }

    h3 {
      text-align: center;
      color: #333;
      font-size: 14px;
      margin: 0;
    }
  }
`;

const Modal = (props) => {
  return (
    <SModal closeIcon={<CloseIcon />} width={359} centered {...props}>
      {props.children}
    </SModal>
  );
};

export default Modal;
