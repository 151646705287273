import { Input, Button } from "components";
import { Modal as AModal, Form } from "antd";
import styled from "styled-components";
import { ReactComponent as EyeVisible } from "assets/svg/eye-visible.svg";
import { ReactComponent as EyeInVisible } from "assets/svg/eye-invisible.svg";
import PublicLayout from "../../components/Layout/PublicLayout";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Success } from "../../assets/svg/tick-circle.svg";
import { ReactComponent as CloseIcon } from "assets/svg/close-circle.svg";
import { ERROR_MESSAGES, PASSWORD_REGEX } from "utils/constant";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 15px;
  padding: 150px 0 0;

  .change-password {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #0059ab;
    text-align: center;
  }

  .description {
    font-weight: 400;
    color: #333;
    font-size: 12px;
    line-height: 150%;
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px 16px;
    background-color: white;
    border-radius: 8px;

    .ant-form-item {
      margin-bottom: 16px;
    }
  }
`;
const ChangePWModal = styled(AModal)`
  .ant-modal-content {
    border-radius: 8px;
    padding: 24px;

    .ant-modal-close {
      top: 24px;
      right: 24px;
      margin: unset;
      width: unset;
      height: unset;

      .ant-modal-close-x {
        width: unset;
        height: unset;
        line-height: unset;
      }
    }
    h3 {
      text-align: center;
      color: #333;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .ant-modal-body {
    padding: unset;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .ant-modal-footer {
    border-top: none;
    padding: unset;
    margin-top: 12px;
  }
  .email-icon {
    margin-top: 24px;
    text-align: center;
  }
`;
const ChangePasswordForm = ({ onSubmit, loading, isVisible, setIsVisible }) => {
  const navigate = useNavigate();
  const onFinish = (values) => {
    onSubmit(values);
  };
  const onRetry = () => {
    setIsVisible(false);
    navigate("/login");
  };
  return (
    <PublicLayout isLogoBlue>
      <StyledDiv>
        <Form onFinish={onFinish} autoComplete="off">
          <div className="change-password">パスワード設定</div>
          <div className="description">
            新しいパスワードを入力して「変更」ボタンを押下してください
          </div>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: ERROR_MESSAGES.REQUIRED },

              {
                pattern: PASSWORD_REGEX,
                message: ERROR_MESSAGES.PASSWORD_NOT_MATCH
              }
            ]}
          >
            <Input
              id="password"
              name="password"
              type="password"
              placeholder="新パスワード"
              iconRender={(visible) =>
                visible ? (
                  <EyeVisible width={16} height={16} />
                ) : (
                  <EyeInVisible width={16} height={16} />
                )
              }
            />
          </Form.Item>

          <Form.Item
            dependencies={["password"]}
            name="retypePassword"
            rules={[
              { required: true, message: ERROR_MESSAGES.REQUIRED },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(ERROR_MESSAGES.CONFIRM_PASSWORD_NOT_MATCH)
                  );
                }
              })
            ]}
          >
            <Input
              id="confirm"
              name="confirm"
              type="password"
              placeholder="新パスワード（確認用)"
              iconRender={(visible) =>
                visible ? (
                  <EyeVisible width={16} height={16} />
                ) : (
                  <EyeInVisible width={16} height={16} />
                )
              }
            />
          </Form.Item>

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              変更
            </Button>
          </Form.Item>
        </Form>
        <ChangePWModal
          visible={isVisible}
          onOk={onRetry}
          onCancel={onRetry}
          closeIcon={<CloseIcon />}
          width={333}
          height={270}
          footer={
            <Button onClick={onRetry} type="primary">
              ログイン
            </Button>
          }
        >
          <div className="email-icon">
            <Success width={80} height={80} />
          </div>
          <h3>パスワードの変更が完了しました。</h3>
        </ChangePWModal>
      </StyledDiv>
    </PublicLayout>
  );
};

export default ChangePasswordForm;
