import styled from 'styled-components';
import { ReactComponent as NotificationIcon } from 'assets/svg/notification-icon.svg';
import { ReactComponent as AvatarIcon } from 'assets/svg/avatar-icon.svg';
import { ReactComponent as LogoutIcon } from 'assets/svg/logout-icon.svg';
import { Typography } from 'antd';

const StyledHeader = styled.header`
  width: 100%;
  height: 72px;
  background-color: white;
  flex: 0 0 72px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  padding: 16px 28px;

  .user-name {
    font-size: 14px;
  }
`;
  
const Logout = styled.div`
  color: #0059ab;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  button.content {
    cursor: pointer;
    height: fit-content;
    display: flex;
    gap: 6px;
    justify-content: center;
    background: none;
    border: none;
    outline: none;
    margin: 0;
    padding-left: 20px;
  }

  .icon {
    transform: rotate(180deg);
  }
  
  .text {
    font-weight: bold;
    color: #0059ab;
  }
`;
const Header = ({ userName, onRequestLogout  }) => {
  const { Text } = Typography;
  return (
    <StyledHeader>
      <NotificationIcon />
      <AvatarIcon />
      <div className="user-name">{userName}</div>
      <Logout>
        <button className="content" onClick={onRequestLogout}>
          <Text className="text">ログアウト</Text>
          <LogoutIcon className="icon"/>
        </button>
      </Logout>
    </StyledHeader>
  );
};

export default Header;
