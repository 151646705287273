import styled from 'styled-components';
import { COLORS } from 'utils/constant';
import Box from './Box';
import Flex from './Flex';
import { Title } from './Text';
import { ReactComponent as CloseIcon } from 'assets/svg/close-circle.svg';

const SuccessVector = () => (
  <svg width="35" height="24" viewBox="0 0 35 24" fill="none">
    <path
      d="M12.7668 23.9337C12.1001 23.9337 11.4668 23.667 11.0001 23.2003L1.5668 13.767C0.60013 12.8003 0.60013 11.2003 1.5668 10.2337C2.53346 9.26699 4.13346 9.26699 5.10013 10.2337L12.7668 17.9003L29.9001 0.766992C30.8668 -0.199674 32.4668 -0.199674 33.4335 0.766992C34.4001 1.73366 34.4001 3.33366 33.4335 4.30032L14.5335 23.2003C14.0668 23.667 13.4335 23.9337 12.7668 23.9337Z"
      fill="#5FD88E"
    />
  </svg>
);

export default function ({ title, onSuccess }) {
  const handleSuccess = () => {
    onSuccess && onSuccess();
  }
  return (
    <Flex alignItems="center" justifyContent="center" flexDirection="column" padding={40}>
      <div style={{ display: "flex", justifyContent: 'flex-end', width: '100%' }}><CloseIcon onClick={handleSuccess}/></div>
      <Box margin={15}>
        <Circle alignItems="center" justifyContent="center">
          <SuccessVector />
        </Circle>
      </Box>
      <Title color={COLORS.TRIPLE_3}>{title}</Title>
    </Flex>
  );
}

const Circle = styled(Flex)`
  background: #bfefd2;
  
  width: 65px;
  height: 65px;
  border-radius: 100%;
`;
