import { actionTypes } from 'state/actions/reserveInfo';

const initialState = {
  list_reserve_info: [],
  reserve_detail: {},
  new_reserve_info: {},
  edit_reserve_info: {},
};

const reserveInfo = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_RESERVE_INFO:
      return {
        ...state,
        list_reserve_info: payload
      };
    case actionTypes.FETCH_RESERVE_DETAIL:
      return {
        ...state,
        reserve_detail: payload
      };
    case actionTypes.SET_NEW_RESERVE:
      return {
        ...state,
        new_reserve_info: payload
      };
    case actionTypes.SET_EDIT_RESERVE:
      return {
        ...state,
        edit_reserve_info: payload
      };
    default:
      return state;
  }
};

export default reserveInfo;
