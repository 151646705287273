import { Button, Col, Row, Typography } from "antd";
import { LocationItemDetail, PublicLayout } from "components/index";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LocationsContext } from "../../contexts/Location";
import { UserSupportRequestLocationWrapper } from "./styles";

const { Title, Text } = Typography;

const UserSupportRequestLocation = () => {
  const navigate = useNavigate();
  const { locationData } = useContext(LocationsContext);
  const [locationList, setLocationList] = locationData;
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const goBackSearchLocation = useCallback(() => navigate("/user-send-request"), [navigate]);
  const nextToRequestLocation = useCallback(() => navigate("/user-support-request-notification"), [navigate]);

  const changeSelectItem = (id, newStatus) => {
    let locations = locationList;
    locations = locations.map(item => {
      if (item.reserve_id === id) {
        item.isSelected = newStatus;
      }
      return item;
    });
    let currentDataValid = locations.filter(item => item.isSelected === true);
    if (currentDataValid.length === 0) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
    setLocationList(locations);
  };

  const changeSelectAllItem = () => {
    let locations = locationList;
    locations = locations.map(item => {
      item.isSelected = false;
      return item;
    });
    setButtonDisabled(true);
    setLocationList(locations);
  };

  useEffect(() => {
    if (locationList.length === 0) {
      navigate("/user-send-request", { replace: true });
    } else {
      let currentDataValid = locationList.filter(item => item.isSelected === true);
      if (currentDataValid.length === 0) {
        setButtonDisabled(true);
      }
    }
  }, []);

  const changeDeleteAll = () => {
    let locations = locationList;
    locations = locations.map(item => {
      if(item?.current_status === ''){
        item.isSelected = true;
      }
      return item;
    });
    setButtonDisabled(false);
    setLocationList(locations);
  }

  return (
    <UserSupportRequestLocationWrapper>
      <PublicLayout>
        <Title className="page-title" level={2}>支援要請場所選択</Title>
        <Text className="page-title-note">支援要請する備蓄場所を選択して「次へ」を押下してください。</Text>
        <Row gutter={[15, 12]} className="row-button">
          <Col xs={12}>
            <Button className="btn-delete-all" type="primary" onClick={changeDeleteAll}>
              すべて選択
            </Button>
          </Col>
          <Col xs={12}>
            <Button className="btn-select-all" type="primary" onClick={changeSelectAllItem}>
              すべて解除
            </Button>
          </Col>
        </Row>
        {locationList && locationList.map((location, index) => (
          <LocationItemDetail key={`location-item-detail-${index}`}
                              item={location}
                              status={location.current_status}
                              changeSelectItem={changeSelectItem}
                              selectItem={location.isSelected} />
        ))}
        <div className="btn-back-wrapper">
          <Row gutter={[15, 12]}>
            <Col xs={12}>
              <Button className="btn-return" type="primary" onClick={goBackSearchLocation}>
                戻る
              </Button>
            </Col>
            <Col xs={12}>
              <Button className="btn-next" type="primary" onClick={nextToRequestLocation} disabled={buttonDisabled}>
                次へ
              </Button>
            </Col>
          </Row>
        </div>
      </PublicLayout>
    </UserSupportRequestLocationWrapper>
  );
};

export default UserSupportRequestLocation;
