import { authenticate } from 'api';
import { IdleSessionTimeout } from "idle-session-timeout";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SessionTimeout = () => {
  const navigate = useNavigate();

  let session = new IdleSessionTimeout(30 * 60 * 1000);

  session.onTimeOut = async () => {
    await authenticate.logout();
    navigate('/login');
  };

  session.start();
  session.reset();
  session.dispose();

  useEffect(() => {
    session.start();
  }, [])

  return (
    <></>
  );
};

export default SessionTimeout;
