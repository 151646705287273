// flex utilities
export const alignItems = (p) => p.alignItems && { alignItems: p.alignItems };
export const justifyContent = (p) =>
  p.justifyContent && { justifyContent: p.justifyContent };
export const flexDirection = (p) => p.flexDirection && { flexDirection: p.flexDirection };

// text utilities
export const textAlign = (p) => p.textAlign && { textAlign: p.textAlign };

// layout utils
export const margin = (p) => ({
  margin: p.margin,
  marginBottom: p.mb,
  marginTop: p.mt,
  marginLeft: p.ml,
  marginRight: p.mr
});
export const width = p => p.width && { width: p.width };
export const height = p => p.height && { height: p.height };
export const padding = (p) => p.padding && { padding: p.padding };
