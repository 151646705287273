import Button from 'components/Button/index';
import Box from 'components/misc/Box';
import Card from 'components/misc/Card';
import Flex from 'components/misc/Flex';
import { ButtonVariants } from 'components/misc/Status';
import { Label, Text, TextWithLabel, Title } from 'components/misc/Text';
import { REQUEST_STATUS } from 'containers/UserRequesting/RequestItem';
import { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { TAB_STATUS } from './index';
import ModalConfirm, { MODAL_HELP_TYPE } from './ModalConfirm';
import CustomButton from 'components/misc/CustomButton';

const LABEL_WIDTH = 70;

export default function ({
  item_name,
  quantity,
  item_unit,
  street,
  city,
  province,
  zip_code,
  customer_information,
  type,
  current_status,
  customer_support_id,
  refetch,
  created_date
}) {
  const [modalConfirm, setModalConfirm] = useState(0);

  return (
    <Card
      actions={
        type === TAB_STATUS.replied
          ? [
              {
                title: {
                  [REQUEST_STATUS.PENDING]: '選択中',
                  [REQUEST_STATUS.APPROVED]: '回答:支援可能',
                  [REQUEST_STATUS.REJECT]: '回答：支援不可'
                }[current_status],
                onClick: () => {},
                variant: {
                  [REQUEST_STATUS.PENDING]: ButtonVariants.INFO,
                  [REQUEST_STATUS.APPROVED]: ButtonVariants.SUCCESS,
                  [REQUEST_STATUS.REJECT]: ButtonVariants.DANGER
                }[current_status]
              }
            ]
          : []
      }
    >
      <Label fontSize={16}>{moment(created_date).format('YYYY/MM/DD HH:mm')}</Label>
      <Title fontSize={20} mt={8}>
        支援要請を受けたあなたの備蓄情報
      </Title>
      {[
        {
          title: '備蓄品',
          value: item_name
        },
        {
          title: '数量',
          value: quantity + ' ' + item_unit
        },
        {
          title: '備蓄場所',
          value: [zip_code ? '〒' + zip_code + ' ' : false, province, city, street]
            .filter((item) => !!item)
            .join('')
        }
      ].map(({ title, value }, index) => (
        <Flex mt={8} key={index}>
          <Box width={LABEL_WIDTH}>
            <Label>{title}</Label>
          </Box>
          <Text>{value}</Text>
        </Flex>
      ))}

      {customer_information &&
        customer_information.map((customer, idx) => {
          const jc = pasreJsonContent(customer.json_content);
          return (
            <>
              {type === TAB_STATUS.cancelled && (
                <Box mt={5}>
                  <Title mt={18} fontSize={20}>
                    キャンセルメッセージ
                  </Title>
                  <Text><pre>{jc.CancelMessage}</pre></Text>
                </Box>
              )}
              <Title mt={18} fontSize={20}>
                支援要請者の連絡先
              </Title>

              <GrayZone key={idx}>
                <TextWithLabel label="事業者名/自治体名" text={customer.company_name} />
                <TextWithLabel
                  label="住所"
                  text={[customer.province, customer.city, customer.street]
                    .filter((item) => !!item)
                    .join('')}
                />

                <Flex style={{ justifyContent: 'space-between' }}>
                  <Flex style={{ width: '49%', maxWidth: '49%' }}>
                    <TextWithLabel label="支援要請者" text={jc.SenderName} />
                  </Flex>
                  <Flex style={{ width: '49%', maxWidth: '49%' }}>
                    <TextWithLabel label="支援要請者の連絡先" text={jc.SenderPhone} />
                  </Flex>
                </Flex>
                <TextWithLabel
                  label="支援要請者からのメッセージ"
                  text={jc.MessageContent}
                />
              </GrayZone>
            </>
          );
        })}
      {type === TAB_STATUS.waiting && (
        <Flex mt={20}>
          <Button
            type="primary"
            onClick={() => {
              setModalConfirm(MODAL_HELP_TYPE.CAN_HELP);
            }}
          >
            支援可能
          </Button>
          <Box margin={8} />
          <CustomButton
            onClick={() => {
              setModalConfirm(MODAL_HELP_TYPE.CAN_NOT_HELP);
            }}
          >
            支援不可
          </CustomButton>
        </Flex>
      )}
      {modalConfirm !== 0 && (
        <ModalConfirm
          type={modalConfirm}
          id={customer_support_id}
          onClose={(shouldFetch = false) => {
            setModalConfirm(0);
            if (shouldFetch) refetch();
          }}
        />
      )}
    </Card>
  );
}

const GrayZone = styled.div`
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ddd;
`;

const pasreJsonContent = (jc) => {
  try {
    return JSON.parse(jc);
  } catch {
    return {};
  }
};
