import { Button, Modal, Typography, Badge } from "antd";
import { user } from "api";
import ReserveInfo from "api/ReserveInfo/index";
import { ReactComponent as CubeIcon } from "assets/svg/cube.svg";
import FlagIconBlue from "assets/svg/FlagIcon/index";
import { ReactComponent as LocationIcon } from "assets/svg/location.svg";
import { ReactComponent as CloseIcon } from 'assets/svg/close-circle.svg';
import { ReactComponent as LogoutIcon } from "assets/svg/logout-icon.svg";
import { ReactComponent as SearchIcon } from "assets/svg/search.svg";
import TickIconBlue from "assets/svg/TickBlue/index";
import Flex from "components/misc/Flex";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { formatNumber } from "utils/constant";
import { message, Spin } from "../../../node_modules/antd/lib/index";
import PublicLayout from "../../components/Layout/PublicLayout";

const EmptyReserveModal = styled(Modal)`
  width: 375px !important;
  margin: 0 auto;

  .ant-modal-content {
    padding: 24px;
    border-radius: 8px;

    .ant-modal-body {
      padding: unset;
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 16px;

      .label{
        font-weight: 700;
        font-size: 28px;
        padding: 8px 0px;
        line-height: 36px;
        color: #0059ab;
        text-align: center;
      }

      button {
        width: 40%;
        height: 50px;
      }
    }

    .ant-modal-close {
      top: 24px;
      right: 24px;
      margin: unset !important;
      width: unset;

      .ant-modal-close-x {
        width: unset;
        height: unset;
        line-height: unset;
      }
    }
  }
`;


const StyledDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  @media (max-width: 576px) {
    width: 100%;
    min-width: 350px;
    overflow: overlay;
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: #a8a8a8;
      border-radius: 6px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }
  }
  display: flex;
  align-items: center;
  flex-direction: column;

  .log-in {
    font-size: 40px;
    line-height: 48px;
    color: #0059ab;
  }

  .description {
    color: #333;
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
    margin-bottom: 50px;
  }

  form {
    width: 100%;

    .ant-form-item {
      margin-bottom: 16px;
    }
  }

  hr {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .signup-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
  }

  .button {
    margin-top: 56px;
    background: #53b7b2;
    border: 1px solid #53b7b2;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    width: 100%;
    height: auto;
  }

  .button-2 {
    background: #0059ab;
    border: 1px solid #0059ab;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    margin: 16px 0 28px 0;
    width: 100%;
    height: auto;
  }

  .wrap-detail {
    padding: 16px;
    width: 375px;
    background-color: #f2f5fe;
  }

  .wrap-list-card {
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow: scroll;
    overflow: overlay;
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: #a8a8a8;
      border-radius: 6px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }
    position: absolute;
    top: 22%;
    left: 50%;
    transform: translate(-50%, -20%);
    width: 100%;
    justify-content: center;
    height: 128px;
    background-color: transparent;
    z-index: 0;

    .wrap-item-request:last-child {
      margin-right: unset;
    }
  }

  .wrap-list-request {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 344px;
    height: 128px;
    background-color: transparent;
    margin-bottom: 29px;
    display: flex;
    justify-content: space-between;

    .badge {
      width: 48%;

      .ant-badge-count{
        width: 30px;
        height: 30px;
        top: 5px;
        right: 5px;
        line-height: 30px;
        font-size: 16px;
        font-weight: 700;
        border-radius: 50%;
        padding: unset;
      }

      .text-small{
        .ant-badge-count{
          font-size: 12px;
        }
      }

      .text-small{
        .ant-badge-count{
          font-size: 12px;
        }
      }
    }

    .blue{
      .ant-badge-count{
        background: white;
        border: solid 3px blue;
        color: black;
        line-height: 23px;
      }
    }
  }

  .wrap-item-request {
    margin-right: 8px;
    border-radius: 4px;
    min-width: 164px;
    height: 128px;
    background-color: white;
    padding: 12px;
  }

  .button-style {
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    margin: 0 0 16px 0;
    width: 100%;
    height: 48px;
    font-size: 16px;
  }

  .request-btn-style {
    display: block;
    background: #fff;
    border-radius: 4px;
    padding: 4px 15px;
    cursor: pointer;
    width: fit-content;
    margin-top: 8px;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 700;
  }

  .btn-primary {
    color: #0059ab;
  }

  .search-icon {
    margin-right: 10px;
  }

  .wrap-icon {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 12px;
  }

  .request-icon {
    margin-right: 8px;
  }

  .number {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }

  .name {
    font-weight: 400;
    font-size: 14px;
  }
`;

const Logout = styled.div`
  color: #0059ab;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  padding-bottom: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  button.content {
    cursor: pointer;
    height: fit-content;
    display: flex;
    gap: 6px;
    justify-content: center;
    background: none;
    border: none;
    outline: none;
    margin: 0;
    padding-left: 20px;
  }

  .icon {
    transform: rotate(180deg);
    stroke: #003974 !important;
    width: 16px;
  }
  .text {
    font-weight: bold;
    color: #003974;
    font-size: 14px;
    line-height: 24px;
    font-family: "Segoe UI", SegoeUI, "Yu Gothic UI", "Meiryo UI", "Helvetica Neue",
      Helvetica, Arial, sans-serif;
  }
`;

const ModalLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .btn-modal-style {
    cursor: pointer;
    height: 50px;
    width: 154px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const LENGTH_REQUEST_WAITING = 100;
const LENGTH_REQUEST_VIEW = 100;

export default function TopUser({ onRequestLogout }) {
  const [loading, setLoading] = useState(false);
  const [loadingReserve, setLoadingReserve] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState([]);
  const [modalEmptyReserve, setModalEmptyReserve] = useState(false);
  const navigate = useNavigate();
  const { Text } = Typography;

  const WrapRequest = styled.div`
    border-radius: 4px;
    height: auto;
    background-color: ${(props) => (props.isClick === true ? "#DDE9F7" : "#FE757C")};
    padding: 12px;

    .request-text {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      color: ${(props) => (props.isClick === true ? "#0059AB" : "#fff")};
    }

    .icon-style {
      fill: ${(props) => (props.isClick === true ? "transparent" : "#FE757C")};
      border: ${(props) => (props.isClick === true ? "#0059AB" : "#fff")};
      stroke: ${(props) => (props.isClick === true ? "#0059AB" : "#fff")};
    }

    .request-btn-style {
      background: #fff;
      border-radius: 4px;
      padding: 4px 15px;
      cursor: pointer;
      width: fit-content;
      margin-top: 8px;
      color: ${(props) => (props.isClick === true ? "#0059AB" : "#FE757C")};
    }
  `;
  
  const listUserRequest = [
    {
      icon: <CubeIcon />,
      name: "ユーザー数",
      unit: "件",
      number: `${data?.total_user}`
    },
    {
      icon: <LocationIcon />,
      name: "備蓄拠点数",
      unit: "件",
      number: `${data?.total_reserve}`
    }
  ];

  const listUserNewRequest = [
    {
      icon: <FlagIconBlue />,
      number: "確認する",
      isClick: `${data?.number_reserve_water === 0 ? true : false}`,
      link: "/user-requested"
    }
  ];
  const listRequest = [
    {
      icon: <TickIconBlue />,
      number: "確認する",
      link: "/user-requesting"
    }
  ];

  const onConfirmEdit = () => {
    navigate("/user-information");
  };

  const [countWaiting, setCountWaiting] = useState(0);
  const [countView, setCountView] = useState(0);

  const fetchTopData = async () => {
    setLoading(true);
    try {
      const dataTop = await user.getTopInfo();
      setData(dataTop);
    } catch (err) {
      message.error(
        "サーバへの要求に失敗しました。しばらく経ってから再度実施してください。"
      );
    }
    setLoading(false);
  };

  const fetchSupportWaiting = async () => {
    setLoading(true);
    try {
      const res = await user.getUserRequested('waiting');
      setCountWaiting(res.length);
    } catch (err) {
      message.error(err);
    }
    setLoading(false);
  };

  const fetchRequestSituationView = async () => {
    setLoading(true);
    try {
      const res = await user.getRequestSituations();
      setCountView(res.filter(x => x.current_status === 1).length);
    } catch (err) {
      message.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTopData();
    fetchSupportWaiting();
    fetchRequestSituationView();
  }, []);

  const onCloseModal = useCallback(() => {
    setIsEdit(false);
  }, []);

  const handleChangePageReserve = () => {
    navigate("/list-information");
  };

  const goToSearchLocation = (path) => {
    navigate(path);
  };

  const handleUserSendRequest = async () => {
    try {
      setLoadingReserve(true);
      let response = await ReserveInfo.getReserveInfo();
      if(response.length === 0){
        setModalEmptyReserve(true);
      } else {
        navigate('/user-send-request');
      }
      setLoadingReserve(false);
    } catch (error) {
      setLoadingReserve(false);
    }
  };

  const handleCancelEmptyReserve = () => {
    setModalEmptyReserve(false);
  }

  return (
    <StyledDiv>
      <PublicLayout isHideLogo>
        <div className="wrap-detail">
          <div className="wrap-list-card">
            {listUserRequest?.map((item, index) => {
              return (
                <div className="wrap-item-request" key={index}>
                  {loading ? (
                    <LoadingComponent />
                  ) : (
                    <>
                      <div className="wrap-icon">
                        <div className="request-icon">{item?.icon}</div>
                        <div className="name">{item?.name}</div>
                      </div>
                      <div className="number">
                        {item.number ? formatNumber(item?.number) : "-"} {item?.unit}
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
          <Spin spinning={loadingReserve} style={{ height: "100%" }}>
            <Button
              type="primary"
              className="btn button"
              onClick={() => goToSearchLocation("/user-search-store-location")}
            >
              <SearchIcon className="search-icon" />
              近隣の備蓄場所を検索する
            </Button>
            <Button
              type="primary"
              className="btn button-2"
              onClick={handleUserSendRequest}
            >
              支援を要請する
            </Button>
            <div className="wrap-list-request">
              {listUserNewRequest.map((item, index) => {
                return loading ? (
                  <LoadingComponent />
                ) : (
                  <Badge className={`badge ${countWaiting > LENGTH_REQUEST_WAITING ? "text-small" : "text-normal"} ${countWaiting === 0 ? "blue" : 'red'}`} count={countWaiting}>
                    <WrapRequest
                      key={index}
                      isClick={countWaiting === 0 ? true : false}
                    >
                      <>
                        <div className="icon-style">{item.icon}</div>
                        <div className="request-text">
                          あなたが受けた<br/>支援要請
                        </div>
                        <Link className="request-btn-style request-text" to={item.link}>
                          {item.number}
                        </Link>
                      </>
                    </WrapRequest>
                  </Badge>
                );
              })}
              {listRequest.map((item, index) => {
                return loading ? (
                  <LoadingComponent />
                ) : (
                  <Badge className={`badge ${countView > LENGTH_REQUEST_VIEW ? "text-small" : "text-normal"} ${countView === 0 ? "blue" : 'red'}`} count={countView}>
                    <WrapRequest key={index} isClick={true}>
                      <div className="icon-style">{item.icon}</div>
                      <div className="request-text">
                        あなたが出した<br/>支援要請の結果
                      </div>
                      <Link className="request-btn-style request-text" to={item.link}>
                        {item.number}
                      </Link>
                    </WrapRequest>
                  </Badge>
                );
              })}
            </div>
            <Button
              className="btn btn-primary button-style"
              onClick={handleChangePageReserve}
            >
              備蓄情報を登録/編集する
            </Button>
            <Button
              className="btn btn-primary button-style"
              search={true}
              onClick={() => onConfirmEdit()}
            >
              アカウント情報を確認/編集する
            </Button>
            <Logout>
              <button className="content" onClick={onRequestLogout}>
                <Text className="text">ログアウト</Text>
                <LogoutIcon className="icon" />
              </button>
            </Logout>
          </Spin>
        </div>
        <Modal
          centered
          visible={isEdit}
          onOk={onConfirmEdit}
          onCancel={onCloseModal}
          width={300}
          footer={
            <ModalLayout>
              <Button onClick={onCloseModal} className="btn-modal-style">
                キャンセル
              </Button>
              <Button onClick={onConfirmEdit} type="primary" className="btn-modal-style">
                ログアウト
              </Button>
            </ModalLayout>
          }
        >
          <p>ログアウトします。よろしですか？</p>
        </Modal>
      </PublicLayout>
      <EmptyReserveModal
        visible={modalEmptyReserve}
        closeIcon={<CloseIcon />}
        onCancel={handleCancelEmptyReserve}
        footer={false}
        centered
        className="modal-empty-reserve"
      >
        <p className="label">備蓄情報を先に登録してください。</p>
        <Button onClick={handleCancelEmptyReserve} type="primary">
          OK
        </Button>
      </EmptyReserveModal>
    </StyledDiv>
  );
}

const LoadingComponent = () => (
  <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
    <Spin />
  </Flex>
);
