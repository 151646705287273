import { Modal, message } from "antd";
import { authenticate } from "api";
import { Layout } from "components";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const keyMappings = ["top-user", "user-list", "data-register"];

const LayoutContainer = (props) => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const path = useMemo(() => {
    const pathToCheck = location?.pathname?.slice(1);
    const isValidPath = keyMappings.includes(pathToCheck);
    if (isValidPath) {
      return pathToCheck;
    }
    return keyMappings[0];
  }, [location]);

  const selectedKeys = useMemo(() => {
    return [path];
  }, [path]);

  const { isAdmin, email } = useSelector((state) => {
    return {
      isAdmin: state?.authenticate.isAdmin,
      email: state?.authenticate.email
    };
  });

  const onRequestLogout = useCallback(() => {
    setVisible(true);
  }, []);

  const onConfirmLogout = async () => {
    try {
      await authenticate.logout();
      navigate("/login");
      setVisible(false);
    } catch (error) {
      message;
    }
  };

  const onCloseModal = useCallback(() => {
    setVisible(false);
  }, []);

  const handleMenuItemChage = useCallback(
    (key) => {
      navigate(`/${key}`);
    },
    [navigate]
  );

  return (
    <>
      <Layout
        {...props}
        userName={isAdmin ? "Admin" : email}
        onRequestLogout={onRequestLogout}
        selectedKeys={selectedKeys}
        onMenuItemChage={handleMenuItemChage}
      />
      <Modal
        className="logout-modal"
        centered
        visible={visible}
        onOk={onConfirmLogout}
        onCancel={onCloseModal}
        cancelText={"キャンセル"}
        okText={"ログアウト"}
        width={360}
        closable={false}
      >
        <p>ログアウトします。よろしいですか？</p>
      </Modal>
    </>
  );
};

export default LayoutContainer;
