import { Modal, message } from 'antd';
import { authenticate } from 'api';
import { ChangePasswordUserForm } from 'components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import localStorage from 'utils/localStorage';


const SuccessModal = styled(Modal)`
  .ant-modal-footer {
    .ant-btn {
      height: 50px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      width: 100%;
    }
  }
  .ant-modal-body {
    border-radius: 8px;
  }
  .ant-modal-content p {
    padding: 0px;
  }
`;
const StyledIcon = styled.div`
  .email-icon {
    margin-top: 60px;
    margin-bottom: 16px;
    text-align: center;
  }
`;
const UserChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const email = useSelector((state) => {
    return state?.authenticate?.email;
  });

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await authenticate.userChangedPassword({
        email,
        old_password: values.old_password,
        new_password: values.new_password
      });

      setLoading(false);
      await message.success('Change password successfully');
    } catch (error) {
      setLoading(false);
      message.error(error?.message);
    }
  };

  useEffect(() => {
    const { idToken, access_token } = localStorage.loadTokens();
    if (!idToken && !access_token) {
      navigate('/user-password');
    }
  }, [navigate]);
  const onVerifyRetry = () => {
    setVisible(false);
    navigate('/login');
  };

  return <>
    <ChangePasswordUserForm onSubmit={handleSubmit} loading={loading} isVisible={isVisible} setIsVisible={setIsVisible} />
  </>
};

export default UserChangePassword;
