import React from 'react'

const FlagIconBlue = (props) => {
  return (
    <svg {...props} width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.15039 2V22" strokeWidth="1.5" stroke={`${props => props.isClick === true ? "#0059AB" : "#fff"}`} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.15039 4H16.3504C19.0504 4 19.6504 5.5 17.7504 7.4L16.5504 8.6C15.7504 9.4 15.7504 10.7 16.5504 11.4L17.7504 12.6C19.6504 14.5 18.9504 16 16.3504 16H5.15039" stroke={`${props => props.isClick === true ? "#0059AB" : "#fff"}`} strokeWidth="1.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
export default FlagIconBlue;
