import { useEffect, useState } from 'react';

const useFetch = ({
  prefetched = true,
  defaultData = [],
  fetcher = () => {},
  onSuccess = () => {}
}) => {
  const [data, setData] = useState(defaultData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const doFetch = async (data) => {
    setLoading(true);
    setError(null);
    try {
      const result = await fetcher(data);
      if (Array.isArray(result)) {
        setData(result);
      } else {
        if (result && result.data) {
          setData(result.data);
        } else {
          setData(result);
        }
      }
      onSuccess(result);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (prefetched) {
      doFetch();
    }
  }, []);

  return { data, loading, error, doFetch };
};

export default useFetch;
