import styled from "styled-components";

export const StyledDiv = styled.div`
  position: relative;
  margin: 20px 16px;
  padding: 120px 0 90px;
  &.preview-request{
    // padding: 120px 0 100px;
  }

  .ant-form-item {
    width: 100%;
    margin-bottom: 16px;

    // .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    //   display: inline-block;
    //   margin-right: 4px;
    //   color: #ff4d4f;
    //   font-size: 10px;
    //   font-family: SimSun, sans-serif;
    //   line-height: 1;
    //   content: '';
    // }
  }

  .register {
    display: block;
    text-align: center;
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    color: #0059ab;
    margin-bottom: 12px;
  }

  .description {
    color: #333;
    max-width: 310px;
    font-size: 13px;
    line-height: 23px;
    font-weight: 400;
    text-align: center;
  }

  form {
    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    .ant-input[disabled] {
      color: rgba(0, 0, 0, 0.85);
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      cursor: not-allowed;
      padding: 0;
      border-bottom: 1px solid #dcdcdc;
      border-right-width: 1px;
      opacity: 1;
    }

    .ant-input-password[disabled] {
      border-bottom: 1px solid #dcdcdc;
      border-right-width: 1px;
      color: #333;
      -webkit-text-security: disc;
    }

    .ant-checkbox + span {
      color: rgba(0, 0, 0, 0.85);
    }

    .ant-form-item {
      margin-bottom: 12px;

      .ant-form-item {
        margin-bottom: 0;
      }

      .ant-form-item-label {
        padding-bottom: 0px;

        label {
          font-size: 12px;
          line-height: 150%;
          font-weight: 400;
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          color: #7b7b7b;

          &::before {
            color: #ff676e;
          }
        }
      }

      &.group-wrapper {
        .ant-form-item-label {
          padding-bottom: 0;
        }

        .ant-col.ant-form-item-control {
          .ant-form-item-label {
            padding-bottom: 4px;

            label {
              padding-left: 8px;
              margin-top: 14px;
              font-style: normal;
              font-weight: 400;
              font-size: 10px;
              line-height: 16px;
              color: #7B7B7B;
            }
          }
        }
      }

      .ant-form-item-control {
        input {
          height: 34px;
          
        }
        .password-hidden {
          -webkit-text-security: disc;
        }
       
        .ant-input-password {
          height: 34px;
          
          input {
            height: 32px;
          }
        }

        .des {
          font-size: 10px;
          font-weight: 400;
          color: #7b7b7b;
        }
      }
    }

    .form_agreement {
      .ant-form-item-label {
        label {
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 16px;
          color: #7B7B7B;
        }
      }

      label {
        margin-left: unset;

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.85);
          letter-spacing: 0.5px;
        }
      }
    }

    .form_font {
      rgba(0, 0, 0, 0.85);
    }
    

    .anticon {
      color: #333333;
    }
  }

  .ant-form label {
    font-size: 13px;
  }

  .form-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    background-color: white;
    padding: 40px 16px 0;
    border-radius: 8px;
  }
`;
export const StyleIsNotify = styled.div`
  .ant-modal-body {
    border-radius: 8px;
  }

  background: #ffffff;
  border-radius: 8px;

  .isNofifyQuestion {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
`;
export const StylePlaceHolder = styled.div`
  .style-placeholder {
    font-size: 15px;
    color: #333333;
  }
`;
export const StyleButton = styled.div`
  display: flex;
  flex-direction: row-reverse;
  column-gap: 15px;

  .button-ok {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .button-cancel {
    color: #0059ab;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
`;
export const is_notify_list = [
  { label: "受け取る", value: "true" },
  { label: "受け取らない", value: "false" }
];
export const customer_type_id_list = [
  { label: "民間", value: "1" },
  { label: "自治体", value: "2" }
];
export const SignUpAction = styled.div`
  position: fixed;
  display: block;
  bottom: 0;
  left: 50%;
  padding: 15px;
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
  z-index: 1;
  background-color: #f6f9ff;
`;
export const FixedBottom = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f6f9ff;
  padding: 16px;
  max-width: 375px;
  margin: auto;
`;
