import { apiUrl } from 'api/utils';
import baseApi from './base';

export const REQUEST_SITUATION = apiUrl('support/request-situation');
export const USER_REQUESTED = apiUrl('user/support');
export const USER_INFORMATION = apiUrl('user/profile');
export const TOP_INFORMATION = apiUrl('user/dashboard');
export const USER_EDIT = apiUrl('user/profile');
export const USER_RESERVE_ADDRESS = apiUrl('user/reserve_address');

export default {
  getRequestSituations: async () => {
    return baseApi.authRequest(REQUEST_SITUATION + '/view');
  },
  cancelRequest: (body, isSingle) => {
    return baseApi.authRequest(REQUEST_SITUATION + `/cancel-request`, {
      method: 'PUT',
      ...(isSingle
        ? { body: JSON.stringify(body) }
        : { body: JSON.stringify({ cancel_message: body.cancel_message }) })
    });
  },

  getUserRequested: (tab) => {
    return baseApi.authRequest(USER_REQUESTED + `/` + tab);
  },
  acceptUserRequested: (id) => {
    return baseApi.authRequest(USER_REQUESTED + '/accept/' + id, {
      method: 'POST'
    });
  },
  rejectUserRequested: (id) => {
    return baseApi.authRequest(USER_REQUESTED + '/deny/' + id, {
      method: 'PUT'
    });
  },
  getUserInfo: async () => {
    const data = await baseApi.authRequest(USER_INFORMATION, {
      method: 'GET'
    });
    return data;
  },
  getTopInfo: async () => {
    const data = await baseApi.authRequest(TOP_INFORMATION, {
      method: 'GET'
    });
    return data;
  },
  updateUserInfo: (body) => {
    return baseApi.authRequest(USER_EDIT, {
      method: 'PUT',
      body: JSON.stringify(body)
    });
  },
  getReserveAddress: async () => {
    const data = await baseApi.authRequest(USER_RESERVE_ADDRESS, {
      method: 'GET'
    });
    return data;
  },
};
