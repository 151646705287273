import localStorage from 'utils/localStorage';

export const isAuthenticated = () => {
  const { idToken, accessToken } = localStorage.loadTokens();
  return Boolean(idToken && accessToken);
};

export const checkExist = (data) => {
  if(data.name === "" && data.email === "" && data.mobile_phone_number === "" && data.landline_number === ""){
    return false;
  } else {
    return true;
  }
};