import { Input as AInput } from 'antd';
import styled from 'styled-components';

const TextInput = styled(AInput)`
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  height: 34px;
  padding: 5px 12px;
  border: 1px solid #7B7B7B;
  background-color: #fafcfe;
  &::placeholder {
    color: #7b7b7b;
  }
  ,
  .ant-input-wrapper {
    .ant-input {
      width: 100%;
      height: 24px;
      background: #fafcfe;
      font-size: 14px;
      line-height: 24px;
    }

    .ant-input-affix-wrapper {
      padding: 0;
      border: none;
      background: #fafcfe;
    }
  }
`;

const Password = styled(AInput.Password)`
  width: 100%;
  height: 34px;
  padding: 0 12px 0 0;
  border: 1px solid #7B7B7B;
  background-color: #fafcfe;
  &::placeholder {
    color: #7b7b7b;
  }
  input.ant-input {
    padding: 4px 12px;
    font-size: 14px;
    line-height: 24px;
    background-color: #fafcfe;
    &::placeholder {
      color: #7b7b7b;
    }
  }
  .anticon {
    color: #7b7b7b;
    width: 13.62px;
    height: 12.04px;
  }
`;

const Input = ({ type = 'text', ...props }) => {
  if (type === 'text') {
    return <TextInput {...props} />;
  } else if (type === 'password') {
    return <Password {...props} />;
  }
};

export default Input;
