import styled from 'styled-components';
import { SideBar, Header, PublicLayout } from 'components';
import TopUser from '../../containers/TopUser/index'
import {useState} from 'react';
const AuthenticatedLayout = styled.section`
  width: 100%;
  background-color: #f2f5fe;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const RightContent = styled.section`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.main`
  height: 100%;
  flex: 1 0 0;
  width: 100%;
  padding: 32px;
  overflow: auto;
  justify-content: center;

  .page-title {
    color: #0059ab;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px;
  }
`;

const FitContent = styled.section`
  width: 100%;
  max-width: 1559px;
  margin: 0 auto;
`;

const Layout = ({
  type = 'private',
  userName,
  children,
  onRequestLogout,
  onMenuItemChage,
  selectedKeys
}) => {
  if (type === 'public') {
    return <PublicLayout>{children}</PublicLayout>;
  }
  return (
    <AuthenticatedLayout>
      <FitContent >
        <TopUser onRequestLogout={onRequestLogout} />
      </FitContent>
    </AuthenticatedLayout>
  );
};
export default Layout;
