import { Button, Modal } from "components";
import { message } from "antd";
import styled from "styled-components";
import filePDF1 from "assets/pdf/file1.pdf";
import axios from "../../../../node_modules/axios/index";

const TermAggrement1 = ({ check_1, setCheck1, setChecked1 }) => {
  const StyleTermAggreement1 = styled(Modal)`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    .ant-modal-body {
      padding: 17px;
      background-color: #f2f5fe;
    }
    .ant-modal-footer {
      background-color: #f2f5fe;
    }
  `;
  const StyleTermAggreement = styled.div`
    padding: 17px;
    background-color: white;
    border-radius: 8px;
    .term-aggrement-header {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #0059ab;
    }
    .term-aggrement-content {
      height: 550px;
      overflow-y: scroll;
      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      font-style: normal;
      font-size: 13px;
      line-height: 23px;
      color: #333333;

      ol.ol-parent {
        padding-left: 3px;
        margin-left: 0px;
        counter-reset: item;
        margin-bottom: unset;
        li {
          display: block;
          &::before {
            content: "(" counters(item, ".") ") ";
            counter-increment: item;
            font-weight: bold;
          }
        }
      }
      p {
        text-align: left;
        margin-bottom: unset;
      }
    }
    .term-aggrement-button {
      display: flex;
      margin-top: 10px;
      .button-copy {
        color: #0059ab;
        font-weight: 700;
      }
      .button-agree {
        margin-left: 10px;
        font-weight: 700;
      }
    }
    .button-cancel {
      color: #0059ab;
      font-weight: 700;
    }
  `;

  const handleDowloadAggrement = () => {
    axios({
      url: filePDF1,
      method: "GET",
      responseType: "blob"
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "UACJ水の架け橋ネットワーク_ご確認事項.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  return (
    <StyleTermAggreement1
      visible={check_1}
      onOk={() => {
        setCheck1(false);
        setChecked1(true);
      }}
      onCancel={() => {
        setCheck1(false);
        setChecked1(false);
      }}
      closable={false}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
    >
      <StyleTermAggreement>
        <div className="term-aggrement-header">
          水の架け橋ネットワーク
          <br />
          支援要請についてのご確認事項と利用方法
        </div>
        <br />
        <div className="term-aggrement-content">
          <ol className="ol-parent">
            <li>
              水の架け橋ネットワークに登録後、[支援要請を行う]をクリックすると、水の架け橋ネ
              ットワークが、支援要請を行ったユーザー（以下、「支援要請者」といいます。）の被災の
              事実と支援要請を近隣の水の架け橋ネットワークへ登録しているユーザーに発信し、支
              援の可否を照会します。
            </li>
            <li>
              上記の照会の結果、支援可能と回答したユーザー（以下、「支援可能者」といいます。）の
              備蓄場所の位置情報と備蓄量のリストが支援要請者に表示されます。
            </li>
            <li>
              支援要請者が上記のリストから支援要請を行う支援可能者を選択し、[支援可能]をクリ
              ックすることにより、支援要請者と支援可能者とのマッチングが成立し、相互の電話番号
              とメールアドレスが開示されます。
            </li>
            <li>
              備蓄水の輸送などの支援の実施は、支援要請者と上記でマッチングした支援可能者（以下、
              「支援者」といいます。）の当事者同士の責任で実施ください。
            </li>
            <li>
              支援要請者が備蓄水の支援を受けた際には、おおむね 90
              日以内に支援者に現物にて返還 ください。
            </li>
            <li>
              水の架け橋ネットワークは、支援可能者がいる場合に支援要請者と支援可能者の相互の
              連絡を可能にすることを目的としたものであり、支援可能者の有無および実際の支援の
              実施可否やその結果、並びに備蓄水の返還について、水の架け橋ネットワークの運営を行
              う株式会社ＵＡＣＪが保証するものではありません。
            </li>
            <li>
              [支援要請を行う]をクリックすることにより発信された支援要請は記録されます。虚偽
              の支援要請または訓練等での支援要請の発信を禁止します。
            </li>
            <li>
              支援要請により得た支援者の情報は、今回の支援およびその返還以外にはご利用いただ
              けません。
            </li>
          </ol>
        </div>
        <div className="term-aggrement-button">
          <Button onClick={handleDowloadAggrement} className="button-copy">
            ダウンロード
          </Button>
          <Button
            onClick={() => {
              setCheck1(false);
              setChecked1(true);
            }}
            type="primary"
            className="button-agree"
          >
            同意する
          </Button>
        </div>
        <Button
          style={{ marginTop: "10px" }}
          onClick={() => {
            setCheck1(false);
            setChecked1(false);
          }}
          type="cancel"
          className="button-cancel"
        >
          キャンセル
        </Button>
      </StyleTermAggreement>
    </StyleTermAggreement1>
  );
};
export default TermAggrement1;
