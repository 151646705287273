import ReserveInfo from 'api/ReserveInfo/index';
import Button from 'components/Button/index';
import StoreInformation from 'components/StoreInformation/index';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReserveDetail, fetchReserveInfo } from 'state/actions/reserveInfo';
import styled from 'styled-components';
import { Empty, Spin, message } from '../../../node_modules/antd/lib/index';
import { useNavigate } from '../../../node_modules/react-router-dom/index';

const StyledDiv = styled.div`
  width: 375px;
  min-height: calc(100% - 84px);
  padding: 24px 16px 0px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 576px) {
    min-width: 350px;
  }

  .ant-spin-nested-loading{
    width: 100%;
    height: 100%;

    .ant-spin-container{
      height: 100%;
    }
  }

  .store-title {
    font-weight: 700;
    font-size: 16px;
    color: #0059ab;
    margin-bottom: 24px;
  }
`;

const ButtonBar = styled.div`
  position: sticky;
  bottom: 0px;
  width: 100%;
  padding: 10px 16px 24px;
  max-width: 375px;
  margin: auto;
  background: #F6F9FF;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    .ant-btn-default {
      span {
        color: #0059ab;
      }
    }

    &.disabled{
      opacity: 0.4;
      pointer-events: none;
    }

    span {
      font-weight: 700;
      font-size: 16px;
      text-align: center;
    }
  }
`;

const ListReserveInformationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const listReserveInfo = useSelector((state) => {
    return state?.reserveInfo.list_reserve_info;
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await ReserveInfo.getReserveInfo();
        dispatch(fetchReserveInfo(response));
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleViewDetail = async (id) => {
    try {
      setLoading(true);
      const response = await ReserveInfo.getReserveDetail(id);
      dispatch(fetchReserveDetail(response));
      if(response){
        navigate('/detail-information');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("サーバへの要求に失敗しました。しばらく経ってから再度実施してください。");
    }
  };

  const handleCreateNewInformation = () => {
    navigate('/register-information');
  };

  const handleBack = () => {
    navigate("/top-user");
  };

  return (
    <>
      <StyledDiv>
        <h1 level={3} className="store-title">
          備蓄情報一覧
        </h1>
        <Spin spinning={loading} style={{ height: '100%' }}>
            {
              listReserveInfo.length ? listReserveInfo.map(reserve => {
                return (
                  <StoreInformation key={reserve.reserve_id} reserve={reserve} onClick={handleViewDetail}/>
                );
              }) : (
                <Empty
                  style={{ height: "555px", display: "flex", flexDirection: 'column', justifyContent: "center", margin: 'unset' }}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>データなし</span>}
                />
              )
            }
        </Spin>
      </StyledDiv>
      <ButtonBar>
        <Button onClick={handleBack} className={loading ? "disabled" : ""}>
          <span style={{ color: "#0059AB" }}>戻る</span>
        </Button>
        <Button type="primary" onClick={handleCreateNewInformation} style={{ marginLeft: '15px' }} className={loading ? "disabled" : ""}>
          <span>新規登録する</span>
        </Button>
      </ButtonBar>
    </>
  );
};

export default ListReserveInformationPage;
