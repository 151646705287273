import { Form } from "antd";
import authenticate from "api/authenticate/index";
import { ReactComponent as CloseIcon } from 'assets/svg/close-circle.svg';
import { Button, Input } from "components";
import Modal from 'components/Modal/index';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { saveValidKey } from "state/actions/authenticate";
import styled from "styled-components";
import { ERROR_MESSAGES } from "utils/constant";
import { useNavigate } from "../../../node_modules/react-router-dom/index";
import PublicLayout from "../../components/Layout/PublicLayout";
import filePDF1 from "assets/pdf/file1.pdf";
import filePDF2 from "assets/pdf/file2.pdf";
import filePDF3 from "assets/pdf/file3.pdf";
import axios from "../../../node_modules/axios/index";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 180px 0 0;
  margin: 0 15px;

  .layout{
    padding: 10px 16px 40px;
    border-radius: 8px;
    background-color: white;
  }

  .log-in {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #0059ab;
    text-align: center;
  }

  .description {
    font-weight: 400;
    color: #333;
    font-size: 13px;
    line-height: 23px;
    margin-top: 16px;
    margin-bottom: 32px;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    .ant-form-item {
      &.custom-email-item {
        margin-bottom: 16px;
      }

      &.custom-password-item {
        margin-bottom: 12px;
      }

      &.custom-submit-item {
        margin-bottom: 0px;
      }
    }
  }

  hr {
    width: 80%;
    opacity: 0.2;
    margin: 0 auto;
  }

  .signup-text {
    display: block;
    width: 100%;
    padding: 12px 0;
    font-weight: 400;
    font-size: 11px;
    line-height: 24px;
    text-align: center;
    color: #333333;
    margin: 0;
    a {
      text-decoration: underline;
      font-weight: 700;
    }
    .agreement{
      display: inline;
      background: transparent;
      border: unset;
      padding: unset;
      text-decoration: underline;
      cursor: pointer;
      color: #0059AB;
      font-weight: 700;
    }
  }

  .signup-button {
    background: #53b7b2;
    border: 1px solid #53b7b2;
    border-radius: 4px;
  }
`;

const StyledForgotPassword = styled.div`
  button {
    width: 100%;
    text-align: left;
    font-size: 14px;
    line-height: 1;
    font-weight: 700;
    margin-top: 0;
    padding: 0;
    height: auto;
    font-style: normal;
    margin-bottom: 32px;
    color: #003974;
  }
`;

const ModalValidKey = styled(Modal)`
  .ant-modal-content {
    padding: 24px;
    border-radius: 8px;

    .ant-modal-body {
      padding: unset;
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 16px;

      .label{
        font-weight: 700;
        font-size: 28px;
        padding: 8px 0px;
        line-height: 36px;
        color: #0059ab;
        text-align: center;
      }

      .form{
        display: flex;
        justify-content: space-between;
        .ant-row{
          width: 140px;
          min-height: 110px;
          margin-bottom: unset;
          flex-direction: column !important;
          flex-flow: wrap;

          .ant-form-item-label{
            padding-bottom: unset;
            text-align: unset;
          }

          .ant-form-item-control{
            min-height: 78px;
          }
          
          @media (max-width: 575px){
            flex-direction: unset !important;
          }
        }

      }

      .custom-submit-form{
        margin-top: 24px;
        margin-bottom: unset;

        .ant-form-item-control-input-content{
          display: flex;
          justify-content: center;
          button{
            width: 50%;
          }
        }
      }
    }

    .ant-modal-close {
      top: 24px;
      right: 24px;
      margin: unset !important;
      width: unset;

      .ant-modal-close-x {
        width: unset;
        height: unset;
        line-height: unset;
      }
    }
  }
`;

const ModalCancelForm = styled(Modal)`
  .ant-modal-content {
    padding: 24px;
    border-radius: 8px;

    .ant-modal-body {
      padding: unset;
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 16px;

      .label{
        font-weight: 700;
        font-size: 18px;
        line-height: 30px;
        padding: 12px 0px;
        color: #333;
        text-align: center;
      }

      button {
        width: 40%;
      }
    }

    .ant-modal-close {
      top: 24px;
      right: 24px;
      margin: unset !important;
      width: unset;

      .ant-modal-close-x {
        width: unset;
        height: unset;
        line-height: unset;
      }
    }
  }
`;

const LoginForm = ({ onLogin, loading }) => {

  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [ openModal, setOpenModal ] = useState(false);
  const [ openModalError, setOpenModalError ] = useState(false);
  const [ loadingButton, setLoadingButton ] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setDisable(loading);
  }, [loading]);

  const onFinish = (values) => {
    onLogin(values);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  const handleCancel = () => {
    setOpenModal(false);
  }

  const handleCancelError = () => {
    setOpenModalError(false);
  }

  const onFinishSubmit = async (values) => {
    setLoadingButton(true);
    const data = await authenticate.verify_registration_id({ first_number: values.first_number, last_number: values.last_number });
    if(data.is_success){
      setLoadingButton(false);
      dispatch(saveValidKey(values));
      navigate("/signup");
    } else {
      setLoadingButton(false);
      setOpenModal(false)
      setOpenModalError(true);
    }
  }

  const handleClickDownloadFile1 = () => {
    axios({
      url: filePDF1,
      method: 'GET',
      responseType: 'blob',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'UACJ水の架け橋ネットワーク_ご確認事項.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  const handleClickDownloadFile2 = () => {
    axios({
      url: filePDF2,
      method: 'GET',
      responseType: 'blob',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'UACJ水の架け橋ネットワーク_プライバシーポリシー.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  const handleClickDownloadFile3 = () => {
    axios({
      url: filePDF3,
      method: 'GET',
      responseType: 'blob',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'UACJ水の架け橋ネットワーク_ご利用規約.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  return (
    <PublicLayout isLogoBlue>
      <StyledDiv>
        <div className="layout">
          <Form onFinish={onFinish} autoComplete="off">
            <div className="log-in">ログイン</div>
            <div className="description">IDとパスワードを入力してログインしてください。</div>
            <Form.Item
              className="custom-email-item"
              name="email"
              rules={[{ required: true, message: ERROR_MESSAGES.REQUIRED }]}
            >
              <Input id="email" name="email" placeholder="アカウントID（メールアドレス）" type="text" />
            </Form.Item>
            <Form.Item
              className="custom-password-item"
              name="password"
              rules={[{ required: true, message: ERROR_MESSAGES.REQUIRED }]}
            >
              <Input
                id="password"
                name="password"
                type="password"
                placeholder="パスワード"
              />
            </Form.Item>

            <StyledForgotPassword>
              <Button
                type="link"
                disabled={disable}
                onClick={() => navigate("/forgot-password")}
              >
                パスワードを忘れた場合
              </Button>
            </StyledForgotPassword>
            <Form.Item className="custom-submit-item">
              <Button loading={loading} type="primary" htmlType="submit">
                ログイン
              </Button>
            </Form.Item>
          </Form>
          <p className="signup-text">
          本ネットワークシステムをお使いいただくにあたり
          <br/>
          <button className="agreement" onClick={handleClickDownloadFile1}>支援要請のご確認事項</button>　
          <button className="agreement" onClick={handleClickDownloadFile2}>プライバシーポリシー</button>　
          <button className="agreement" onClick={handleClickDownloadFile3}>利用規約</button>
          <br/>
          に同意いただく必要があります。
          </p>
          <hr />
          <p className="signup-text">
            <div>
          「水の架け橋」備蓄水をご購入いただいた事業者様・自治体様
            </div>
          には登録コードをお送りしています。
          <br/>
          下記よりお進み頂き登録コードを入力してください。
          <br/>
          * ご購入後、登録コードを受け取られていない方は<a rel="redirect" id="form" href="https://mizuno-kakehashi.com/contact">こちら</a>までお問合せください。
          </p>
          <Button className="signup-button" type="primary" disabled={disable} onClick={handleOpenModal}>
            新規会員登録
          </Button>
        </div>
      </StyledDiv>
      <ModalValidKey
        visible={openModal}
        closeIcon={<CloseIcon />}
        onCancel={handleCancel}
        footer={false}
      >
        <div className="label">
          登録コードを記入ください
        </div>
        <Form
          onFinish={onFinishSubmit}
          style={{
            width: '100%'
          }}
        >
          <div className="form">
            <Form.Item
              name="first_number"
              label="8桁"
              rules={[
                { required: true, message: "入力必須項目です。" },
                {
                  pattern: /^[0-9]{8}$/,
                  message: "8桁の半角数字を入力してください。"
                }
              ]}
            >
              <Input type="text" />
            </Form.Item>
            <div style={{
              width: '4%',
              height: '2px',
              backgroundColor: 'black',
              top: '47%',
              position: 'absolute',
              right: '46%',
              transform: 'translate(-53%, -52%)'
            }} />
            <Form.Item
              name="last_number"
              label="8桁"
              rules={[
                { required: true, message: "入力必須項目です。" },
                {
                  pattern: /^[0-9]{8}$/,
                  message: "8桁の半角数字を入力してください。"
                }
              ]}
            >
              <Input type="text"/>
            </Form.Item>
          </div>
          <Form.Item className="custom-submit-form">
            <Button loading={loadingButton} type="primary" htmlType="submit">
              次へ
            </Button>
          </Form.Item>
        </Form>
      </ModalValidKey>
      <ModalCancelForm
        visible={openModalError}
        closeIcon={<CloseIcon />}
        onCancel={handleCancelError}
        footer={false}
      >
        <div className="label">
          登録コードが正しくありません。ご確認後、再登録ください。
        </div>
        <Button type="primary" onClick={handleCancelError}>
          OK
        </Button>
      </ModalCancelForm>
    </PublicLayout>
  );
};

export default LoginForm;
