import { Menu as AMenu } from 'antd';
import styled from 'styled-components';
import { ReactComponent as SidebarIcon } from 'assets/svg/sidebar-header.svg';
import { ReactComponent as DashboardIcon } from 'assets/svg/dashboard-icon.svg';
import { ReactComponent as UserIcon } from 'assets/svg/user-icon.svg';
import { ReactComponent as DataRegisterIcon } from 'assets/svg/data-register-icon.svg';

const StyledMenu = styled(AMenu)`
  margin-top: 40px;
  width: 100%;
  flex: 1 0 0;

  &.ant-menu-vertical {
    border-right: none;

    .ant-menu-item {
      height: 54px;
      margin: 0;
      padding: 14px 16px;
      display: flex;
      align-items: center;
      font-size: 16px;

      &.ant-menu-item-selected {
        background-color: rgba(0, 89, 171, 0.1);
        border-radius: 6px;
        color: #0059ab;
      }
    }
  }
`;

const SideBarContainer = styled.nav`
  width: 265px;
  min-width: 265px;
  height: 100%;
  flex: 0 0 0;
  padding: 32px 24px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
`;

const items = [
  {
    label: 'TOP',
    key: 'dashboard',
    icon: <DashboardIcon />
  },
  {
    label: 'ユーザ／備蓄場所検索',
    key: 'user-list',
    icon: <UserIcon />
  },
  {
    label: 'データ一括登録',
    key: 'data-register',
    icon: <DataRegisterIcon />
  }
];

const SideBar = ({ onRequestLogout, selectedKeys = [], onMenuItemChage }) => {
  const onSelectMenuItem = ({ key }) => {
    onMenuItemChage(key);
  };

  return (
    <SideBarContainer>
      <div className="sidebar-header">
        <SidebarIcon width={196} height={26} />
      </div>
      <StyledMenu
        selectedKeys={selectedKeys}
        items={items}
        mode="vertical"
        onSelect={onSelectMenuItem}
      />
    </SideBarContainer>
  );
};

export default SideBar;
