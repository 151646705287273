import { Checkbox } from "antd";
import ReserveInfo from "api/ReserveInfo/index";
import { ReactComponent as CloseIcon } from "assets/svg/close-circle.svg";
import { Button } from "components";
import Modal from "components/Modal/index";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEditReserve, setNewReserve } from "state/actions/reserveInfo";
import styled from "styled-components";
import { message, Spin } from "../../../node_modules/antd/lib/index";
import moment from "../../../node_modules/moment/moment";
import { useNavigate } from "../../../node_modules/react-router-dom/index";
import { ReactComponent as Success } from "../../assets/svg/tick-circle.svg";

const StyledDiv = styled.div`
  width: 375px;
  min_height: 812px;
  padding: 24px 16px 0px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 576px) {
    min-width: 350px;
  }
  .detail-title {
    font-weight: 700;
    font-size: 16px;
    color: #0059ab;
    margin-bottom: 24px;
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 48px;
    justify-content: space-between;
    margin-bottom: 16px;

    .ant-checkbox-group {
      span {
        font-weight: 500;
        font-size: 13px;
        color: #333333;
      }
    }
  }

  .label {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #7b7b7b;
  }

  .value {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333;
    border-bottom: 0.5px solid #dcdcdc;
    overflow-wrap: break-word;
  }
`;

const ModalSuccess = styled(Modal)`
  .ant-modal-content {
    padding: 24px;
    border-radius: 8px;

    .ant-modal-body {
      padding: unset;
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 16px;

      span {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
        margin-top: 16px;
      }
    }

    .ant-modal-close {
      top: 24px;
      right: 24px;
      margin: unset !important;
      width: unset;

      .ant-modal-close-x {
        width: unset;
        height: unset;
        line-height: unset;
      }
    }
  }
`;

const ButtonBar = styled.div`
  position: sticky;
  bottom: 0px;
  padding: 10px 16px 24px;
  max-width: 375px;
  margin: auto;
  background: #f6f9ff;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    .ant-btn-default {
      span {
        color: #0059ab;
      }
    }

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    span {
      font-weight: 700;
      font-size: 16px;
      text-align: center;
    }
  }
`;

const ConfirmReservePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reserveDetail, setReserveDetail] = useState();

  const editReserve = useSelector((state) => {
    return state?.reserveInfo.edit_reserve_info;
  });

  const newReserve = useSelector((state) => {
    return state?.reserveInfo.new_reserve_info;
  });

  useEffect(() => {
    if (location.pathname.split("/")[1] === "register-information") {
      setReserveDetail(newReserve);
    }
    if (location.pathname.split("/")[1] === "edit-information") {
      setReserveDetail({ ...editReserve, exp_date: moment(editReserve.exp_date) });
    }
  }, [location]);

  const handleSubmitData = async () => {
    try {
      setLoading(true);
      if (location.pathname.split("/")[1] === "register-information") {
        const response = await ReserveInfo.createNewReserve(reserveDetail);
        if (response) {
          setIsSuccess(true);
          dispatch(setNewReserve({}));
        }
        setLoading(false);
      }
      if (location.pathname.split("/")[1] === "edit-information") {
        const response = await ReserveInfo.editReserveDetail(reserveDetail);
        if (response) {
          setIsSuccess(true);
          dispatch(setEditReserve({}));
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      message.error(
        "サーバへの要求に失敗しました。しばらく経ってから再度実施してください。"
      );
    }
  };

  const handleBack = () => {
    navigate(`/${location.pathname.split("/")[1]}`);
  };

  const handleCancelSuccess = () => {
    setIsSuccess(false);
    navigate("/list-information");
  };

  return (
    <>
      <Spin spinning={loading} center>
        <StyledDiv>
          <h1 level={3} className="detail-title">
            備蓄情報
          </h1>
          <div className="row">
            <span className="label">備蓄品</span>
            <span className="value">{reserveDetail?.item_name}</span>
          </div>
          <div className="row">
            <span className="label">登録ID</span>
            <span className="value">
              {reserveDetail?.registration_id?.length ? reserveDetail?.registration_id : '-'}
            </span>
          </div>
          <div
            style={{ display: "flex", width: "100%", justifyContent: "space-between" }}
          >
            <div className="row" style={{ width: "48%" }}>
              <span className="label">数量</span>
              <span className="value">{reserveDetail?.quantity}</span>
            </div>
            <div className="row" style={{ width: "48%" }}>
              <span className="label">単位</span>
              <span className="value">{reserveDetail?.item_unit}</span>
            </div>
          </div>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <div className="row" style={{ width: '48%' }}>
              <span className="label">期限</span>
              <span className="value">
                {reserveDetail?.exp_date._d.toISOString().split('T')[0].split('-').join('/')}
              </span>
            </div>
            <div className="row" style={{ width: '48%' }}>
              <span className="label">期限前通知</span>
              <span className="value">{reserveDetail?.wrn_month}カ月前から通知</span>
            </div>
          </div>
          <div className="row">
            <span className="label">備蓄場所</span>
            <span className="value">{`〒${reserveDetail?.zip_code} ${reserveDetail?.province}${reserveDetail?.city}${reserveDetail?.street}`}</span>
          </div>
          {reserveDetail?.person_in_charge?.map((per, idx) => {
            return (
              <React.Fragment key={idx}>
                <div className="row">
                  <span className="label">担当者 {idx + 1}</span>
                  <span className="value">{per?.name}</span>
                </div>
                <div className="row">
                  <span className="label">メールアドレス {idx + 1}</span>
                  <span className="value">{per?.email ? per?.email : "-"}</span>
                </div>
                <div style={{ width: "100%" }}>
                  <span className="label">担当者電話番号 {idx + 1}</span>
                </div>
                <div className="row">
                  <span className="label">（携帯）</span>
                  <span className="value">
                    {per?.mobile_phone_number.length ? per?.mobile_phone_number : "-"}
                  </span>
                </div>
                <div className="row">
                  <span className="label">（固定）</span>
                  <span className="value">
                    {per?.landline_number.length ? per?.landline_number : "-"}
                  </span>
                </div>
                <div
                  className="row"
                  style={{ borderBottom: "unset", marginBottom: "30px" }}
                >
                  <span className="label">
                    被災者からの支援要請を受け取りますか？
                  </span>
                  <Checkbox.Group
                    options={[
                      { label: "受け取る", value: "する" },
                      { label: "受け取らない", value: "しない" }
                    ]}
                    disabled
                    defaultValue={per?.is_notify ? "する" : "しない"}
                  />
                </div>
              </React.Fragment>
            );
          })}

          <div className="row" style={{ borderBottom: "unset", marginBottom: "30px" }}>
            <span className="label">備蓄情報の公開設定</span>
            <Checkbox.Group
              options={[
                { label: "公開する", value: "1" },
                { label: "公開しない", value: "0" }
              ]}
              disabled
              value={reserveDetail?.is_published ? "1" : "0"}
            />
          </div>
          <ModalSuccess
            visible={isSuccess}
            closeIcon={<CloseIcon />}
            onCancel={handleCancelSuccess}
            footer={false}
          >
            <React.Fragment>
              <Success width={80} height={80} />
              <span>
                {location.pathname.split("/")[1] === "register-information"
                  ? "登録が完了しました。"
                  : "保存が完了しました。"}
              </span>
            </React.Fragment>
          </ModalSuccess>
        </StyledDiv>
      </Spin>
      <ButtonBar>
        <Button onClick={handleBack} className={loading ? "disabled" : ""}>
          <span style={{ color: "#0059AB" }}>戻る</span>
        </Button>
        <Button
          type="primary"
          onClick={handleSubmitData}
          style={{ marginLeft: "15px" }}
          className={loading ? "disabled" : ""}
        >
          {location.pathname.split("/")[1] === "register-information" ? "登録" : "保存"}
        </Button>
      </ButtonBar>
    </>
  );
};

export default ConfirmReservePage;
