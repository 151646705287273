import React, { useCallback, useContext, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Typography, Form, Input, Select, Row, Col, Button } from "antd";

import { PublicLayout } from "components/index";
import { UserSearchStockpileInformationWrapper } from "./styles";

import { master, search, user } from "api";
import LocationList from "components/LocationList";
import { LocationsContext } from "../../contexts/Location";
import { ERROR_MESSAGES, USER_PATH } from "utils/constant";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setReserveAddressRequestHelp } from "state/actions/userInfo";

const { Title } = Typography;
const { Option } = Select;

const ZIP_CODE_LENGTH = 7;

const UserSearchStockpileInformation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    locationData,
    locationQuery,
    locationUserDefaultData,
    locationItemType,
    locationUserProvinceData,
  } = useContext(LocationsContext);
  const [locationList, setLocationList] = locationData;
  const [locationSearch, setLocationSearch] = locationQuery;
  const [userDefaultData, setUserDefaultData] = locationUserDefaultData;
  const [itemTypeData, setItemTypeData] = locationItemType;
  const [locationProvince, setLocationProvince] = locationUserProvinceData;

  const [reserveLocation, setReserveLocation] = useState({});
  const [reserveProvince, setReserveProvince] = useState([]);
  const [reserveCity, setReserveCity] = useState([]);

  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingUserDefault, setLoadingUserDefault] = useState(false);
  const [loadingItemType, setLoadingItemType] = useState(false);
  const initialValues = {
    zip_code: locationSearch.zip_code,
    province: locationSearch.province,
    city: locationSearch.city,
    item_name: locationSearch.item_name,
    other_item_name: locationSearch.other_item_name || "",
    quantity: locationSearch.quantity || 10
  };

  const onSearchStockpileInformation = async (values) => {
    try {
      setLoading(true);
      setLocationSearch(values);
      const newValues = location.pathname === "/user-search-store-location" ? { ...values, type: 'all' } : 
      { ...values, type: "" }
      const response = await search.getUserSearchReserve(newValues);
      if (response.length > 0) {
        dispatch(setReserveAddressRequestHelp(values));
        let responseData = [...response];
        if (location.pathname === USER_PATH.HELP_LOCATION) {
          responseData = response.filter(item => item.current_status !== 4 && item.current_status !== 5);
        }
        responseData.forEach(item => {
          if (!item.current_status) {
            item.isSelected = false;
          }
        });
        setLocationList(responseData);
      } else {
        setLocationList([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const setFieldsData = (data) => {
    const defaultUserAddress = [
      {
        name: ["zip_code"],
        value: data?.zip_code || ""
      },
      {
        name: ["province"],
        value: data?.province || ""
      },
      {
        name: ["city"],
        value: data?.city || ""
      }
    ];
    setFields(defaultUserAddress);
    
  };

  const handleChangeZipCode = async (event) => {
    let zip_code = event.target.value;
    if (zip_code.length === ZIP_CODE_LENGTH) {
      setLoadingUserDefault(true);
      setFieldsData({ zip_code: zip_code });
      const response = await master.getAddressFromZipCode(
        event.target.value
      );
      let provinceData = [];
      let cityData = "";
      let neighborhood = "";
      if (response.ok) {
        let responseText = await response.text();
        responseText = JSON.parse(responseText);
        if (responseText.results.length > 0) {
          let address = responseText.results[0].address_components;
          address.forEach(element => {
            if (element.types.includes('administrative_area_level_1', 'political')) {
              provinceData.push(element.long_name);
            }
            if (element.types.includes('locality', 'political')) {
              cityData = element.long_name;
            }
            if (element.types.includes('sublocality', 'political')) {
              neighborhood = element.long_name;
            }
            if (element.types.includes('country', 'political')) {
              if (element.long_name !== '日本') {
                provinceData = [];
                cityData = '';
              }
            }
          });
          cityData += neighborhood;
          setFieldsData({ zip_code: zip_code, province: provinceData, city: cityData });
        }
        setLoadingUserDefault(false);
      } else {
        setLoadingUserDefault(false);
      }
    }
  };

  const goBackTopUser = useCallback(() => navigate("/top-user"), [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingUserDefault(true);
        setLoadingItemType(true);

        if(location.pathname === '/user-send-request'){
          const reserve_address = await user.getReserveAddress();
          setReserveLocation(reserve_address);
          setReserveProvince(Object.keys(reserve_address));
          setReserveCity(reserve_address[Object.keys(reserve_address)[0]]);
        }

        // Get Location Province
        if (isEmpty(locationProvince) && location.pathname === '/user-search-store-location') {
          const responseProvince = await master.getProvince();
          let provinceData = responseProvince.map(item => item.province);
          setLocationProvince(provinceData);
        }

        // Get Item Type
        if (isEmpty(itemTypeData)) {
          const responseItemType = await master.getItemType();
          let removeItemOther = responseItemType.slice(0, -1);
          setFields([{
            name: ["item_name"],
            value: removeItemOther[0]?.name || ""
          }]);
          setItemTypeData(removeItemOther);
        } else {
          setFields([{
            name: ["item_name"],
            value: itemTypeData[0]?.name || ""
          }]);
        }

        // Get User Address
        if (isEmpty(userDefaultData) && location.pathname === '/user-search-store-location') {
          const responseUserAddress = await user.getUserInfo();
          setFieldsData(responseUserAddress[0]);
          setUserDefaultData(responseUserAddress);
        }

        setLoadingUserDefault(false);
        setLoadingItemType(false);
      } catch (err) {
        setLoadingUserDefault(false);
        setLoadingItemType(false);
      }
    };
    fetchData();
  }, []);

  const handleChangeProvince = (e) => {
    setReserveCity(reserveLocation[e]);
    setFields([
      {
        name: ["city"],
        value: reserveLocation[e][0] || ""
      }
    ])
  }

  useEffect(() => {
    if(location.pathname === '/user-send-request') {
      setFields([
        {
          name: ["province"],
          value: reserveProvince[0] || ""
        },
        {
          name: ["city"],
          value: reserveCity[0] || ""
        }
      ])
    }
  }, [reserveLocation]);

  return (
    <UserSearchStockpileInformationWrapper>
      <PublicLayout>
        {location.pathname === USER_PATH.STORE_LOCATION && (
          <Title className="page-title" level={2}>近隣備蓄情報検索</Title>
        )}
        {location.pathname === USER_PATH.HELP_LOCATION && (
          <>
            <Title className="page-help-title" level={2}>支援要請</Title>
            <Title className="page-sub-title" level={3}>支援要請を出したい場所（被災場所）</Title>
          </>
        )}
        <Form className="form-wrapper"
          name="UserSearchStockpileInformation"
          initialValues={initialValues}
          form={form}
          fields={fields}
          onFinish={onSearchStockpileInformation}
          layout="vertical">
          <div className="form-block">
            <Title className="form-block-title" level={3}>備蓄場所（被災場所）</Title>
            <Row gutter={[8, 8]}>
              {
                location.pathname === '/user-send-request' ? (
                  <>
                    <Col xs={8}>
                      <Form.Item
                        name="province"
                        rules={[
                          {
                            required: true,
                            message: ERROR_MESSAGES.EMPTY
                          }
                        ]}
                      >
                        <Select 
                          virtual={false}
                          onChange={(e) => handleChangeProvince(e)}
                          id="province" 
                          name="province" 
                          style={{ width: "100%" }}
                          loading={loadingUserDefault}
                        >
                          {reserveProvince && reserveProvince.map((element, index) => (
                            <Select.Option key={`province-${index}`} value={element}>{element}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={16}>
                      <Form.Item
                        name="city"
                        noStyle
                      >
                        <Select virtual={false} onChange={() => {}} id="city" name="city" style={{ width: "100%" }}
                          loading={loadingUserDefault}>
                          {reserveCity && reserveCity.map((element, index) => (
                            <Select.Option key={`city-${index}`} value={element}>{element}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs={10}>
                      <Form.Item
                        name="zip_code"
                        colon={false}
                        noStyle
                      >
                        <Input allowClear maxLength={ZIP_CODE_LENGTH} onChange={handleChangeZipCode} />
                      </Form.Item>
                    </Col>
                    <Col xs={14}>
                      <Form.Item
                        name="province"
                        rules={[
                          {
                            required: true,
                            message: ERROR_MESSAGES.EMPTY
                          }
                        ]}
                      >
                        <Select virtual={false} onChange={() => ({})} id="province" name="province" style={{ width: "100%" }}
                          loading={loadingUserDefault}>
                          {locationProvince && locationProvince.map((element, index) => (
                            <Select.Option key={`province-${index}`} value={element}>{element}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        name="city"
                        noStyle
                      >
                        <Input allowClear />
                      </Form.Item>
                    </Col>
                  </>
                )
              }
            </Row>
          </div>
          <div className="form-block">
            <Title className="form-block-title" level={3}>備蓄品（不足品）</Title>
            <Row gutter={[8, 8]}>
              <Col xs={24}>
                <Form.Item
                  name="item_name"
                  noStyle
                >
                  <Select virtual={false} style={{ width: "100%" }} loading={loadingItemType}>
                    {
                      itemTypeData && itemTypeData.map((item, index) =>
                        <Option key={`item-type-${index}`} value={item.name}>{item.name}</Option>
                      )
                    }
                    <Option value="" />
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col xs={24}>
                <Form.Item
                  name="other_item_name"
                  noStyle
                >
                  <Input placeholder="その他備蓄品を検索　複数入力可" allowClear />
                </Form.Item>
              </Col> */}
            </Row>
          </div>
          <div className="form-block">
            <Title className="form-block-title" level={3}>表示件数</Title>
            <Row gutter={[8, 8]}>
              <Col xs={24}>
                <Form.Item
                  name="quantity"
                  noStyle
                >
                  <Select virtual={false} style={{ width: "100%" }}>
                    {
                      Array.from({ length: 5 }, (_, i) => i + 1).map((number, index) =>
                        <Option key={`quantity-${index}`} value={number * 10}>{number * 10}</Option>
                      )
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="form-block">
            <Form.Item noStyle>
              <Button className="btn-submit" type="primary" htmlType="submit" loading={loading}>
                近隣の備蓄情報を検索する
              </Button>
            </Form.Item>
          </div>
        </Form>
        <LocationList locations={locationList} />
        <div className="btn-back-wrapper">
          <Button className="btn-back" type="primary" onClick={goBackTopUser}>
            戻る
          </Button>
        </div>
      </PublicLayout>
    </UserSearchStockpileInformationWrapper>
  );
};

export default UserSearchStockpileInformation;
