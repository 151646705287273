import { Spin } from 'antd';
import UserRequested from 'containers/UserRequested/index';
import { lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from 'routes';
import { StyledSpin } from 'routes/PublicRoute/';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useNavigate, useSearchParams } from '../node_modules/react-router-dom/index';
import LocationContext from './contexts/Location';

const LoginPage = lazy(() =>
  import(/* webpackChunkName: "LoginPage" */ 'containers/LoginPage')
);
const ChangePasswordPage = lazy(() =>
  import(/* webpackChunkName: "ChangePasswordPage" */ 'containers/ChangePasswordPage')
);
const UserChangePasswordPage = lazy(() =>
  import(
    /* webpackChunkName: "UserChangePasswordPage" */ 'containers/UserChangePasswordPage'
  )
);
const ForgotPasswordPage = lazy(() =>
  import(/* webpackChunkName: "ForgotPasswordPage" */ 'containers/ForgotPasswordPage')
);
const PageNotFound = lazy(() =>
  import(/* webpackChunkName: "404Page" */ 'containers/404Page')
);
const SignUpPage = lazy(() =>
  import(/* webpackChunkName: "404Page" */ 'containers/SignUpPage')
);

const TopUserPage = lazy(() =>
  import(/* webpackChunkName: "TopUserPage" */ 'containers/TopUser')
);

const UserDetailInformationPage = lazy(() =>
  import(/* webpackChunkName: "404Page" */ 'containers/UserDetailInformationPage')
);

const UserSearchStockpileInformation = lazy(() =>
  import(/* webpackChunkName: "404Page" */ 'containers/UserSearchStockpileInformation')
);

const UserSupportRequestLocation = lazy(() =>
  import(/* webpackChunkName: "404Page" */ 'containers/UserSupportRequestLocation')
);

const UserSupportRequestNotification = lazy(() =>
  import(/* webpackChunkName: "404Page" */ 'containers/UserSupportRequestNotification')
);

const UserRequestingPage = lazy(() =>
  import(/* webpackChunkName: "404Page" */ 'containers/UserRequesting')
);
const ListReserveInformationPage = lazy(() =>
  import(/* webpackChunkName: "404Page" */ 'containers/ListReserveInformationPage')
);

const DetailReserveInformationPage = lazy(() =>
  import(/* webpackChunkName: "404Page" */ 'containers/DetailReserveInformationPage')
);

const EditReserveInformationPage = lazy(() =>
  import(/* webpackChunkName: "404Page" */ 'containers/EditReserveInformationPage')
);

const ConfirmReservePage = lazy(() =>
  import(/* webpackChunkName: "404Page" */ 'containers/ConfirmReservePage')
);

const UserChangePassword = lazy(() =>
  import(/* webpackChunkName: "404Page" */ 'containers/UserChangePassword')
);

const UserEditInformationPage = lazy(() =>
  import(/* webpackChunkName: "404Page" */ 'containers/UserEditInformationPage')
);

const UserConfirmPage = lazy(() =>
  import(/* webpackChunkName: "404Page" */ 'containers/UserConfirmPage')
);

function App() {

  const valid_key = useSelector(state => state.authenticate.valid_key);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const check = ['email', 'code', 'accesstoken']

  const valid_param = () => {
    let len = 0;
    for (const entry of searchParams.entries()) {
      if(check.includes(entry[0])){
        len++;
      }
      return len;
    }
    if(len === 2){
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if(location.pathname === "/signup"){
      if(!(valid_key !== "" || valid_param())){
        navigate("login")
      }
    }
  }, [location.pathname])

  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route index element={<Navigate to="/top-user" replace />} />

        <Route
          path="top-user"
          element={
            <PrivateRoute>
              <TopUserPage />
            </PrivateRoute>
          }
        />

        <Route
          path="user-change-password"
          element={
            <PrivateRoute type="public">
              <UserChangePasswordPage />
            </PrivateRoute>
          }
        />

        <Route
          path="user-password"
          element={
            <PrivateRoute type="public">
              <UserChangePassword />
            </PrivateRoute>
          }
        />

        <Route
          path="user-information"
          element={
            <PrivateRoute type="public">
              <UserDetailInformationPage />
            </PrivateRoute>
          }
        />

        <Route
          path="user-edit"
          element={
            <PrivateRoute type="public">
              <UserEditInformationPage />
            </PrivateRoute>
          }
        />

        <Route
          path="user-confirm"
          element={
            <PrivateRoute type="public">
              <UserConfirmPage />
            </PrivateRoute>
          }
        />

        <Route
          path="user-search-store-location"
          element={
            <PrivateRoute type="public">
              <LocationContext subPages={<UserSearchStockpileInformation />} />
            </PrivateRoute>
          }
        />

        <Route
          path="user-send-request"
          element={
            <PrivateRoute type="public">
              <LocationContext subPages={<UserSearchStockpileInformation />} />
            </PrivateRoute>
          }
        />

        <Route
          path="user-support-request-location"
          element={
            <PrivateRoute type="public">
              <LocationContext subPages={<UserSupportRequestLocation />} />
            </PrivateRoute>
          }
        />

        <Route
          path="user-support-request-notification"
          element={
            <PrivateRoute type="public">
              <LocationContext subPages={<UserSupportRequestNotification />} />
            </PrivateRoute>
          }
        />

        <Route
          path="user-requesting"
          element={
            <PrivateRoute type="public">
              <UserRequestingPage />
            </PrivateRoute>
          }
        />
        <Route
          path="user-requested"
          element={
            <PrivateRoute type="public">
              <UserRequested />
            </PrivateRoute>
          }
        />

        <Route
          path="list-information"
          element={
            <PrivateRoute type="public">
              <ListReserveInformationPage />
            </PrivateRoute>
          }
        />

        <Route
          path="detail-information"
          element={
            <PrivateRoute type="public">
              <DetailReserveInformationPage />
            </PrivateRoute>
          }
        />

        <Route
          path="edit-information"
          element={
            <PrivateRoute type="public">
              <EditReserveInformationPage />
            </PrivateRoute>
          }
        />

        <Route
          path="register-information"
          element={
            <PrivateRoute type="public">
              <EditReserveInformationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="edit-information/confirm-reserve"
          element={
            <PrivateRoute type="public">
              <ConfirmReservePage />
            </PrivateRoute>
          }
        />

        <Route
          path="register-information/confirm-reserve"
          element={
            <PrivateRoute type="public">
              <ConfirmReservePage />
            </PrivateRoute>
          }
        />
        
        <Route
          path="change-password"
          element={
            <PublicRoute>
              <ChangePasswordPage />
            </PublicRoute>
          }
        />

        <Route
          path="forgot-password"
          element={
            <PublicRoute>
              <ForgotPasswordPage />
            </PublicRoute>
          }
        />

        <Route
          path="login"
          element={
            <PublicRoute>
              <LoginPage />
            </PublicRoute>
          }
        />
        
        <Route
          path="signup"
          element={
            <PublicRoute>
              <SignUpPage />
            </PublicRoute>
          }
        />

        <Route
          path="login"
          element={
            <PublicRoute>
              <LoginPage />
            </PublicRoute>
          }
        />

        <Route
          path="login"
          element={
            <PublicRoute>
              <LoginPage />
            </PublicRoute>
          }
        />

        <Route
          path="*"
          element={
            <Suspense
              fallback={
                <StyledSpin>
                  <Spin />
                </StyledSpin>
              }
            >
              <PageNotFound />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
