import styled from "styled-components";

export const LocationListWrapper = styled.div`
  .location-scroll {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 15px;
    vertical-align: top;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: #a8a8a8;
      border-radius: 6px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    .location-item {

    }
  }

  .location-slider {
    &.slick-slider {
      margin-bottom: 0;
    }

    .slick-dots li {
      position: absolute;
      pointer-events: none;
      height: auto;
      width: auto;
      left: 50%;
      bottom: 5px;

      &.slick-active {
        z-index: 1;
      }

      &:nth-child(3n+1) {
        transform: translateX(-250%);
      }

      &:nth-child(3n+2) {
        transform: translateX(-50%);
      }

      &:nth-child(3n+0) {
        transform: translateX(150%);
      }
    }
  }

  .button-fullscreen{
    position: absolute;
    right: 8px;
    top: 8px;
    width: 36px;
    z-index: 1;
    padding: 2px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    span{
      height: 32px;

      svg {
        width: 32px;
        height: 32px;
        line-height: 32px;
      }
    }
  }

  // @media (min-width: 1200px) {
  .map {
    height: 368px;
    .text-center{
      position: fixed;
      bottom: 70px;
      left: 50%;
      transform: translate(-50%, 0px);
      height: 368px;
      width: 80%;
    }
  }
  // }
`;
