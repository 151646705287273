import styled from "styled-components";

export const LocationItemDetailWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 15px;
  background: #F6F6F6;
  margin-bottom: 15px;
  border-radius: 10px;
  border: 1px solid #0000006b;
  transition: all .25s ease-in-out;
  box-shadow: 0 4px 16px rgba(0,0,0,0.1);
  
  &.item-empty-status{
    background: white;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }

  &.item-selected {
    border: 1px solid #0059AB;
  }

  &.item-unselected {
    border: 1px solid red;
  }

  .location-status {
    position: absolute;
    right: 15px;
    top: 20px;
    padding: unset;
    border-radius: 4px;
    width: 45%;
    height: 45px;
    display: flex;
    align-items: center;
    font-size: 1.4em;
    justify-content: center;
    font-weight: 700;
    transition: all .25s ease-in-out;
    white-space: nowrap;

    & button {
      width: 100%;
      height: 100%;
      text-align: center;
      border: unset;
    }

    &.requesting {
      & button {
        color: black;
        font-weight: 700;
        background-color: #80808094;
      }
    }

    &.not-support {
      & button {
        color: black;
        font-weight: 700;
        background-color: #80808094;
      }
    }

    &.requested {
      & button {
        color: black;
        font-weight: 700;
        background-color: #80808094;
      }
    }

    &.selected {
      color: #0059AB;

      & button {
        background-color: #0072ff94;
      }
    }

    &.unselected {
      color: #333333;
      & button {
        background-color: #ff4d4fcf;
      }
    }
  }

  .btn-deselect, .btn-select {
    display: inline-block;
    height: auto;
    padding: 5px 15px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    color: #FFFFFF;
    border-radius: 4px;
  }

  .btn-deselect {
    background: #003974;
    border: 1px solid #003974;
  }

  .btn-select {
    background: #0059AB;
    border: 1px solid #0059AB;
  }

  .location-block {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &-title {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: #7B7B7B;
      margin-bottom: 5px;
    }

    &-content {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #333333;
      margin-bottom: 0;
    }
  }
`;
