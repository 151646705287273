import styled from "styled-components";
import ProjectLogo from "../../assets/svg/ProjectLogo";
import { ReactComponent as ProjectLogoBlue } from "assets/svg/project-logo.svg";

const Container = styled.div`
  width: 100%;
  // margin: 0 auto;
  // max-width: 375px;
  // height: 100%;

  .project-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 251px;

    background-color: #0059AB;
    padding: 50px 141px 136px 141px;
  }

  .project-logo-white {
    position: fixed;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    left: 50%;
    top: 0;
    padding: 30px 0 15px;
    background: #F6F9FF;
    transform: translateX(-50%);
    z-index: 1;
  }
`;

const PublicLayout = ({ children, isHideLogo, isLogoBlue }) => {
  return (
    <Container>
      {isHideLogo ?
        <div className="project-logo">
          <ProjectLogo />
        </div>
        :
        ""
      }
      {isLogoBlue ?
        <div className="project-logo-white">
          <ProjectLogoBlue />
          <div style={{     
            width: '365px',
            margin: '0 auto',
            fontSize: '13px',
          }}>
            「水の架け橋」ネットワーク加入者の専用サイトです。
            <br/>
            「支援要請」「自社の備蓄品の管理」などが行えます。
          </div>
        </div>
        :
        ""
      }
        <div style={{ 
          margin: '0 auto',
          maxWidth: '375px',
        }}>
          {children}
        </div>
    </Container>
  );
};

export default PublicLayout;
