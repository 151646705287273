import React, { Fragment, useEffect, useState } from "react";
import { LocationItemDetailWrapper } from "./styles";
import { Button, Typography } from "antd";
import { formatNumber, LOCATION_STATUS } from "utils/constant";

const { Title, Paragraph } = Typography;

const LocationItemDetail = ({ item, status, selectItem, changeSelectItem }) => {
  const [statusItem, setStatusItem] = useState({});
  useEffect(() => {
    let statusData = {
      name: "",
      value: ""
    };
    if (status) {
      switch (status) {
        case LOCATION_STATUS.REQUESTING:
          statusData.name = "要請要請中";
          statusData.value = "requesting";
          break;
        case LOCATION_STATUS.REQUESTED:
          statusData.name = "連絡先 交換済";
          statusData.value = "requested";
          break;
        case LOCATION_STATUS.NOT_SUPPORT:
          statusData.name = "支援要請不可";
          statusData.value = "not-support";
          break;
      }
    } else {
      if (selectItem === true) {
        statusData.value = "unselected";
      } else {
        statusData.value = "selected";
      }
    }
    setStatusItem(statusData);
  }, [status, selectItem]);

  const handleClick = () => {
    if(item.current_status === '' && !selectItem){
      changeSelectItem(item.reserve_id, true)
    }
    if(item.current_status === '' && selectItem){
      changeSelectItem(item.reserve_id, false)
    }
  }

  return (
    <Fragment>
      <LocationItemDetailWrapper
        onClick={handleClick}
        className={`${!status ? "item-empty-status" : ""} ${!status && !selectItem ? "item-selected" : ""} ${!status && selectItem ? "item-unselected" : ""}`}>
        <span className={`location-status ${statusItem.value}`}>
          {(() => {
            if (item.current_status === '' && !selectItem) {
              return (
                <Button 
                  className="btn-select"
                  type="primary"
                >
                  要請しない
                </Button>
              )
            } else if (item.current_status === '' && selectItem) {
              return (
                <Button 
                  className="btn-deselect"
                  type="primary"
                >
                  要請する
                </Button>
              )
            } else {
              return (
                <Button disabled={true} type="primary">
                  {statusItem.name}
                </Button>
              )
            }
          })()}
        </span>
        {
          item.company_name && item.current_status === LOCATION_STATUS.REQUESTED && (
            <Typography className="location-block">
              <Title className="location-block-title" level={5}>事業者名/自治体名</Title>
              <Paragraph className="location-block-content">{item.company_name}</Paragraph>
            </Typography>
          )
        }

        {item.item_name && (
          <Typography className="location-block">
            <Title className="location-block-title" level={5}>備蓄品</Title>
            <Paragraph className="location-block-content">{item.item_name}</Paragraph>
          </Typography>
        )}

        <Typography className="location-block">
          <Title className="location-block-title" level={5}>数量</Title>
          <Paragraph className="location-block-content">{formatNumber(item?.quantity)} {item?.item_unit}</Paragraph>
        </Typography>

        {item.address && (
          <Typography className="location-block">
            <Title className="location-block-title" level={5}>備蓄場所</Title>
            <Paragraph className="location-block-content">{item.address}</Paragraph>
          </Typography>
        )}
      </LocationItemDetailWrapper>
    </Fragment>
  );
};

export default LocationItemDetail;
