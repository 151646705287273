import { apiUrl } from 'api/utils';
import baseApi from '../base';

export const PROVINCE_URL = apiUrl('master/province');
export const CUSTOMER_TYPE_URL = apiUrl('master/customer_type');
export const ITEM_TYPE_URL = apiUrl('master/item_type');
export const ITEM_UNIT_URL = apiUrl('master/item_unit');
export const ADDRESS_FROM_ZIP_CODE_URL = 'https://maps.googleapis.com/maps/api/geocode/json';

export default {
  getProvince: async () => {
    const data = await baseApi.request(PROVINCE_URL, {
      method: 'GET'
    });
    return data;
  },
  getCustomerType: async () => {
    const data = await baseApi.request(CUSTOMER_TYPE_URL, {
      method: 'GET'
    });
    return data;
  },
  getItemType: async () => {
    const data = await baseApi.request(ITEM_TYPE_URL, {
      method: 'GET'
    });
    return data;
  },
  getItemUnit: async () => {
    const data = await baseApi.request(ITEM_UNIT_URL, {
      method: 'GET'
    });
    return data;
  },
  getAddressFromZipCode: async (zip_code) => {
    const objectData = {
      address: zip_code,
      language: "ja",
      key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    };
    const queryParams = Object.entries(objectData).map(([key, val]) => {
      if (val !== "" && val !== undefined) {
        return `${key}=${val}`;
      }
    }).filter(x => x).join("&");

    const data = await fetch(`${ADDRESS_FROM_ZIP_CODE_URL}?${queryParams}`, {
      method: 'GET'
    });
    return data;
  }
};
